import React, {useState} from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const helperFile = require("./airline_helper.js");

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/airline/dashboard";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    
                        
                          <td>{props.item.userName}</td>
                          <td>{props.item.courseName}</td>
                          <td>{props.item.date}</td>
                          <td>{props.item.daysLeft}</td>
                          <td><img src={"/status-"+props.item.statusColor+".svg"} width="30" height="30"/></td>
                    {/*<!-- Modal Sending -->*/}
                          <td><button type="button" class="btn btn-outline-primary" onClick={() => {
                            document.getElementById("userId").value = props.item.id;
                            document.getElementById("userName").value = props.item.userName;
                            document.getElementById("email").value = props.item.email;
                            document.getElementById("subject").value = "Expiration for " +props.item.courseName;
                            document.getElementById("body").value = "Your training requirement for "+props.item.courseName+" is expiring soon."

                            window.$("#exampleModal").modal('show');
                          }} data-toggle1="modal" data-target="#exampleModal" data-whatever="@mdo">Send</button>
                            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                              <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Send Reminder</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <form>
                                      <div class="form-group">
                                        <label for="recipientName" class="col-form-label">Recipient:</label>
                                        <input type="hidden" id="userId" />
                                        <input type="text" class="form-control" id="userName" readonly/>
                                      </div>
                                      <div class="form-group">
                                        <label for="recipientEmail" class="col-form-label">Recipient Email:</label>
                                        <input type="text" class="form-control" id="email" readonly/>
                                      </div>
                                      <div class="form-group">
                                        <label for="priority" class="col-form-label">Priority:</label>
                                        <select class="form-control" id="selectPriority">
                                          <option>High</option>
                                          <option>Normal</option>
                                          <option>Low</option>
                                        </select>
                                      </div>
                                      <div class="form-group">
                                        <label for="subject" class="col-form-label">Subject:</label>
                                        <input type="text" class="form-control" id="subject"/>
                                      </div>
                                      <div class="form-group">
                                        <label for="messageText" class="col-form-label">Message:</label>
                                        <textarea class="form-control" id="body" rows="4" placeholder="Enter your message here"/>
                                      </div>
                                    </form>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="button" class="btn btn-primary" onClick={() => {
//                                      jQuery.noConflict();
//                                      console.log("sending")
//                                      $("#exampleModal").modal('hide')


                                      helper.postToServer("/airline/notify", {dw:"send",userId: document.getElementById("userId").value, 
                                        subject:document.getElementById("subject").value, 
                                        body: document.getElementById("body").value})
                                      .then(res => {
                                        window.$("#exampleModal").modal('hide');
                                      })
                                      .catch(err => {
                                        helper.handleErr(err);
                                      }) 

                                    }}>Send message</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                    {/*<!-- Dismiss button -->*/}
                          <td><button type="button" class="btn btn-block"><img src="/dismiss-icon.svg" width="30" height="30" alt="dismiss"/></button></td>
                        
                        
</tr>

}

class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    var id = helper.parseQuery()['id'];

    this.state = {
      id: id,
    };
  }




fillList()
{
    helper.getFromServer(g_link+"?dw=getOne&id="+this.state.id)
  .then(res => {

    this.setState({showForm: false, props: res.result.vals, rows: res.result.rows});
  })



}
 componentDidMount() {
  this.fillList()  
  }

    

  render() {
document.title = "Dashboard";

let listItems;
let spinner;
if (!this.state.props)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>;

}
else 
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

}
if (!this.state.props)
{
  return(<div>
  {spinner}    
  </div>)
}
return(
<div>
{helperFile.nav()}


    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-dashboard mr-2" aria-hidden="true"></i>Dashboard</h2>
        
{/*<!-- dashboard status start-->*/}
        <div class="container my-3 px-0">
            
            <div class="row">
                <div class="col-12 mb-2">
                    <h4>Training Status</h4>
                </div>
            </div>
            
            <div class="row mb-3">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                    <div class="card py-4">
                        <div class="container d-flex justify-content-between">
                            <h5><i class="fa fa-users mr-1" aria-hidden="true"></i>Users</h5>
                            <h5>{this.state.props.numUsers}</h5>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                    <div class="card py-4">
                        <div class="container d-flex justify-content-between">
                            <h5><i class="fa fa-sitemap mr-1" aria-hidden="true"></i>Requirement Sets</h5>
                            <h5>3{this.state.props.numRequirementSets}</h5>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                    <div class="card py-4">
                        <div class="container d-flex justify-content-between">
                            <h5><i class="fa fa-paste mr-1" aria-hidden="true"></i>Courses</h5>
                            <h5>{this.state.props.numCourses}</h5>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                    <div class="card py-4">
                        <div class="container d-flex justify-content-between">
                            <h5><i class="fa fa-file mr-1" aria-hidden="true"></i>Attempts</h5>
                            <h5>{this.state.props.numAttempts}</h5>
                        </div>
                    </div>
                </div>

               
            </div>
            
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-2">
                    <div class="card border-warning p-3">
                        <img src="/status-orange.svg" width="30" height="30" alt="green"/>
                        <div class="container d-flex justify-content-between mt-2">
                            <h4>Approaching</h4>
                            <h2>{this.state.props.numApproaching}</h2>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-2">
                    <div class="card border-danger p-3">
                        <img src="/status-red.svg" width="30" height="30" alt="green"/>
                        <div class="container d-flex justify-content-between mt-2">
                            <h4>Expiring/Expired</h4>
                            <h2>{this.state.props.numExpired}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
{/*<!-- data container for filters and table list start -->*/}
        <div class="data-container">

            
{/*<!-- status table list start -->*/}         
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <h5 class="text-danger">Training expired or about to expire</h5>
                    <ul>
                        <li>This table displays Expiring training status, most urgent items are displayed at the top.</li>
                        <li>You can send Alert Reminders to crew members to update approaching or expiring courses, please use the Send button.</li>
                        <li>You may dismiss expiring status by clicking the Dismiss button if no action required.</li>
                    </ul>
                    <hr class="border-warning"/>
                    
{/*<!-- filter dropdowns in flex container start -->*/}         
            <div class="container d-flex my-4 px-0 justify-content-between">
{/*<!-- filter by name -->*/}          
                <div class="dropdown">
                    <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="filterCrewName" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Filter by: Name
                    </button>
                    <div class="dropdown-menu" aria-labelledby="filterCrewName">
                        <a class="dropdown-item" href="#">Action</a>
                    </div>
                </div>
{/*<!-- filter by course-->*/}               
                <div class="dropdown">
                    <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="filterCourse" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Filter by: Course
                    </button>
                    <div class="dropdown-menu" aria-labelledby="filterCourse">
                        <a class="dropdown-item" href="#">Action</a>
                    </div>
                </div>
                <button type="button" class="btn btn-primary">Reset Filters</button>
            </div>
                    
                    {/*<!-- list1 -->*/}
                    <table class="table mb-0 table-hover">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Course</th>
                          <th scope="col">Expiry Date</th>
                          <th scope="col">Days Left</th>
                          <th scope="col">Status</th>
                          <th scope="col">Reminder</th>
                          <th scope="col">Dismiss</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/*<!-- row start -->*/}{listItems}{/*<!-- row end -->*/}
                      </tbody>
                    </table>
                    {/*<!-- list1End -->*/}
                </div>
            </div>
        </div>
    </div>


 {helperFile.footer()}
 
</div>

)
}
}


export default ModalList1
