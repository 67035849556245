import React from "react";
const helper = require("../helper");
const helperFile = require("./user_helper.js");
const Pager = require("../comp_misc").pager;

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/user/questions";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    <td>{props.item.qName}</td>
<td>{props.item.body}</td>


</tr>

}

class ModalList1 extends React.Component {
  

  constructor(props)
  {
    super(props);
    var params = helper.parseQuery();
    var page = params.p;
    if (!page)
      page = 0;

    this.state = {
      rows: null,
      error:null,
      image: null,
      page: page,
      aid:params.aid
    };
  }


checkInits()
{

}

fillList(filter)
{
  var rows1 = [];

  helper.getFromServer(g_link+"?dw=get&filter="+filter+"&aid="+this.state.aid)
  .then(res => {

    this.setState({showForm: false, rows: res.result, options: res.options, count: res.options.count});
  })



}
 componentDidMount() {
  this.fillList()  
  }

  showModal(item)
  {
      
//    this.setState({showForm: true, image: "/api/media/logo"+item.id+".png", id: item.id, name: item.name, email: helper.checkNull(item.email), error: ""});
    this.setState({showForm: true, id: item.id, answer1: item.answer1, answer2: item.answer2, answer3: item.answer3, answer4: item.answer4, answer5: item.answer5, answer6: item.answer6, correctAnswer: item.correctAnswer, alert: '', error: ""});
}

  hideModal()
  {
    this.setState({show: false});
  }

  saveItem()
  {
//    helper.postToServer(g_link, {dw: "update", id: this.state.id, email: this.state.email, name: this.state.name}, true)
    helper.postToServer(g_link, {dw: "update", id: this.state.id, answer1: this.state.answer1, answer2: this.state.answer2, answer3: this.state.answer3, answer4: this.state.answer4, answer5: this.state.answer5, answer6: this.state.answer6, correctAnswer: this.state.correctAnswer, }, true)
.then(res => {
      this.fillList();
    })
    .catch(err => {
      this.setState({error: err});
    })

  }
  addItem()
  {
    var item = {id: -1};
    this.showModal(item);
/*
    helper.postToServer(g_link, {dw: "add",qid:this.state.qid}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })
*/
  }

  deleteItem()
  {

    helper.postToServer(g_link, {dw: "del", id: this.state.id}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }
  
  hiCheck1(event)
  {
    const target = event.target;
    const name = target.name;
    let value;
    if (target.checked)
      value = target.checked;
    else
      value = 0;

      this.setState({
        [name]: value
      });
  }

  
  filter(event)
  {
    const target = event.target;
//    const name = target.name;
    var value = target.value;

    this.fillList(value);

  }

  hic(event) {

    const target = event.target;
    const name = target.name;

    let value;
//    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'checkbox')
    {
      value = this.state[name];
      if (!value)
        value = [];
        // assumes DOM won't feed us dupes
        var vint = parseInt(target.value);

      if (target.checked)
      {
        value.push(vint);
      }
      else
      {
        var index = value.indexOf(vint);
        if (index !== -1) value.splice(index, 1);
      }
    }
    else
    {
      value = target.value;
    }
    this.setState({
      [name]: value
    });
  
  }
  

  render() {
document.title = "Questions";

let spinner;
let pager;
if (!this.state.rows)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>;
}
else
{
  pager = <Pager page={this.state.page} count={this.state.options.count} link={g_link}/>;
}

var error1 = <div class="alert alert-danger" role="alert">
<i class="fa fa-times mr-2" aria-hidden="true"></i>{this.state.error}
</div>;
if (!this.state.error)
error1 = <div></div>;
var alert1 = <div class="alert alert-success" role="alert">
<i class="fa fa-check mr-2" aria-hidden="true"></i>{this.state.alert}
</div>;

if (!this.state.alert)
    alert1 = <div></div>

let listItems;
var filterItems = [];

if (this.state.rows)
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

  if (this.state.options.filters)
  {
    this.state.options.filters.map((item) => {
      filterItems.push(<option value={item.id}>{item.name}</option>)
    });
  }

}
if (this.state.showForm)
{
  this.checkInits();
  
  
  

  let deleteButton;

  if (this.state.id != -1)
  {
    deleteButton = <button type="submit" class="btn btn-danger mt-4" onClick={this.deleteItem.bind(this)}>Delete</button>
  }

  return(
<div>{helperFile.nav()}

    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-comment mr-2" aria-hidden="true"></i>Questions Form</h2>

{/*<!-- user info block start -->*/}
        <div class="data-container">
            <div class="row mb-2">
                <div class="col-12">
                    <h4>Add/Edit Questions</h4>
                </div>
            </div>
            <hr class="border-primary mb-4"/>
            
{/*<!-- form start -->*/}
        <div class="row">
            <div class="col-12">
                
                   <table class="table">
                        <tbody>
                            <tr>
                                <td width="160" align="right">Question Body:</td>
                                <td colspan="2"><textarea class="form-control" name="body" id="questionBody" rows="4"></textarea></td>
                            </tr>
                            <tr>
                                <td align="right">#</td>
                                <td>Answers</td>
                                <td align="center" width="100" class="bg-light">Correct</td>
                            </tr>
                            <tr>
                                <td width="160" align="right">a)</td>
                                <td><input type="text"  name="answer1" value={this.state.answer1} onChange={this.hic.bind(this)}  class="form-control"/></td>
                                <td align="center" width="100" class="bg-light"><input type="radio" name="correctAnswer" onChange={this.hic.bind(this)} value="1" checked={this.state.correctAnswer == 1}/></td>
                            </tr>
                            <tr>
                                <td width="160" align="right">b)</td>
                                <td><input type="text"  name="answer2" value={this.state.answer2} onChange={this.hic.bind(this)}  class="form-control"/></td>
                                <td align="center" width="100" class="bg-light"><input type="radio" name="correctAnswer" onChange={this.hic.bind(this)} value="2" checked={this.state.correctAnswer == 2}/></td>
                            </tr>
                            <tr>
                                <td width="160" align="right">c)</td>
                                <td><input type="text" name="answer-c"  name="answer3" value={this.state.answer3} onChange={this.hic.bind(this)}  class="form-control"/></td>
                                <td align="center" width="100" class="bg-light"><input type="radio" name="correctAnswer" onChange={this.hic.bind(this)} value="3" checked={this.state.correctAnswer == 3}/></td>
                            </tr>
                            <tr>
                                <td width="160" align="right">d)</td>
                                <td><input type="text" name="answer-d"  name="answer4" value={this.state.answer4} onChange={this.hic.bind(this)}  class="form-control"/></td>
                                <td align="center" width="100" class="bg-light"><input type="radio" name="correctAnswer" onChange={this.hic.bind(this)} value="4" checked={this.state.correctAnswer == 4}/></td>
                            </tr>
                            <tr>
                                <td width="160" align="right">e)</td>
                                <td><input type="text" name="answer-e"  name="answer5" value={this.state.answer5} onChange={this.hic.bind(this)}  class="form-control"/></td>
                                <td align="center" width="100" class="bg-light"><input type="radio" name="correctAnswer" onChange={this.hic.bind(this)} value="5" checked={this.state.correctAnswer == 5}/></td>
                            </tr>
                            <tr>
                                <td width="160" align="right">f)</td>
                                <td><input type="text" name="answer-f"  name="answer6" value={this.state.answer6} onChange={this.hic.bind(this)}  class="form-control"/></td>
                                <td align="center" width="100" class="bg-light"><input type="radio" name="correctAnswer" onChange={this.hic.bind(this)} value="6" checked={this.state.correctAnswer == 6}/></td>
                            </tr>
                            <tr>
                                <td width="160" align="right">Force Question:</td>
                                <td><input type="checkbox" id="force_question" name="force_question"/><span>&ensp;Check to force question to appear at all times</span></td>
                            </tr>
                        </tbody>
                    </table>
                    <button type="submit" class="btn btn-primary mt-4" onClick={this.saveItem.bind(this)}>Add/Update Question</button>&nbsp;{deleteButton}&nbsp;<button class="btn btn-danger mt-4" onClick={() => {this.setState({showForm:false})}}>Close</button>
              
            </div>
        </div>
     </div>
 </div>
      

</div>


  )
  }
  else
  {
return(
<div>
  {spinner}
{helperFile.nav()}

    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-database mr-2" aria-hidden="true"></i>Questions</h2>

        <div class="data-container">
            <div class="row mb-2">
                <div class="col-8">
                    <p>The list displays all the questions for this quiz, if no questions appearing in the list, you may start adding by clicking the Add Question button.</p>
                </div>
                <div class="col-4">
                    <div class="card bg-secondary">
                        <div class="card-body text-center font-weight-bolder text-white">
                        Questions: {this.state.count}
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            

            <div class="container d-flex my-4 px-0 justify-content-between">
                <input class="form-control w-40" id="myInput" type="text" placeholder="Search.. by anything"/>
            </div>
            

            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <table class="table mb-0 table-hover">
                      <thead>
                        <tr>
                          <th scope="col">Q No.</th>
                          <th scope="col" >Question</th>
                        </tr>
                      </thead>
                      <tbody id="myTable">
                      {listItems}
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
      

    {pager}
 {helperFile.footer()}
 
</div>

)
  }}
}


export default ModalList1
