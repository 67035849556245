import React from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const helperFile = require("./admin_helper.js");
const Pager = require("../comp_misc").pager;

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/admin/aircraft";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    <td>{props.item.airlineName}</td>
<td>{props.item.type}</td>
<td>{props.item.registration}</td>

  <td align="center"><button type="button" class="btn btn-outline-primary" onClick={() =>
    {
    props.parent.showModal(props.item) }
  }><i class="fa fa-edit mr-2" aria-hidden="true"></i>Edit</button></td>  

</tr>

}

class ModalList1 extends React.Component {
  

  constructor(props)
  {
    super(props);
    var params = helper.parseQuery();
    var page = params.p;
    if (!page)
      page = 0;

    this.state = {
      rows: null,
      error:null,
      image: null,
      page: page,
      
    };
  }


checkInits()
{

}

fillList(filter)
{
  var rows1 = [];

  helper.getFromServer(g_link+"?dw=get&filter="+filter)
  .then(res => {

    this.setState({showForm: false, rows: res.result, options: res.options, count: res.options.count});
  })



}
 componentDidMount() {
  this.fillList()  
  }

  showModal(item)
  {
      
//    this.setState({showForm: true, image: "/api/media/logo"+item.id+".png", id: item.id, name: item.name, email: helper.checkNull(item.email), error: ""});
    this.setState({showForm: true, id: item.id, airlineId: item.airlineId, type: item.type, registration: item.registration, alert: '', error: ""});
}

  hideModal()
  {
    this.setState({show: false});
  }

  saveItem()
  {
//    helper.postToServer(g_link, {dw: "update", id: this.state.id, email: this.state.email, name: this.state.name}, true)
    helper.postToServer(g_link, {dw: "update", id: this.state.id, airlineId: this.state.airlineId, type: this.state.type, registration: this.state.registration, }, true)
.then(res => {
      this.fillList();
    })
    .catch(err => {
      this.setState({error: err});
    })

  }
  addItem()
  {
    var item = {id: -1};
    this.showModal(item);
/*
    helper.postToServer(g_link, {dw: "add"}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })
*/
  }

  deleteItem()
  {

    helper.postToServer(g_link, {dw: "del", id: this.state.id}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }
  
  hiCheck1(event)
  {
    const target = event.target;
    const name = target.name;
    let value;
    if (target.checked)
      value = target.checked;
    else
      value = 0;

      this.setState({
        [name]: value
      });
  }

  
  filter(event)
  {
    const target = event.target;
//    const name = target.name;
    var value = target.value;

    this.fillList(value);

  }

  hic(event) {

    const target = event.target;
    const name = target.name;

    let value;
//    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'checkbox')
    {
      value = this.state[name];
      if (!value)
        value = [];
        // assumes DOM won't feed us dupes
        var vint = parseInt(target.value);

      if (target.checked)
      {
        value.push(vint);
      }
      else
      {
        var index = value.indexOf(vint);
        if (index !== -1) value.splice(index, 1);
      }
    }
    else
    {
      value = target.value;
    }
    this.setState({
      [name]: value
    });
  
  }

  onFile() {

  // OE??!! Fix this because it it confusing and only allows 1 _t per page
    var file = document.getElementById("undefined").files[0];
  
    var formData = new FormData();
    formData.append('file', file);
    formData.append('id', this.state.id)
    formData.append("dw", "saveFile")
    
    fetch(config.back+g_link+"_up", {
      method: 'POST', credentials: "same-origin",
      body: formData
    }).then(response => response.json())
    .then(res => {
      
      this.setState({image: "/api/media/undefined"+this.state.id+".png?"+new Date().getMilliseconds(),
    alert: "File was uploaded"});
    })
    .catch(error => { 
      alert(error);
    });
   //.then(response => console.log('Success:', response));
    
  }
    

  render() {
document.title = "Aircraft";

let spinner;
let pager;
if (!this.state.rows)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>;
}
else
{
  pager = <Pager page={this.state.page} count={this.state.options.count} link={g_link}/>;
}

var error1 = <div class="alert alert-danger" role="alert">
<i class="fa fa-times mr-2" aria-hidden="true"></i>{this.state.error}
</div>;
if (!this.state.error)
error1 = <div></div>;
var alert1 = <div class="alert alert-success" role="alert">
<i class="fa fa-check mr-2" aria-hidden="true"></i>{this.state.alert}
</div>;

if (!this.state.alert)
    alert1 = <div></div>

let listItems;
var filterItems = [];

if (this.state.rows)
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

  if (this.state.options.filters)
  {
    this.state.options.filters.map((item) => {
      filterItems.push(<option value={item.id}>{item.name}</option>)
    });
  }

}
if (this.state.showForm)
{
  this.checkInits();
  var airlineList = this.state.options.airlineList.map((item, index) =>
                <option value={item.id}>{item.name}</option>);

  
  

  let deleteButton;

  if (this.state.id != -1)
  {
    deleteButton = <button type="submit" class="btn btn-danger mt-4" onClick={this.deleteItem.bind(this)}>Delete</button>
  }

  return(
<div>{helperFile.nav()}

    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-plane mr-2" aria-hidden="true"></i>Aircraft Form</h2>

{/*<!-- Error & Feedback alerts Show/hide as needed -->*/}
{/*<!--
    <div class="alert alert-success" role="alert">
      <i class="fa fa-check mr-2" aria-hidden="true"></i>Aircraft details have been Saved/Updated
    </div>
    <div class="alert alert-danger" role="alert">
      <i class="fa fa-times mr-2" aria-hidden="true"></i>Error alert here
    </div>
-->*/}    
{error1}       
{alert1}
            
{/*<!-- user info block start -->*/}
        <div class="data-container">
            <div class="row mb-2">
                <div class="col-12">
                    <h4>Add/Edit Aircraft</h4>
                </div>
            </div>
            <hr class="border-primary mb-4"/>
 
{/*<!-- form start -->*/}
        <div class="row">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                <p>Aircraft must be assigned to a specific airline...</p>
                
                    <div class="form-group">
                        <select id="inputState" class="form-control"  name="airlineId" value={this.state.airlineId}  onChange={this.hic.bind(this)}>
                            <option selected>Choose Airline...</option>
                            {airlineList}
                        </select>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control w-40" id="aircraftType" placeholder="Aircraft Type"  name="type" value={this.state.type} onChange={this.hic.bind(this)} />
                        <small id="aircraftTypeHelp" class="form-text text-muted">Enter aircraft type as it should appear to crew members</small>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control w-40 text-uppercase" id="aircraftReg"  name="registration" value={this.state.registration} onChange={this.hic.bind(this)}  placeholder="Aircraft Registration"/>
                        <small id="aircraftRegHelp" class="form-text text-muted">Aircraft registration must be unique and not duplicated</small>
                    </div>
                    <div class="form-group">
                        <textarea class="form-control" id="aircraftDescription" rows="3" placeholder="Aircraft Description"></textarea>
                        <small id="aircraftDesHelp" class="form-text text-muted">Enter any specific description for this aircraft</small>
                    </div>
                    <button type="submit" class="btn btn-primary mt-4" onClick={this.saveItem.bind(this)}>Add/Update Aircraft</button>&nbsp;{deleteButton}&nbsp;<button class="btn btn-danger mt-4" onClick={() => {this.setState({showForm:false})}}>Close</button>
              
            </div>
        </div>
     </div>
 </div>
        

</div>


  )
  }
  else
  {
return(
<div>
  {spinner}
{helperFile.nav()}

    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-database mr-2" aria-hidden="true"></i>Aircraft</h2>

        <div class="data-container">
            <div class="row mb-2">
                <div class="col-8">
                    <p>Aircraft are associated with airlines, you may filter by typing the name of the airline in the Search field to show aircraft belongs to a specific airline.</p>
                </div>
                <div class="col-4">
                    <div class="card bg-secondary">
                        <div class="card-body text-center font-weight-bolder text-white">
                        Aircraft: {this.state.count}
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            

            <div class="container d-flex my-4 px-0 justify-content-between">
                <a class="btn btn-primary" role="button" onClick={this.addItem.bind(this)}><i class="fa fa-database mr-2" aria-hidden="true"></i>Add Aircraft</a>
                <input class="form-control w-40" id="myInput" type="text" placeholder="Search.. by anything"/>
            </div>
            

            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <table class="table mb-0 table-hover">
                      <thead>
                        <tr>
                          <th scope="col" width="50%" >Airline</th>
                          <th scope="col" >A/C Type</th>
                          <th scope="col" >A/C Reg</th>
                          <th scope="col" class="text-center" width="120" >Action</th>
                        </tr>
                      </thead>
                      <tbody id="myTable">
                      {listItems}
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
      

    {pager}
 {helperFile.footer()}
 
</div>

)
  }}
}


export default ModalList1
