import React from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const helperFile = require("./admin_helper.js");
const Pager = require("../comp_misc").pager;

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/admin/trainees";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    <td>{props.item.providerName}</td>
<td>{props.item.fullName}</td>
<td>{props.item.groupNames}</td>
<td>{props.item.rankName}</td>
<td>{props.item.aircraftNames}</td>
<td>{props.item.courseNames}</td>
<td>{props.item.active}</td>

  <td align="center"><button type="button" class="btn btn-outline-primary" onClick={() =>
    {
    props.parent.showModal(props.item) }
  }><i class="fa fa-edit mr-2" aria-hidden="true"></i>Edit</button></td>  
<td><a href="#" onClick={() => {
  props.parent.doLogin(props.item.id);
}}>Login as trainee</a></td>
</tr>

}

class ModalList1 extends React.Component {
  

  constructor(props)
  {
    super(props);
    var params = helper.parseQuery();
    var page = params.p;
    if (!page)
      page = 0;

    this.state = {
      rows: null,
      error:null,
      image: null,
      page: page,
      
    };
  }

doLogin(id)
{

  document.location = "/user";
  helper.postToServer("/admin/login_trainee", {userId: id})
  .then(res => {
    if (res.result == "OK")
    {
      document.location = "/user";
    }
  })


}

checkInits()
{
if (!this.state.aircraftIds)
     this.state.aircraftIds = [];
if (!this.state.groupIds)
     this.state.groupIds = [];
if (!this.state.courseIds)
     this.state.courseIds = [];

}

fillList(filter)
{
  var rows1 = [];

  helper.getFromServer(g_link+"?dw=get&filter="+filter)
  .then(res => {

    this.setState({showForm: false, rows: res.result, options: res.options, count: res.options.count});
  })



}
 componentDidMount() {
  this.fillList()  
  }

  showModal(item)
  {
      
//    this.setState({showForm: true, image: "/api/media/logo"+item.id+".png", id: item.id, name: item.name, email: helper.checkNull(item.email), error: ""});
    this.setState({showForm: true, id: item.id, rankId: item.rankId, aircraftIds: item.aircraftIds, groupIds: item.groupIds, courseIds: item.courseIds, providerId: item.providerId, firstName: item.firstName, lastName: item.lastName, email: item.email, homeBase: item.homeBase, alert: '', error: ""});
}

  hideModal()
  {
    this.setState({show: false});
  }

  saveItem()
  {
//    helper.postToServer(g_link, {dw: "update", id: this.state.id, email: this.state.email, name: this.state.name}, true)
    helper.postToServer(g_link, {dw: "update", id: this.state.id, rankId: this.state.rankId, aircraftIds: this.state.aircraftIds, groupIds: this.state.groupIds, courseIds: this.state.courseIds, providerId: this.state.providerId, firstName: this.state.firstName, lastName: this.state.lastName, email: this.state.email, homeBase: this.state.homeBase, password1: this.state.password1, password2: this.state.password2, }, true)
.then(res => {
      this.fillList();
    })
    .catch(err => {
      this.setState({error: err});
    })

  }
  addItem()
  {
    var item = {id: -1};
    this.showModal(item);
/*
    helper.postToServer(g_link, {dw: "add"}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })
*/
  }

  deleteItem()
  {

    helper.postToServer(g_link, {dw: "del", id: this.state.id}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }
  
  hiCheck1(event)
  {
    const target = event.target;
    const name = target.name;
    let value;
    if (target.checked)
      value = target.checked;
    else
      value = 0;

      this.setState({
        [name]: value
      });
  }

  
  filter(event)
  {
    const target = event.target;
//    const name = target.name;
    var value = target.value;

    this.fillList(value);

  }

  hic(event) {

    const target = event.target;
    const name = target.name;

    let value;
//    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'checkbox')
    {
      value = this.state[name];
      if (!value)
        value = [];
        // assumes DOM won't feed us dupes
        var vint = parseInt(target.value);

      if (target.checked)
      {
        value.push(vint);
      }
      else
      {
        var index = value.indexOf(vint);
        if (index !== -1) value.splice(index, 1);
      }
    }
    else
    {
      value = target.value;
    }
    this.setState({
      [name]: value
    });
  
  }

  onFile() {

  // OE??!! Fix this because it it confusing and only allows 1 _t per page
    var file = document.getElementById("undefined").files[0];
  
    var formData = new FormData();
    formData.append('file', file);
    formData.append('id', this.state.id)
    formData.append("dw", "saveFile")
    
    fetch(config.back+g_link+"_up", {
      method: 'POST', credentials: "same-origin",
      body: formData
    }).then(response => response.json())
    .then(res => {
      
      this.setState({image: "/api/media/undefined"+this.state.id+".png?"+new Date().getMilliseconds(),
    alert: "File was uploaded"});
    })
    .catch(error => { 
      alert(error);
    });
   //.then(response => console.log('Success:', response));
    
  }
    

  render() {
document.title = "Trainee User Profile List";

let spinner;
let pager;
if (!this.state.rows)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>;
}
else
{
  pager = <Pager page={this.state.page} count={this.state.options.count} link={g_link}/>;
}

var error1 = <div class="alert alert-danger" role="alert">
<i class="fa fa-times mr-2" aria-hidden="true"></i>{this.state.error}
</div>;
if (!this.state.error)
error1 = <div></div>;
var alert1 = <div class="alert alert-success" role="alert">
<i class="fa fa-check mr-2" aria-hidden="true"></i>{this.state.alert}
</div>;

if (!this.state.alert)
    alert1 = <div></div>

let listItems;
var filterItems = [];

if (this.state.rows)
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

  if (this.state.options.filters)
  {
    this.state.options.filters.map((item) => {
      filterItems.push(<option value={item.id}>{item.name}</option>)
    });
  }

}
if (this.state.showForm)
{
  this.checkInits();
  var providerList = this.state.options.providerList.map((item, index) =>
                <option value={item.id}>{item.name}</option>);
/*
  var aircraftItems = this.state.options.aircraftList.map((item, index) => <div><label><input type="checkbox"
                 name="aircraftIds" value={item.id} checked={this.state.aircraftIds.indexOf(item.id) != -1}
 onChange={this.hic.bind(this)}/> {item.name}</label></div>);

var groupItems = this.state.options.groupList.map((item, index) => <div><label><input type="checkbox"
                 name="groupIds" value={item.id} checked={this.state.groupIds.indexOf(item.id) != -1}
 onChange={this.hic.bind(this)}/> {item.name}</label></div>);
*/
var courseItems = this.state.options.courseList.map((item, index) => <div><label><input type="checkbox"
                 name="courseIds" value={item.id} checked={this.state.courseIds.indexOf(item.id) != -1}
 onChange={this.hic.bind(this)}/> {item.name}</label></div>);

/*
  var rankItems = this.state.options.rankList.map((item, index) => <div class="form-check mb-2">
            <input class="form-check-input" type="radio" name="rankId" onChange={this.hic.bind(this)}
            value={item.id} checked={this.state.rankId == item.id} /><label class="form-check-label">
              {item.name}</label></div>);
*/


  let deleteButton;

  if (this.state.id != -1)
  {
    deleteButton = <button type="submit" class="btn btn-danger mt-4" onClick={this.deleteItem.bind(this)}>Delete</button>
  }

  return(
<div>{helperFile.nav()}

    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-user mr-2" aria-hidden="true"></i>Trainee User Profile Form</h2>

{/*<!-- Error & Feedback alerts Show/hide as needed -->*/}
{/*<!--
    <div class="alert alert-success alert-dismissible fade show" role="alert">
        here is a successful dismissable alert
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="alert alert-warning alert-dismissible fade show" role="alert">
        here is a warning dismissable alert
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="alert alert-danger alert-dismissible fade show" role="alert">
        here is a danger error dismissable alert
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
-->*/}
{error1}
        
{/*<!-- user info block start -->*/}
        <div class="data-container">
            <div class="row mb-2">
                <div class="col-12">
                    <h4>Add/Edit Trainee</h4>
                </div>
            </div>
            <hr class="border-primary mb-4"/>
 
{/*<!-- form start -->*/}
        <div class="row">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                <p>Trainee user must be assigned to a specific provider:</p>
                
                    <div class="form-group">
                        <select id="inputState" class="form-control"  name="providerId" value={this.state.providerId}  onChange={this.hic.bind(this)} >
                            <option selected>Choose Provider...</option>
                            {providerList}
                        </select>
                    </div>
                <hr class="border-primary"/>
                    <div class="form-group">
                        <h5 class="text-primary">User Info:</h5>
                        <div class="form-row">
                            <div class="col-6">
                                <input type="text" class="form-control" id="firstName"  name="firstName" value={this.state.firstName} onChange={this.hic.bind(this)}  placeholder="First Name"/>
                            </div>
                            <div class="col-6">
                                <input type="text" class="form-control" id="lastName"  name="lastName" value={this.state.lastName} onChange={this.hic.bind(this)}  placeholder="Last Name"/>
                            </div>
                            <div class="col-12">
                                <small id="nameHelp" class="form-text text-muted">Name must be entered as it should appear on the certificate</small>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="email" class="form-control" id="userEmailAddress"  name="email" value={this.state.email} onChange={this.hic.bind(this)}  aria-describedby="emailHelp" placeholder="Email Address"/>
                        <small id="userEmailHelp" class="form-text text-muted">Enter email address to be used for communication with user</small>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control"  name="homeBase" value={this.state.homeBase} onChange={this.hic.bind(this)}  id="homeBase" placeholder="Home Base"/>
                    </div>
                    <div class="form-group">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="userActive" value="active" checked/>
                          <label class="form-check-label" for="userActive">Indicate if this user is Active/Inactive</label>
                        </div>
                    </div>
                <hr class="border-primary"/>
                    <h5 class="text-primary">Rank &amp; Aircraft:</h5>
                    <table class="form-table">
                        <tbody>
                            <tr>
                                <td width="100"><h6 class="m-0">Rank:</h6></td>
                                <td>
{/*<!--                                    {rankItems}

                                    <label>
                                    <input type="checkbox" name="rank" value="FC" id="rank"/>
                                    Capt</label>
                                    <br/>
                                    <label>
                                    <input type="checkbox" name="rank" value="CC" id="rank1"/>
                                    F/O</label>
-->*/}                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="form-table">
                        <tbody>
                            <tr>
                                <td width="100"><h6 class="m-0">Aircraft:</h6></td>
                                <td>You may assign one or more type for this user:</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
{/*<!--                                    {aircraftItems}

                                    <label>
                                    <input type="checkbox" name="aircraftType" value="FC" id="aircraftType"/>
                                    B757</label>
                                    <br/>
                                    <label>
                                    <input type="checkbox" name="aircraftType1" value="CC" id="aircraftType1"/>
                                    Challenger 405/505</label>
-->*/}                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                <hr class="border-primary"/>
                    <h5 class="text-primary">Enrollment:</h5>
                    <table class="form-table">
                        <tbody>
                            <tr>
                                <td width="100"><h6 class="m-0">Groups:</h6></td>
                                <td>Tick the groups that this user will be enrolled in:</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
{/*<!--                                    {groupItems}

                                    <label>
                                    <input type="checkbox" name="CheckboxGroup1" value="FC" id="CheckboxGroup1_0"/>
                                    FC</label>
                                    <br/>
                                    <label>
                                    <input type="checkbox" name="CheckboxGroup1" value="CC" id="CheckboxGroup1_1"/>
                                    CC</label>
-->*/}                                                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="form-table">
                        <tbody>
                            <tr>
                                <td width="100"><h6 class="m-0">Courses:</h6></td>
                                <td>Default courses for selected group are displayed, tick/untick courses as required:</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    {courseItems}
{/*<!--
                                    <label>
                                    <input type="checkbox" name="CheckboxGroup3" value="FC" id="CheckboxGroup1_3"/>
                                    RVSM</label>
                                    <br/>
                                    <label>
                                    <input type="checkbox" name="CheckboxGroup4" value="CC" id="CheckboxGroup1_4"/>
                                    TCAS</label>
-->*/}                                                                        
                                </td>
                            </tr>
                        </tbody>
                    </table>   
                <hr class="border-primary"/>
                    <h5 class="text-primary">Login &amp; Password:</h5>
                    <div class="form-group">
                        <input type="email" class="form-control" id="userName" aria-describedby="userNameHelp" placeholder="Email used for login"/>
                        <small id="userNameHelp" class="form-text text-muted">Your username must be your email address</small>
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" id="password"  name="password1" value={this.state.password1} onChange={this.hic.bind(this)}  placeholder="Password"/>
                        <small id="passwordHelp" class="form-text text-danger">To change password, enter and confirm below new password, otherwise leave blank</small>
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" id="confirmPassword"  name="password2" value={this.state.password2} onChange={this.hic.bind(this)}  placeholder="Confirm Password"/>
                    </div>
                    
                    

                    <button type="submit" class="btn btn-primary mt-4" onClick={this.saveItem.bind(this)}>Add/Update Trainee</button>&nbsp;{deleteButton}&nbsp;<button class="btn btn-danger mt-4" onClick={() => {this.setState({showForm:false})}}>Close</button>
              
            </div>
        </div>
     </div>
 </div>
        

</div>


  )
  }
  else
  {
return(
<div>
  {spinner}
{helperFile.nav()}

    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-database mr-2" aria-hidden="true"></i>Trainee User Profile List</h2>

        <div class="data-container">
            <div class="row mb-2">
                <div class="col-8">
                    <p></p>
                </div>
                <div class="col-4">
                    <div class="card bg-secondary">
                        <div class="card-body text-center font-weight-bolder text-white">
                        Trainees: {this.state.count}
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            

            <div class="container d-flex my-4 px-0 justify-content-between">
                <a class="btn btn-primary" role="button" onClick={this.addItem.bind(this)}><i class="fa fa-database mr-2" aria-hidden="true"></i>Add Trainee</a>
                <input class="form-control w-40" id="myInput" type="text" placeholder="Search.. by anything"/>
            </div>
            <div class="container d-flex my-4 px-0 justify-content-center"><div class="dropdown">
    <select id="inputState" class="form-control" onChange={this.filter.bind(this)}>
    <option selected>Choose Provider...</option>
    {filterItems}
    </select></div></div>


            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <table class="table mb-0 table-hover">
                      <thead>
                        <tr>
                          <th scope="col" >Provider</th>
                          <th scope="col" >Full Name</th>
                          <th scope="col" >Group</th>
                          <th scope="col" >Rank</th>
                          <th scope="col" >A/C Type</th>
                          <th scope="col" >Courses</th>
                          <th scope="col" >Activity</th>
                          <th scope="col" class="text-center" width="120" >Action</th>
                          <th scope="col" class="text-center" width="120" >Login</th>
                        </tr>
                      </thead>
                      <tbody id="myTable">
                      {listItems}
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
      

    {pager}
 {helperFile.footer()}
 
</div>

)
  }}
}


export default ModalList1
