/*
var back1 = 'https://localhost:3010/api';
var client1 = true;
var name1 = "Recurrent Pro"
var hostname = window.location.hostname;
if (hostname.indexOf("rpro.omnicode.com") != -1)
{
    back1 = "https://rpro.omnicode.com:/api"
    client1 = false;
    name1 = "Recurrent Pro"
}
if (hostname.indexOf("driller.page") != -1)
{
    back1 = hostname;
    client1 = false;
    name1 = "Driller"
}
export const back = back1;
export const client = client1;
export const name = name1;
*/


export const back = window.location.origin+"/api";
export const client = true
export const name = "Epiloting Site Manager";
