import React from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const helperFile = require("./airline_helper.js");
const Pager = require("../comp_misc").pager;

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/airline/quizzes";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    

</tr>

}

class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      show: false,
      rows: null,
      name:null,
      email:"",
      error:null,
      image: null,
    };
  }




fillList()
{
  var rows1 = [];

  helper.getFromServer(g_link+"?dw=get")
  .then(res => {

    this.setState({showForm: false, rows: res.result, options: res.options, count: res.options.count});
  })



}
 componentDidMount() {
  this.fillList()  
  }

    

  render() {
document.title = "Quizzes";

let spinner;
let pager;
if (!this.state.rows)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>;
}
else
{
  pager = <Pager page={this.state.page} count={this.state.options.count} link={g_link}/>;
}


let listItems;
if (this.state.rows)
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

}
return(
<div>
{helperFile.nav()}

{listItems} <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-list mr-2" aria-hidden="true"></i>Test Quiz &amp; Questions</h2>
        
        <div class="data-container">
            content starts here
        </div>
        
        
  </div>



    {pager}

 {helperFile.footer()}
 
</div>

)
}
}


export default ModalList1
