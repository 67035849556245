import React from "react";
const helper = require("./helper");


// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/register";


class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      crewCount:25,
      payMethod:"annualPlan",
    };
  }

  save()
  {
    alert("hey!");
  }

  hic(event) {

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
  
    //console.log(name)
    this.setState({
      [name]: value
    });
  
  }

    

  render() {
document.title = "Dashboard";

return(
<div>

        <div class="reg-form-container">
            <div class="reg-form-left">
            <a href="/">Home</a><br/><br/>
            <a href="/login">Login</a><br/><br/>
            <a href="/pricing">Pricing</a><br/><br/>
            <a href="/signup">Sign Up</a><br/><br/>
            <a href="/about">About</a><br/><br/>
                
            </div>

            <div class="reg-form-right">
                <h5 class="text-primary">Pricing for our services</h5>
                <hr/>
                <table  border="1px" >
                    <tbody>
                        <tr>
                            <td style={{width:"300px", padding:"10px"}}>
                                <h3>Essentials</h3>
                            </td>
                            <td style={{width:"300px", padding:"10px"}}>
                            <h3>Professional</h3>
                            </td>
                            <td style={{width:"300px", padding:"10px"}}>
                            <h3>Enterprise</h3>
                            </td>
                        </tr>
                        <tr>
                            <td style={{height: "100px", width:"300px", padding:"5px"}}>
                                $5/user/permonth<br/><br/>
                                <a href="/pricing">Start 30 day free trial</a>
                            </td>
                            <td style={{height: "100px", width:"300px", padding:"5px"}}>
                                $15/user/permonth<br/><br/>
                                <a href="/pricing">Start 30 day free trial</a>
                            </td>
                            <td style={{height: "100px", width:"300px", padding:"5px"}}>
                                $25/user/permonth<br/><br/>
                                <a href="/pricing">Start 30 day free trial</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
          </div>
        </div>

 
</div>

)
}
}


export default ModalList1
