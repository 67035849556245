import React from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const helperFile = require("./user_helper.js");
const Clock = require("../comp_misc").clock;

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/user/test2";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    !!table_list!!
  <td align="center"><button type="button" class="btn btn-outline-primary" onClick={() =>
    {
    props.parent.showModal(props.item) }
  }><i class="fa fa-edit mr-2" aria-hidden="true"></i>Edit</button></td>  

</tr>

}

class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    var start = helper.parseQuery()['start'];

    if (start == 1)
    {
      localStorage.setItem("answers", JSON.stringify([]));
      document.location = "/user/test2";

    }

    this.state = {
      start: start,
    };
  }


  getQuestionBlock(questions)
  {
      var list = [];
      var n = 1;
      questions.map((item) => {
          var answers = [];
          for (var i = 0; i < 5; i++)
          {
            var s = "answer"+(i+1);
            var answer = item[s];
            if (!answer || answer.length < 1)
              break;
            answers.push(<tr>
              <td width="50">a)</td>
              <td width="30"><input type="radio" name={"q"+item.id} onClick={this.hic.bind(this)} 
                checked={this.state["q"+item.id] == (i+1)}
              id="choice-a" value={(i+1)}/></td>
              <td>{answer}</td>
          </tr>)
          }
          list.push(<div class="container p-3 my-4 border border-warning bg-light">
          <div class="row">
              <div class="col-1 font-weight-bold lead">
                  {n}.
              </div>
              <div class="col-11 font-weight-bolder lead">
                  {item.body}
              </div>
          </div>
          <div class="row mt-3">
              <div class="col-10 offset-2">
                  <table class="multiple-choice">
                    {answers}
                  </table>
              </div>            
          </div>
      </div>);
      n++;
      });
      return list;
  }

  submitAnswers()
  {
/*   
    var answers = [];
      this.state.props.answers.map(a => {
        a.a = this.state["q"+a.id];
        answers.push(a);

      }) */
      helper.postToServer(g_link, {answers: this.answers})
      .then(res => {
       document.location = "/user/test3";
  
      }).catch(err => {
        helper.showError("An error has occurred");
      })
  
  }

hic(event) {

  const target = event.target;
  const name = target.name;

  let value;
//    const value = target.type === 'checkbox' ? target.checked : target.value;
  if (target.type === 'checkbox')
  {
    value = this.state[name];
    if (!value)
      value = [];
      // assumes DOM won't feed us dupes
      var vint = parseInt(target.value);

    if (target.checked)
    {
      value.push(vint);
    }
    else
    {
      var index = value.indexOf(vint);
      if (index !== -1) value.splice(index, 1);
    }
  }
  else
  {
    value = target.value;
  }

  this.answers[name.substring(1)] = value;
  localStorage.setItem("answers", JSON.stringify(this.answers));

  this.setState({
    [name]: value
  }, () => {
    

  });

}


fillList()
{
  var rows1 = [];
  if (this.state.start == 1)
    return;
    
  helper.getFromServer(g_link+"?dw=getOne&id="+this.state.id)
  .then(res => {
    if (!localStorage.getItem("answers"))
    {
      localStorage.setItem("answers", JSON.stringify([]));
    }
    this.answers = {};
    var answers = JSON.parse(localStorage.getItem("answers"));
    
    var ans = {};
    Object.keys(answers).map(id => {
      if (answers[id] != -1)
      {
        ans["q"+id] = answers[id];
        this.answers[id] = answers[id];
      }
    })

    this.setState({showForm: false, props: res.result, ...ans});
  })



}
 componentDidMount() {
  this.fillList()  
  }

    

  render() {
document.title = "Test";

let listItems;
if (this.state.rows)
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

}
if (!this.state.props)
{
  return(<div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>)
}
var questionBlock = this.getQuestionBlock(this.state.props.questions);
return(
<div>
{helperFile.nav()}

     
    <div class="container my-5">
        
        {/*<!-- course title (logo and name) -->*/}
        <div class="container d-flex mb-3 px-0 align-content-center">
            <div class="mr-2">
                <img src="assets/icon-rvsm.svg" width="100" height="100" alt=""/>
            </div>
            <div class="ml-4">
                <div class="course-title">RVSM</div>
                <p class="lead font-weight-bolder">Reduced Vertical Separation Minimum</p>
            </div>
        </div>
        
         <div class="data-container mb-4">
            <div class="container mb-3">
                <div class="row">
                    <div class="col-10 border-right">
                        <h4>Quiz Name: RVSM</h4>
                        <ul>
                            <li>Please ensure to answer all questions in one session and within the time limit displayed.</li>
                            <li>Do not leave your screen unattended as the system may shutdown automatically and give you a score of "Failed".</li>
                            <li>We strongly advise that you do not click on any menu links or exit this page as the system my re-shuffle the questions.</li>
                        </ul>
                    </div>
                    <div class="col-2">
                        <h6 class="text-danger">Questions: 20</h6>
                        <h6 class="text-danger">Pages: 1/1</h6>
                        <h6 class="text-danger"><Clock /></h6>
                    </div>
                </div>
            </div>
            
            {questionBlock}
 {/*<!-- quiz body start -->*/}{/*<!-- quiz body end -->*/}                        
            {/*<!-- here are the buttons -->*/}
            <div class="row">
                <div class="col-12">
                    <a href="#"><button type="button" class="btn btn-lg btn-primary mr-2 mt-2"><i class="fa fa-hand-point-left mr-2" aria-hidden="true"></i>Back</button></a>
                    <a href="#"><button type="button" class="btn btn-lg btn-primary mr-2 mt-2">Next<i class="fa fa-hand-point-right ml-2" aria-hidden="true"></i></button></a>
                    <button type="button"  onClick={this.submitAnswers.bind(this)} class="btn btn-lg btn-success mr-2 mt-2">Submit &amp; Review<i class="fa fa-arrow-alt-circle-right ml-2" aria-hidden="true"></i></button>
                </div>
            </div>
        </div>
    </div>



 {helperFile.footer()}
 
</div>

)
}
}

export default ModalList1
