import React, { useState, useEffect } from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const helperFile = require("./admin_helper.js");
const Pager = require("../comp_misc").pager;

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/admin/companies";

function ListItem(props) {

  const [status, setStatus] = useState('Active');

//  console.log(props)

  // Correct! There is no need to specify the key here:
  var dbExists = "Yes";
  if (!props.item.dbExists)
    dbExists = <a href="#" onClick={() => {
      alert("Create db!");
    }}>Create</a>

  var coursesExists = "Yes";
  if (!props.item.coursesExist)
    coursesExists = <a href="#">Create</a>

    useEffect(() => { 

  if (props.item.active == 0)
  {
      setStatus('Inactive');
  }

},[]);    

  return <tr>
    <td>{props.item.name}</td>
<td>{props.item.db_name}</td>
<td>{dbExists}</td>
<td>{props.item.nTrainees}</td>
<td><a href="#" onClick = {() => {

      if (status == "Active")
      {
        helper.postToServer(g_link, {dw: "setStatus", id: props.item.id, active: 0})
        .then(() => setStatus("Inactive"));
        
      }

    else
      {

        helper.postToServer(g_link, {dw: "setStatus", id: props.item.id, active: 1})
        .then(() => setStatus("Active"));

      }
      

  
}}>{status}</a></td>
<td>{coursesExists}</td>
<td><a href="#">Copy</a></td>

  <td align="center"><button type="button" class="btn btn-outline-primary" onClick={() =>
    {
    props.parent.showModal(props.item) }
  }><i class="fa fa-edit mr-2" aria-hidden="true"></i>Edit</button></td>  
<td><a href="#" onClick={() => {
  props.parent.doLogin(props.item.id);
}}>Login as airline</a></td>
</tr>

}

class ModalList1 extends React.Component {
  

  constructor(props)
  {
    super(props);
    var params = helper.parseQuery();
    var page = params.p;
    if (!page)
      page = 0;

    this.state = {
      rows: null,
      error:null,
      image: null,
      page: page,
      
    };
  }

doLogin(id)
{
  helper.postToServer("/admin/login_provider", {id: id})
  .then(res => {
    if (res.result == "OK")
    {
      document.location = "/airline";
    }
  })
}
checkInits()
{

}

fillList(filter)
{
  var rows1 = [];

  helper.getFromServer(g_link+"?dw=get&filter="+filter)
  .then(res => {

    this.setState({showForm: false, rows: res.result, options: res.options, count: res.options.count});
  })



}
 componentDidMount() {
  this.fillList()  
  }

  showModal(item)
  {
      
//    this.setState({showForm: true, image: "/api/media/logo"+item.id+".png", id: item.id, name: item.name, email: helper.checkNull(item.email), error: ""});
    this.setState({showForm: true, id: item.id, db_name: item.db_name, paymentId: item.paymentId, name: item.name, code: item.code, contact: item.contact, contactPos: item.contactPos, website: item.website, telephone: item.telephone, email: item.email, notes: item.notes, monthlyPrice: item.monthlyPrice, annualPrice: item.annualPrice, active: item.active, image: "/api/media/logo"+item.id+".png",alert: '', error: ""});
}

  hideModal()
  {
    this.setState({show: false});
  }

  saveItem()
  {
//    helper.postToServer(g_link, {dw: "update", id: this.state.id, email: this.state.email, name: this.state.name}, true)
    helper.postToServer(g_link, {dw: "update", id: this.state.id, db_name: this.state.db_name, paymentId: this.state.paymentId, name: this.state.name, code: this.state.code, contact: this.state.contact, contactPos: this.state.contactPos, website: this.state.website, telephone: this.state.telephone, email: this.state.email, notes: this.state.notes, monthlyPrice: this.state.monthlyPrice, annualPrice: this.state.annualPrice, active: this.state.active, password1: this.state.password1, password2: this.state.password2, }, true)
.then(res => {
//      console.log(res);
      this.fillList();
    })
    .catch(err => {
      this.setState({error: err});
    })

  }
  delMaster()
  {
    helper.postToServer(g_link, {dw: "delAttemps"})
    .then(() => {
      alert("Did it!");
    })
    .catch(err => {
      helper.handleError(err);
    })
  }
  addItem()
  {
    var item = {id: -1};
    this.showModal(item);
/*
    helper.postToServer(g_link, {dw: "add"}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })
*/
  }

  deleteItem()
  {

    helper.postToServer(g_link, {dw: "del", id: this.state.id}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }
  
  hiCheck1(event)
  {
    const target = event.target;
    const name = target.name;
    let value;
    if (target.checked)
      value = target.checked;
    else
      value = 0;

      this.setState({
        [name]: value
      });
  }

  
  filter(event)
  {
    const target = event.target;
//    const name = target.name;
    var value = target.value;

    this.fillList(value);

  }

  hic(event) {

    const target = event.target;
    const name = target.name;

    let value;
//    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'checkbox')
    {
      value = this.state[name];
      if (!value)
        value = [];
        // assumes DOM won't feed us dupes
        var vint = parseInt(target.value);

      if (target.checked)
      {
        value.push(vint);
      }
      else
      {
        var index = value.indexOf(vint);
        if (index !== -1) value.splice(index, 1);
      }
    }
    else
    {
      value = target.value;
    }
    this.setState({
      [name]: value
    });
  
  }

  onFile() {

  // OE??!! Fix this because it it confusing and only allows 1 _t per page
    var file = document.getElementById("logoUpload").files[0];
  
    var formData = new FormData();
    formData.append('file', file);
    formData.append('id', this.state.id)
    formData.append("dw", "saveFile")
    
    fetch(config.back+g_link+"_up", {
      method: 'POST', credentials: "same-origin",
      body: formData
    }).then(response => response.json())
    .then(res => {
      
      this.setState({image: "/api/media/logo"+this.state.id+".png?"+new Date().getMilliseconds(),
    alert: "File was uploaded"});
    })
    .catch(error => { 
      alert(error);
    });
   //.then(response => console.log('Success:', response));
    
  }
    

  render() {
document.title = "Airline Profile List";

let spinner;
let pager;
if (!this.state.rows)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>;
}
else
{
  pager = <Pager page={this.state.page} count={this.state.options.count} link={g_link}/>;
}

var error1 = <div class="alert alert-danger" role="alert">
<i class="fa fa-times mr-2" aria-hidden="true"></i>{this.state.error}
</div>;
if (!this.state.error)
error1 = <div></div>;
var alert1 = <div class="alert alert-success" role="alert">
<i class="fa fa-check mr-2" aria-hidden="true"></i>{this.state.alert}
</div>;

if (!this.state.alert)
    alert1 = <div></div>

let listItems;
var filterItems = [];

if (this.state.rows)
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

  if (this.state.options.filters)
  {
    this.state.options.filters.map((item) => {
      filterItems.push(<option value={item.id}>{item.name}</option>)
    });
  }

}
if (this.state.showForm)
{
  this.checkInits();
  
  
  var paymentItems = this.state.options.paymentList.map((item, index) => <div class="form-check mb-2">
            <input class="form-check-input" type="radio" name="paymentId" onChange={this.hic.bind(this)}
            value={item.id} checked={this.state.paymentId == item.id} /><label class="form-check-label">
              {item.name}</label></div>);



  let deleteButton;

  if (this.state.id != -1)
  {
    deleteButton = <button type="submit" class="btn btn-danger mt-4" onClick={this.deleteItem.bind(this)}>Delete</button>
  }

  return(
<div>{helperFile.nav()}

    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-database mr-2" aria-hidden="true"></i>Airline Profile Form</h2>

{/*<!-- Error & Feedback alerts Show/hide as needed -->*/}
{/*<!--
    <div class="alert alert-success alert-dismissible fade show" role="alert">
        Airline details has been saved/updated successfully
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="alert alert-warning alert-dismissible fade show" role="alert">
        here is a warning dismissable alert
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="alert alert-danger alert-dismissible fade show" role="alert">
        here is a danger error dismissable alert
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
-->*/}
{error1}       
{alert1}
{/*<!-- user info block start -->*/}
        <div class="data-container">
            <div class="row mb-2">
                <div class="col-12">
                    <h4>Add/Edit Airline</h4>
                </div>
            </div>
            <hr class="border-primary mb-4"/>
 
{/*<!-- form start -->*/}            
        
            <div class="row">
                <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                    <h5 class="text-primary">Airline Info:</h5>
                    <div class="form-group">
                        <input type="text" class="form-control" id="airlineName" placeholder="Airline Name"  name="name" value={this.state.name} onChange={this.hic.bind(this)} />
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" id="db_name"  name="db_name" value={this.state.db_name} onChange={this.hic.bind(this)} placeholder="Database name"/>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control w-30 text-center text-uppercase"  name="code" value={this.state.code} onChange={this.hic.bind(this)}  placeholder="3 LetterCode"/>
                        <small id="airline3LCodeHelp" class="form-text text-muted">Airline 3 Letter code unique ID</small>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" id="contactName"  name="contact" value={this.state.contact} onChange={this.hic.bind(this)}  placeholder="Contact Name.. i.e John Smith"/>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" id="jobPosition"  name="contactPos" value={this.state.contactPos} onChange={this.hic.bind(this)}  placeholder="Contact Job Position"/>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" id="airlineWebsite"  name="website" value={this.state.website} onChange={this.hic.bind(this)}  placeholder="Airline Website"/>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" id="telephone"  name="telephone" value={this.state.telephone} onChange={this.hic.bind(this)}  placeholder="Telephone.. include country code"/>
                    </div>
                    <hr class="border-primary"/>
                        <h5 class="text-primary">Airline Logo:</h5>
                        <p>Airline logo maximum height 50px, must be in png or svg formats:</p>
                        <div class="form-group">
                            <input type="file" class="form-control-file" id="logoUpload"  name="logo" value={this.state.logo} onChange={this.onFile.bind(this)} />
                            <img src={this.state.image}/>
                        </div>
                    <hr class="border-primary"/>
                    <h5 class="text-primary">Login &amp; Password:</h5>
                    <div class="form-group">
                        <input type="email" class="form-control" id="airlineEmail"  name="email" value={this.state.email} onChange={this.hic.bind(this)}  aria-describedby="airlineEmailHelp" placeholder="Email used for login"/>
                        <small id="airlineEmailHelp" class="form-text text-muted">Airline email is used for login</small>
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" id="password"  name="password1" value={this.state.password1} onChange={this.hic.bind(this)}  placeholder="Password"/>
                        <small id="passwordHelp" class="form-text text-danger">To change password, enter here and confirm below new password, otherwise leave blank</small>
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" id="confirmPassword"  name="password2" value={this.state.password2} onChange={this.hic.bind(this)}  placeholder="Confirm Password"/>
                    </div>
                    <hr class="border-primary"/>
                    <div class="form-group">
                        <textarea class="form-control" id="airlineNotes" rows="4"  name="notes" value={this.state.notes} onChange={this.hic.bind(this)}  placeholder="Notes to remember"></textarea>
                    </div>
                </div>
               
{/*<!-- form right side column -->*/}             
                <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                    <h5 class="text-primary">User Activity:</h5>
                    <div class="form-group">
                        <p>Switch Off/On user login status, airline admin user and trainee users cannot login while switched Off, default value On:</p>
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input" id="userActivity" name="active" checked={this.state.active} onChange={this.hiCheck1.bind(this)}/>
                          <label class="custom-control-label" for="userActivity">Inactive/Active</label>
                        </div>
                    </div>
                    <hr class="border-primary"/>
                    <h5 class="text-primary">User Pay Method:</h5>
                    <p>User selected plan at time of registration, We can switch user here between Monthly/Annual</p>
                    {paymentItems}
{/*<!--
                    <div class="form-check mb-2">
                        <input class="form-check-input" type="radio" name="payMethod" id="monthlyPlan" value="monthlyPlan"/>
                        <label class="form-check-label" for="monthlyPlan">
                            Monthly Plan
                        </label>
                    </div>
                    <div class="form-check mb-2">
                        <input class="form-check-input" type="radio" name="payMethod" id="annualPlan" value="annualPlan"/>
                        <label class="form-check-label" for="annualPlan">
                            Annual Plan
                        </label>
                    </div>
-->*/}                    
                    <hr class="border-primary"/>
                    <h5 class="text-primary">User Pay Plan:</h5>
                    <p>Report total current assigned users, price fields are for our own input, agreed price entered manually:</p>
                    <table class="table">
                        <tbody>
                            <tr>
                                <td class="m-0 p-0">Assigned Users:</td>
                                <td align="right">25</td>
                            </tr>
                            <tr>
                                <td class="m-0 p-0">Monthly Price:</td>
                                <td><input type="text" class="form-control" id="monthlyCharge"  name="monthlyPrice" value={this.state.monthlyPrice} onChange={this.hic.bind(this)} /></td>
                            </tr>
                            <tr>
                                <td class="m-0 p-0">Annual Price:</td>
                                <td><input type="text" class="form-control" id="annualCharge"  name="annualPrice" value={this.state.annualPrice} onChange={this.hic.bind(this)} /></td>
                            </tr>
                        </tbody>
                    </table>

                        
                    <hr class="border-primary"/>
                </div>
            </div>

{/*<!-- form submit buttons -->*/}
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                     <hr class="border-primary"/>
                    <button type="submit" class="btn btn-primary mt-4" onClick={this.saveItem.bind(this)}>Add/Update Airline</button>&nbsp;{deleteButton}&nbsp;<button class="btn btn-danger mt-4" onClick={() => {this.setState({showForm:false})}}>Close</button>
                </div>
            </div>
        
     </div>
 </div>


</div>


  )
  }
  else
  {
return(
<div>
  {spinner}
{helperFile.nav()}

    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-database mr-2" aria-hidden="true"></i>Airline Profile List</h2>

        <div class="data-container">
            <div class="row mb-2">
                <div class="col-8">
                    <p>Airlines are the main account holder for the airline admin, use filters to narrow down searches.</p>
                </div>
                <div class="col-4">
                    <div class="card bg-secondary">
                        <div class="card-body text-center font-weight-bolder text-white">
                        Airlines: {this.state.count}
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            

            <div class="container d-flex my-4 px-0 justify-content-between">
                <a class="btn btn-primary" role="button" onClick={this.addItem.bind(this)}><i class="fa fa-database mr-2" aria-hidden="true"></i>Add Airline</a>
                <a class="btn btn-primary" role="button" onClick={this.delMaster.bind(this)}><i class="fa fa-database mr-2" aria-hidden="true"></i>Delete Master quiz attempts</a>
                <input class="form-control w-40" id="myInput" type="text" placeholder="Search.. by anything"/>
            </div>
            

            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <table class="table mb-0 table-hover">
                      <thead>
                        <tr>
                          <th scope="col" >Airline</th>
                          <th scope="col" >Database name</th>
                          <th scope="col" >Db exists</th>
                          <th scope="col" ># Trainees</th>
                          <th scope="col" >Status</th>
                          <th scope="col" >Courses folder</th>
                          <th scope="col" >Populate Quizzes</th>
                          <th scope="col" class="text-center" width="120" >Action</th>
                        </tr>
                      </thead>
                      <tbody id="myTable">
                      {listItems}
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
      

    {pager}
 {helperFile.footer()}
 
</div>

)
  }}
}


export default ModalList1
