import React from "react";

function AdminNav(){
  return (

<div class="container-fluid bg-warning">
    <div class="container">
        {/*<!-- toggle responsive -->*/}
        <nav class="navbar navbar-expand-lg navbar-light bg-warning"> <a class="navbar-brand" href="#"><img src="assets/rpro-hdr-logo.svg" width="200" height="45" alt="RecurrentPro"/></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"> <span class="navbar-toggler-icon"></span></button>
        {/*<!-- menu items -->*/}    
        <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item"> <a class="nav-link" href="/admin/dashboard"><i class="fa fa-tachometer-alt mr-1" aria-hidden="true"></i>Dashboard</a></li>
                <li class="nav-item"> <a class="nav-link" href="/admin/groups"><i class="fa fa-sitemap mr-1" aria-hidden="true"></i>Groups</a></li>
                <li class="nav-item dropdown"><a class="nav-link dropdown-toggle" href="#" id="coursesDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-paste mr-1" aria-hidden="true"></i>Courses</a>
                    <div class="dropdown-menu" aria-labelledby="coursesDropdownMenuLink">
                    <a class="dropdown-item" href="/admin/media"><i class="fa fa-book mr-1" aria-hidden="true"></i>Media</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="/admin/courses"><i class="fa fa-paste mr-1" aria-hidden="true"></i>Courses</a>
                        <div class="dropdown-divider"></div>
{/*
                        <a class="dropdown-item" href="/admin/manuals"><i class="fa fa-book mr-1" aria-hidden="true"></i>Manuals</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="/admin/videos"><i class="fa fa-video mr-1" aria-hidden="true"></i>Videos</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="/admin/guidelines"><i class="fa fa-graduation-cap mr-1" aria-hidden="true"></i>Guidelines</a>
                        <div class="dropdown-divider"></div>
*/}                                                                        
                        <a class="dropdown-item" href="/admin/quizzes"><i class="fa fa-list mr-1" aria-hidden="true"></i>Quizzes</a>
                        <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="/admin/certificates"><i class="fa fa-certificate mr-1" aria-hidden="true"></i>Certificates</a>

                    </div>
                </li>
{/*
                <li class="nav-item dropdown"><a class="nav-link dropdown-toggle" href="#" id="assignDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-check-square mr-1" aria-hidden="true"></i>Assign</a>
                    <div class="dropdown-menu" aria-labelledby="aircraftDropdownMenuLink"><a class="dropdown-item" href="/admin/aircraft">
                    <i class="fa fa-plane mr-1" aria-hidden="true"></i>Aircraft</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="/admin/ranks"><i class="fa fa-bars mr-1" aria-hidden="true"></i>Rank</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="/admin/classes"><i class="fa fa-tags mr-1" aria-hidden="true"></i>Classes</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="/admin/certificates"><i class="fa fa-certificate mr-1" aria-hidden="true"></i>Certificates</a>
                    </div>
                </li>
*/}
                <li class="nav-item dropdown"><a class="nav-link dropdown-toggle" href="#" id="usersDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-users mr-1" aria-hidden="true"></i>Users</a>
                    <div class="dropdown-menu" aria-labelledby="usersDropdownMenuLink"> <a class="dropdown-item" href="/admin/airlines"><i class="fa fa-database mr-1" aria-hidden="true"></i>Providers</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="/admin/trainees"><i class="fa fa-user mr-1" aria-hidden="true"></i>Trainees</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="/admin/users?t=admin"><i class="fa fa-address-card mr-1" aria-hidden="true"></i>Admin</a>
                    </div>
                </li>
                  
                <li class="nav-item dropdown"> <a class="nav-link dropdown-toggle" href="#" id="adminDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-tasks mr-1" aria-hidden="true"></i>Admin</a>
                    <div class="dropdown-menu" aria-labelledby="adminDropdownMenuLink">
                    <a class="dropdown-item" href="/admin/invoices"><i class="fa fa-bookmark mr-1" aria-hidden="true"></i>Invoices</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="/admin/wikis"><i class="fa fa-bookmark mr-1" aria-hidden="true"></i>Edit Help Wikis</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="/admin/processes"><i class="fa fa-bookmark mr-1" aria-hidden="true"></i>Batch Processes</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="/admin/logins"><i class="fa fa-bookmark mr-1" aria-hidden="true"></i>System Logs</a>

{/*                    
                    <a class="dropdown-item" href="/admin/reports"><i class="fa fa-bookmark mr-1" aria-hidden="true"></i>Reports</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="/admin/billing"><i class="fa fa-dollar-sign mr-1" aria-hidden="true"></i>Billing</a>
*/}                    
                    </div>
                </li>
              </ul>
            </div>
        </nav>
    </div>
</div>
)
}

export const nav = AdminNav;
function Footer(){
  return (<div className="footer">        <div className="mr-3"><a href="#"><img src="/rpro-hdr-logo.svg" width="200" height="45" alt="RecurrentPro"/></a></div>        <div className="mt-2">All rights reserved, material Copyright &copy; – For support contact: support@recurrentpro.com</div>    </div>)}

export const footer = Footer;
