import React from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const helperFile = require("./airline_helper.js");

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/airline/crew";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    
                        
                          <td>{props.item.courseName}</td>
                          <td>{props.item.className}</td>
                          <td>{props.item.validity} Months</td>
                          <td>{props.item.lastAttempt}</td>
                          <td>{props.item.expireDate}</td>
                          <td>{props.item.daysLeft}</td>
                          <td>{props.item.score}</td>
                          <td>{props.item.grade}</td>
                          <td><img src={"/status-"+props.item.statusColor+".svg"} width="30" height="30" alt={props.item.statusColor}/></td>
                        
                        
</tr>

}

class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    var id = helper.parseQuery()['id'];

    this.state = {
      id: id,
    };
  }




fillList()
{
    helper.getFromServer(g_link+"?dw=getOne&id="+this.state.id)
  .then(res => {

    this.setState({showForm: false, props: res.result.vals, rows: res.result.rows});
  })



}
 componentDidMount() {
  this.fillList()  
  }

    

  render() {
document.title = "Crew";

let listItems;
let spinner;
if (!this.state.props)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>;

}
else 
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

}
if (!this.state.props)
{
  return(<div>
  {spinner}    
  </div>)
}
return(
<div>
{helperFile.nav()}


    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-id-card mr-2" aria-hidden="true"></i>Crew Record</h2>

{/*<!-- info block start -->*/}
        <div class="data-container">
            <div class="row mb-5">
                <div class="col-8">
                    <h2 Class="text-danger">{this.state.props.name}</h2>
                    <p>Current status of this user showing all last attempts during training preiod</p>
                </div>
                <div class="col-4">
                    <div class="card bg-secondary">
                        <div class="card-body text-center font-weight-bolder text-white">
                        Courses: {this.state.props.count}
                        </div>
                    </div>
                </div>
            </div>

{/*<!-- table list start -->*/}
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                  {/*<!-- list1 -->*/}
                    <table class="table mb-0 table-hover">
                      <thead>
                        <tr>
                          <th scope="col">Course</th>
                          <th scope="col">Class</th>
                          <th scope="col">Validity</th>
                          <th scope="col">Last Attempt</th>
                          <th scope="col">Expiry Date</th>
                          <th scope="col">Days Left</th>
                          <th scope="col">Score</th>
                          <th scope="col">Grade</th>
                          <th scope="col">Status</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        {/*<!-- row start -->*/}{listItems}{/*<!-- row end -->*/}
                      </tbody>
                    </table>
                    {/*<!-- list1End -->*/}
                </div>
            </div>
        </div>
    </div>



 {helperFile.footer()}
 
</div>

)
}
}


export default ModalList1
