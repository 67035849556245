import React from "react";

function UserNav(){
  return (

      <div class="container-fluid bg-warning">
        <div class="container px-0">
            <nav class="navbar navbar-expand-lg navbar-light bg-warning">
              <a class="navbar-brand" href="#"><img src="assets/rpro-brand-logo.svg" width="58" height="50" alt="RecurrentPro"/></a>
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                  <li class="nav-item">
                    <a class="nav-link" href="/user/dashboard"><i class="fa fa-tachometer-alt mr-1" aria-hidden="true"></i>Dashboard</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/user/courses"><i class="fa fa-paste mr-1" aria-hidden="true"></i>Courses</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/user/attempts"><i class="fa fa-file mr-1" aria-hidden="true"></i>Attempts</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/user/certificates"><i class="fa fa-certificate mr-1" aria-hidden="true"></i>Certificates</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/user/notifications"><i class="fa fa-envelope-open mr-1" aria-hidden="true"></i>Notification</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/user/help"><i class="fa fa-info-circle mr-1" aria-hidden="true"></i>Help</a>
                  </li>
                </ul>
              </div>
            </nav>
        </div>
    </div>
)
}

export const nav = UserNav;
function Footer(){
  return (<div className="footer">        <div className="mr-3"><a href="#"><img src="/rpro-hdr-logo.svg" width="200" height="45" alt="RecurrentPro"/></a></div>        <div className="mt-2">All rights reserved, material Copyright &copy; – For support contact: support@recurrentpro.com</div>    </div>)}

export const footer = Footer;
