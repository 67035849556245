import './App.css';
const React = require("react")
const Component = React.Component
const helper = require("./helper")

//export const formConfirm = FormConfirm;

/*
class OutsideAlerter extends Component {
    constructor(props) {
      super(props);
// https://stackoverflow.com/questions/32553158/detect-click-outside-react-component  
      this.setWrapperRef = this.setWrapperRef.bind(this);
      this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    setWrapperRef(node) {
      this.wrapperRef = node;
    }
  
    handleClickOutside(event) {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
//        alert('You clicked outside of me!');
        console.log("click!");
      }
    }
  
    render() {
      return <div ref={this.setWrapperRef}>{this.props.children}</div>;
    }
  }
  
  OutsideAlerter.propTypes = {
    children: PropTypes.element.isRequired,
  };
  
*/

class TextAreaPrompt extends Component {
 
render()
{
    return(
<div className="form-group row">
<label htmlFor="description" className="col-sm-2 col-form-label">{this.props.title}:</label><div className="col-sm-6">
<textarea  className="form-control"  rows="4" onChange={this.props.onChange} value={this.props.value} id={this.props.eid} name={this.props.name} />
</div>
</div>
    )
}
}

export const textAreaPrompt = TextAreaPrompt

class TextPrompt extends Component {
 
render()
{
    return(
<div className="form-group row">
  <label htmlFor="name" className="col-sm-2 col-form-label">{this.props.title}:</label><div className="col-sm-7">
  <input className="form-control" type='text' onChange={this.props.onChange} id={this.props.eid} value={this.props.value} name={this.props.name} />
  </div>
</div>
    )
}
}

class EmailPrompt extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            value: props.value };
//            name: props.name
      }
 
render()
{
    return(
<div class="form-group row">
  <label for="name" class="col-sm-2 col-form-label">{this.props.title}:</label><div class="col-sm-7"><input class="form-control" type='email' id={this.props.eid} onChange={this.props.onChange1} value={this.props.value} name='name' /></div>
</div>
    )
}
}

export const textPrompt = TextPrompt

class FormContact extends Component {
    constructor(props) {
       super(props);
       this.state = { hasError: false };
   
     }
  
handleSend()
{

    // no need for config.back or api cuz helper does it
    helper.postToServer("/psupport", {email: document.getElementById("email").value, message: document.getElementById("message").value})
    .then(resp => {
        alert("Thanks for your message.  We will be in touch shortly.")
        document.getElementById("email").value = ""
        document.getElementById("message").value = ""

    })
    .catch(err => {
        alert(err)
    })

}

  render()
    {
      return(<div className="container">
      <br/>
      Please enter your message.
      <br/>
      <br/>
      <EmailPrompt title="Email" value={this.state.email} eid="email" />
      <TextAreaPrompt title="Message" value={this.state.message} eid="message"/>
      <input type="button" className="btn btn-primary" onClick={this.handleSend.bind(this)} value="Send"/>
      </div>)
    }
  
  
    } 
  
    export const formContact = FormContact

class CompPager extends Component{
constructor(props){
    super(props)
    var nPages = props.nRows/props.pageSize
    var p = props.offset-props.pageSize
    if (p < 0)
        p = 0
    var n = props.offset+props.pageSize
    if (n > props.nRows-1)
        n = props.nRows-1
    this.state = {
        nPages: nPages,
        p: p,
        n: n,
    }

}
render() {
    var middle = []
    for (var i = 0; i < this.state.nPages; i++)
    {
        middle.push(<a className="w3-button" href={this.props.link+"?o="+i*this.props.pageSize}>{i+1}</a>)
    }

    return(
    <div className="w3-bar">
    <a className="w3-button" href={this.props.link+"?o="+this.state.p}>Previous</a>
    {middle}
    <a className="w3-button" href={this.props.link+"?o="+this.state.n}>Next</a>
  </div>
    )

}
}

export const compPager = CompPager

class CompSearch extends Component {

    render()
    {
        return(
            <div>Topics:</div>
        )
    }
    }
    
    export const compSearch = CompSearch
    
    class CompSpace extends Component {
    /// OE??!!! Note - this won't work with weird CSS float styles
        render()
        {
            return(
                <>&nbsp;</>
            )
        }
        }
        
    export const Space = CompSpace
    
    
    class CompList extends Component {

        render(){

            return(
                <div className="w3-row" key="0">{this.props.children}</div>
            )

        }

    }
export const compList = CompList

    class CompListItem extends Component {

        render(){

            return(
                <div className="col-sm">{this.props.children}</div>
            )

        }

    }
export const compListItem = CompListItem

/*
const Modal = ({ handleClose, show, children }) => {
    const showHideClassname = show ? "modal display-block" : "modal display-none";
  
    return (
      <div className={showHideClassname}>
        <section className="modal-main">
        <div className="container">
  
          {children}
          <button onClick={handleClose}>Close</button>
          </div>
          <br/>
  
        </section>
      </div>
    );
  };
*/

class Modal extends React.Component {

render()
{
    return (
      <div className={this.props.show ? "modal display-block" : "modal display-none"}>
        <section className="modal-main" id={this.props.id}>
        <div className="container">
  
          {this.props.children}
          <button onClick={this.props.handleClose}>Close</button>
          </div>
          <br/>
  
        </section>
      </div>
    );
  }

}

  export const modal = Modal;


  class ModalForm extends React.Component {

    constructor(props)
    {
      super(props);
      this.state = {
        value: null,
        postItem:null,
      };
    }
/*    setData(data)
    {
        this.setState({value: data.content});

    } */
    componentDidMount()
    {
// it's mounted invisible.  We mainly care about when it's popped
    }
    handleInputChange(event) {

      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
    
      console.log(name)
      this.setState({
        [name]: value
      });
    
    }
    
    render()
    {
        return (
          
          <div className={this.props.show ? "modal display-block" : "modal display-none"}>
            <section className="modal-main" id={this.props.id}>
            <div className="container">
      Value: <input type="text" name="value" value={this.state.value} onChange={this.handleInputChange.bind(this)} /><br/>
              {this.props.children}
              <button onClick={() => {
                this.state.updateItem();
              }}>Save</button>
              <button onClick={() => {
                this.state.deleteItem();
              }}>Delete</button>

              <button onClick={() => {
                this.props.parent.setState({modalShowing:false});
              }}>Close</button>

              </div>
              <br/>
      
            </section>
          </div>
        );
      }
    
    }
    
export const modalForm = ModalForm;

  class MessageBox extends React.Component
  {
  
    handleClose()
    {
        this.props.parent.setState({showMessage: null});
    } 

  render()
  {
  
    const showHideClassname = this.props.parent.state.showMessage ? "modal display-block" : "modal display-none";
  

    return (
      <div className={showHideClassname}>
        <section className="modal-main">
        <div className="container">
        <br/>
          {this.props.parent.state.showMessage}<br/><br/>
          <button onClick={this.handleClose.bind(this)}>Close</button>
          </div>
          <br/>
  
        </section>
      </div>
    );
  }
}
export const messageBox = MessageBox;

class Pager extends React.Component
{

  render()
  {
    console.log(this.props)
    var page = Number(this.props.page);
    var list = [];
    for (var i = 0; i < this.props.count/20; i++)
    {
      if (page != i)
        list.push(<li class="page-item"><a class="page-link" href={this.props.link+"?p="+i}>{i+1}</a></li>);
      else
        list.push(<li class="page-item"><div class="page-link">{i+1}</div></li>);

    }
console.log(this.props.count)

if (!this.props.count || this.props.count < 20)
{
  return(<div></div>)
}
else
{
    return(
      <nav aria-label="Page navigation">
      <ul class="pagination justify-content-center">
        <li class="page-item">
          <a class="page-link" href={this.props.link+"?p="+(page-1)} tabindex="-1">Previous</a>
        </li>
        {list}
        <li class="page-item"><a class="page-link" href={this.props.link+"?p="+(page+1)}>Next</a>
        </li>
      </ul>
    </nav>

    )
    }
  }

}

export const pager = Pager;


class Clock extends React.Component {
  constructor(props) {
    super(props);
    var t = new Date();
    t.setSeconds(t.getSeconds() + 30*60);
//    var endDate = new Date("Mar 15, 2019 12:00:00").getTime();
    this.state = {date: new Date(), endDate: t};
  }

  componentDidMount() {
    this.timerID = setInterval(
      () => this.tick(),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.setState({
      date: new Date()
    });
  }

  render() {

    let now = new Date().getTime(); 
    let t = this.state.endDate - now; 
    var date = new Date(0);
    date.setSeconds(t/1000);

    var d = date.toISOString().substr(11, 8);
/*    
    if (t >= 0) {

      days = Math.floor(t / (1000 * 60 * 60 * 24));
      hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      mins = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
      secs = Math.floor((t % (1000 * 60)) / 1000);
  }
*/
    return (
      <div>
        {d}
      </div>
    );
  }
}


export const clock = Clock;

class select1 extends React.Component
{

  componentDidMount()
  {
    
  }
  render()
  {
    var list = [];
//    list.push(<option>Please Select</option>)
    this.props.options.map(item => {
      list.push(<option key ={item.id} value={item.id}>{item.name}</option>);
    })
  
    return (<>
    <select name={this.props.name} value={this.props.value} onChange={this.props.onChange}>
      {list}
    </select>
    </>);
  }

}

export const Select1 = select1

class filterBox extends React.Component {

    
  render()
  {
      var options = [];
      options.push(<option>All</option>);
      this.props.options.map(item => {
          options.push(<option value={item.id}>{item.name}</option>);
      })
      return (
      <><select onChange={this.props.onChange}  >{options}</select></>
      );
  }

}

export const FilterBox = filterBox;