import React from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const helperFile = require("./admin_helper.js");
const Pager = require("../comp_misc").pager;

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/admin/courses";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    <td>{props.item.providerName}</td>
    <td>{props.item.name}</td>
<td>{props.item.ref}</td>
<td>{props.item.airlineName}</td>
<td>{props.item.className}</td>
<td>{props.item.validity}</td>
<td>{props.item.warning}</td>
<td>{props.item.quizName}</td>

  <td align="center"><button type="button" class="btn btn-outline-primary" onClick={() =>
    {
    props.parent.showModal(props.item) }
  }><i class="fa fa-edit mr-2" aria-hidden="true"></i>Edit</button></td>  

</tr>

}

class ModalList1 extends React.Component {
  

  constructor(props)
  {
    super(props);
    var params = helper.parseQuery();
    var page = params.p;
    if (!page)
      page = 0;

    this.state = {
      rows: null,
      error:null,
      image: null,
      page: page,
      
    };
  }


checkInits()
{

}

fillList(filter)
{
  var rows1 = [];

  helper.getFromServer(g_link+"?dw=get&filter="+filter)
  .then(res => {

    this.setState({showForm: false, rows: res.result, options: res.options, count: res.options.count});
  })



}
 componentDidMount() {
  this.fillList()  
  }

  showModal(item)
  {
      
//    this.setState({showForm: true, image: "/api/media/logo"+item.id+".png", id: item.id, name: item.name, email: helper.checkNull(item.email), error: ""});
    this.setState({showForm: true, id: item.id, providerId: item.providerId, classId: item.classId, quizId: item.quizId, name: item.name, 
        description: item.description, validity: item.validity, warning: item.warning, image: "/api/media/course"+item.id+".svg",alert: '', error: ""});
}

  hideModal()
  {
    this.setState({show: false});
  }

  saveItem()
  {
//    helper.postToServer(g_link, {dw: "update", id: this.state.id, email: this.state.email, name: this.state.name}, true)
    helper.postToServer(g_link, {dw: "update", id: this.state.id, airlineId: this.state.airlineId, classId: this.state.classId, quizId: this.state.quizId, name: this.state.name, description: this.state.description, validity: this.state.validity, warning: this.state.warning, }, true)
.then(res => {
      this.fillList();
    })
    .catch(err => {
      this.setState({error: err});
    })

  }
  addItem()
  {
    var item = {id: -1};
    this.showModal(item);
/*
    helper.postToServer(g_link, {dw: "add"}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })
*/
  }

  deleteItem()
  {

    helper.postToServer(g_link, {dw: "del", id: this.state.id}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }
  
  hiCheck1(event)
  {
    const target = event.target;
    const name = target.name;
    let value;
    if (target.checked)
      value = target.checked;
    else
      value = 0;

      this.setState({
        [name]: value
      });
  }

  
  filter(event)
  {
    const target = event.target;
//    const name = target.name;
    var value = target.value;

    this.fillList(value);

  }

  hic(event) {

    const target = event.target;
    const name = target.name;

    let value;
//    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'checkbox')
    {
      value = this.state[name];
      if (!value)
        value = [];
        // assumes DOM won't feed us dupes
        var vint = parseInt(target.value);

      if (target.checked)
      {
        value.push(vint);
      }
      else
      {
        var index = value.indexOf(vint);
        if (index !== -1) value.splice(index, 1);
      }
    }
    else
    {
      value = target.value;
    }
    this.setState({
      [name]: value
    });
  
  }

  onFile() {

  // OE??!! Fix this because it it confusing and only allows 1 _t per page
    var file = document.getElementById("courseUpload").files[0];
  
    var formData = new FormData();
    formData.append('file', file);
    formData.append('id', this.state.id)
    formData.append("dw", "saveFile")
    
    fetch(config.back+g_link+"_up", {
      method: 'POST', credentials: "same-origin",
      body: formData
    }).then(response => response.json())
    .then(res => {
      
      this.setState({image: "/api/media/course"+this.state.id+".svg?"+new Date().getMilliseconds(),
    alert: "File was uploaded"});
    })
    .catch(error => { 
      alert(error);
    });
   //.then(response => console.log('Success:', response));
    
  }
    

  render() {
document.title = "Courses";

let spinner;
let pager;
if (!this.state.rows)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>;
}
else
{
  pager = <Pager page={this.state.page} count={this.state.options.count} link={g_link}/>;
}

var error1 = <div class="alert alert-danger" role="alert">
<i class="fa fa-times mr-2" aria-hidden="true"></i>{this.state.error}
</div>;
if (!this.state.error)
error1 = <div></div>;
var alert1 = <div class="alert alert-success" role="alert">
<i class="fa fa-check mr-2" aria-hidden="true"></i>{this.state.alert}
</div>;

if (!this.state.alert)
    alert1 = <div></div>

let listItems;
var filterItems = [];

if (this.state.rows)
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

  if (this.state.options.providerList)
  {
    this.state.options.providerList.map((item) => {
      filterItems.push(<option value={item.id}>{item.name}</option>)
    });
  }

}
if (this.state.showForm)
{
  this.checkInits();
  var airlineList = this.state.options.providerList.map((item, index) =>
                <option value={item.id}>{item.name}</option>);
var classList = this.state.options.classList.map((item, index) =>
                <option value={item.id}>{item.name}</option>);
var quizList = this.state.options.quizList.map((item, index) =>
                <option value={item.id}>{item.name}</option>);

  
  

  let deleteButton;

  if (this.state.id != -1)
  {
    deleteButton = <button type="submit" class="btn btn-danger mt-4" onClick={this.deleteItem.bind(this)}>Delete</button>
  }

  return(
<div>{helperFile.nav()}

    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-paste mr-2" aria-hidden="true"></i>Course Form</h2>

{/*<!-- Error & Feedback alerts Show/hide as needed -->*/}
{/*<!--
    <div class="alert alert-success alert-dismissible fade show" role="alert">
        here is a successful dismissable alert
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="alert alert-warning alert-dismissible fade show" role="alert">
        here is a warning dismissable alert
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="alert alert-danger alert-dismissible fade show" role="alert">
        here is a danger error dismissable alert
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
-->*/}
{error1}

{/*<!-- user info block start -->*/}
        <div class="data-container">
            <div class="row mb-2">
                <div class="col-12">
                    <h4>Add/Edit Course</h4>
                </div>
            </div>
            <hr class="border-primary mb-4"/>
            
{/*<!-- form start -->*/}
        <div class="row">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                        <h5 class="text-primary">Course Assignment:</h5>
                        <div class="form-check my-3">
                            <label class="form-check-label" for="globalCourse">Note: Recurrent Pro courses are global to all providers</label>
                        </div>
                    </div>
                    <div class="form-group">
                        <select id="selectAirline"  name="providerId" value={this.state.providerId}  onChange={this.hic.bind(this)} class="form-control">
                            <option selected>Assign this course for a specific airline</option>
                            {airlineList}
                        </select>
                    </div>
                    
                    <hr class="border-primary my-4"/>
                    
                    <h5 class="text-primary mb-3">Course Details:</h5>
                    <div class="form-group">
                        <input type="text" class="form-control w-20 text-center text-uppercase" id="courseRef" placeholder="Ref"/>
                        <small id="courseRefHelp" class="form-text text-muted">Enter short max 4 digits reference, two starting letters and number</small>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" id="courseName"  name="name" value={this.state.name} onChange={this.hic.bind(this)}  placeholder="Course Name"/>
                        <small id="courseNameHelp" class="form-text text-muted">Course name as required, for example RVSM</small>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" id="courseDescription"   name="description" value={this.state.description} onChange={this.hic.bind(this)}  placeholder="Course Description"/>
                        <small id="courseDescriptionHelp" class="form-text text-muted">Short description of the course, optional</small>
                    </div>
                    <div class="form-group">
                        <select id="selectClass"  name="classId" value={this.state.classId}  onChange={this.hic.bind(this)} class="form-control">
                            <option selected>Select Class</option>
                            {classList}
                        </select>
                    </div>
                    
                    <hr class="border-primary my-4"/>
                    
                    <h5 class="text-primary mb-3">Course Icon:</h5>
                        <p>Upload course icon in svg. Course icon appears in most training pages in two sizes: small thumbnail in the listing and larger size in content.</p>
                        <div class="form-group">
                            <input type="file" class="form-control-file" id="courseUpload"  name="course" value={this.state.course} onChange={this.onFile.bind(this)} />
                            <img src={this.state.image} width="100" height="100" alt=""/>
                        </div>
                    
                    <hr class="border-primary my-4"/>
                    
                    <h5 class="text-primary mb-3">Course Validity:</h5>
                    <div class="form-group">
                        <input type="text" class="form-control w-20 text-center"  name="validity" value={this.state.validity} onChange={this.hic.bind(this)}  id="validityPeriod" placeholder="Validity Period"/>
                        <small id="validityPeriodHelp" class="form-text text-muted">Enter (Months) validity period i.e. 12</small>
                    </div>
                    <div class="form-group">
                        <select id="warningPeriod"  name="warning" value={this.state.warning} onChange={this.hic.bind(this)}  class="form-control">
                            <option selected>Select Warning Period</option>
                            <option value="30">30 days before expiry</option>
                            <option value="60">60 days before expiry</option>
                            <option value="90">90 days before expiry</option>
                        </select>
                    </div>
                    
                    <hr class="border-primary my-4"/>
                    
{/*<!--
                    <h5 class="text-primary mb-3">Course Content:</h5>
                    <div class="form-group">
                        <select id="courseContent" class="form-control">
                            <option selected>Select content to display</option>
                            <option>...</option>
                        </select>
                    </div>
-->*/}                    
                    <hr class="border-primary my-4"/>
                    
                    <h5 class="text-primary mb-3">Test Quiz:</h5>
                    <div class="form-group">
                        <select id="selectQuiz"  name="quizId" value={this.state.quizId}  onChange={this.hic.bind(this)} class="form-control">
                            <option selected>Select quiz associated with this course</option>
                            {quizList}
                        </select>
                    </div>



                    <button type="submit" class="btn btn-primary mt-4" onClick={this.saveItem.bind(this)}>Add/Update Course</button>&nbsp;{deleteButton}&nbsp;<button class="btn btn-danger mt-4" onClick={() => {this.setState({showForm:false})}}>Close</button>
              
            </div>
        </div>
     </div>
 </div>
      

</div>


  )
  }
  else
  {
return(
<div>
  {spinner}
{helperFile.nav()}

    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-database mr-2" aria-hidden="true"></i>Courses</h2>

        <div class="data-container">
            <div class="row mb-2">
                <div class="col-8">
                    <p>Courses for all airlines are listed here.</p>
                </div>
                <div class="col-4">
                    <div class="card bg-secondary">
                        <div class="card-body text-center font-weight-bolder text-white">
                        Courses: {this.state.count}
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            

            <div class="container d-flex my-4 px-0 justify-content-between">
                <a class="btn btn-primary" role="button" onClick={this.addItem.bind(this)}><i class="fa fa-database mr-2" aria-hidden="true"></i>Add Course</a>
                <input class="form-control w-40" id="myInput" type="text" placeholder="Search.. by anything"/>
            </div>
            <div class="container d-flex my-4 px-0 justify-content-center"><div class="dropdown">
    <select id="inputState" class="form-control" onChange={this.filter.bind(this)}>
    <option selected>Choose Airline...</option>
    {filterItems}
    </select></div></div>


            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <table class="table mb-0 table-hover">
                      <thead>
                        <tr>
                        <th scope="col" >Provider Name</th>  
                          <th scope="col" >Course Name</th>  
                          <th scope="col" >Ref</th>
                          <th scope="col" >Assigned</th>
                          <th scope="col" >Class</th>
                          <th scope="col" >Validity</th>
                          <th scope="col" >Warning</th>
                          <th scope="col" >Quiz</th>
                          <th scope="col" >Action</th>
                        </tr>
                      </thead>
                      <tbody id="myTable">
                      {listItems}
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
      

    {pager}
 {helperFile.footer()}
 
</div>

)
  }}
}


export default ModalList1
