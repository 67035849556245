import React from "react";
import axios from "axios";

const config = require("../config");
const helper = require("../helper");
const misc = require("../comp_misc");
const helperFile = require("./admin_helper.js");
const Pager = require("../comp_misc").pager;

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/admin/media";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    <td>{props.item.providerName}</td>
    <td>{props.item.courseName}</td>
<td>{props.item.title}</td>
<td>{props.item.filename}</td>
<td><a href={`content?id=${props.item.id}`}>View</a></td>

  <td align="center"><button type="button" class="btn btn-outline-primary" onClick={() =>
    {
    props.parent.showModal(props.item) }
  }><i class="fa fa-edit mr-2" aria-hidden="true"></i>Edit</button></td>  

</tr>

}

class ModalList1 extends React.Component {
  

  constructor(props)
  {
    super(props);
    var params = helper.parseQuery();
    var page = params.p;
    if (!page)
      page = 0;

    this.state = {
      rows: null,
      error:null,
      image: null,
      page: page,
      courseId: null,
      progress: "0%",
      
    };
  }


checkInits()
{

}

fillList(filter)
{
  var rows1 = [];

  helper.getFromServer(g_link+"?dw=get&courseId="+this.state.courseId+"&providerId="+this.state.providerId)
  .then(res => {
    this.setState({showForm: false, rows: res.result, options: res.options, count: res.options.count});
  })



}
 componentDidMount() {
  this.fillList()  
  }

  showModal(item)
  {
      
//    this.setState({showForm: true, image: "/api/media/logo"+item.id+".png", id: item.id, name: item.name, email: helper.checkNull(item.email), error: ""});
    this.setState({showForm: true, id: item.id, ext: item.ext, courseId: item.courseId, title: item.title, image: "/api/media/media"+item.id+".mp4",alert: '', error: ""});
}

  hideModal()
  {
    this.setState({show: false});
  }

  saveItem()
  {
//    helper.postToServer(g_link, {dw: "update", id: this.state.id, email: this.state.email, name: this.state.name}, true)
    helper.postToServer(g_link, {dw: "update", id: this.state.id, courseId: this.state.courseId, title: this.state.title, }, true)
.then(res => {
      this.fillList();
    })
    .catch(err => {
      this.setState({error: err});
    })

  }
  addItem()
  {
    var item = {id: -1};
    this.showModal(item);
/*
    helper.postToServer(g_link, {dw: "add"}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })
*/
  }

  deleteItem()
  {

    helper.postToServer(g_link, {dw: "del", id: this.state.id}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }
  
  hiCheck1(event)
  {
    const target = event.target;
    const name = target.name;
    let value;
    if (target.checked)
      value = target.checked;
    else
      value = 0;

      this.setState({
        [name]: value
      });
  }

  
  filterCourse(event)
  {
    const target = event.target;
    var value = target.value;

    this.setState({courseId: value}, () =>
      this.fillList())

  }

  filterProvider(event)
  {
    const target = event.target;
    var value = target.value;

    this.setState({providerId: value}, () =>
      this.fillList())

  }

  hic(event) {

    const target = event.target;
    const name = target.name;

    let value;
//    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'checkbox')
    {
      value = this.state[name];
      if (!value)
        value = [];
        // assumes DOM won't feed us dupes
        var vint = parseInt(target.value);

      if (target.checked)
      {
        value.push(vint);
      }
      else
      {
        var index = value.indexOf(vint);
        if (index !== -1) value.splice(index, 1);
      }
    }
    else
    {
      value = target.value;
    }
    this.setState({
      [name]: value
    });
  
  }

  onFile() {
    if (this.state.courseId <= 0)
    {
      alert("Please select a course")
       return;
    }
    
  // OE??!! Fix this because it it confusing and only allows 1 _t per page
    var file = document.getElementById("videoUpload").files[0];
  
    var formData = new FormData();
    formData.append('file', file);
    formData.append('id', this.state.id)
    formData.append("dw", "saveFile")

// https://stackoverflow.com/questions/35288625/tracking-progress-of-file-upload-in-multer-nodejs
// https://www.w3schools.com/w3css/w3css_progressbar.asp
// https://getbootstrap.com/docs/4.0/components/progress/

    axios({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "POST",
      data: formData,
      url: config.back+g_link+"_up", // route name
//      baseURL: g_link, //local url
      onUploadProgress: progress => {
        const { total, loaded } = progress;
        const totalSizeInMB = total / 1000000;
        const loadedSizeInMB = loaded / 1000000;
        const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
//        setUploadPercentage(uploadPercentage.toFixed(2));
        this.setState({progress: uploadPercentage+"%"});
        console.log("total size in MB ==> ", totalSizeInMB);
        console.log("uploaded size in MB ==> ", loadedSizeInMB);
      },
      encType: "multipart/form-data",
    });    
/*    
    fetch(config.back+g_link+"_up", {
      method: 'POST', credentials: "same-origin",
      body: formData
    }).then(response => response.json())
    .then(res => {
      
      this.setState({image: "/api/media/video"+this.state.id+".mp4?"+new Date().getMilliseconds(),
    alert: "File was uploaded"});
    })
    .catch(error => { 
      alert(error);
    });
   //.then(response => console.log('Success:', response));
  */

  }
    

  render() {
document.title = "Media";

let spinner;
let pager;
if (!this.state.rows)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>;
  return spinner;
}
else
{
  pager = <Pager page={this.state.page} count={this.state.options.count} link={g_link}/>;
}

var error1 = <div class="alert alert-danger" role="alert">
<i class="fa fa-times mr-2" aria-hidden="true"></i>{this.state.error}
</div>;
if (!this.state.error)
error1 = <div></div>;
var alert1 = <div class="alert alert-success" role="alert">
<i class="fa fa-check mr-2" aria-hidden="true"></i>{this.state.alert}
</div>;

if (!this.state.alert)
    alert1 = <div></div>

let listItems;
var filterItems = [];

if (this.state.rows)
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

  if (this.state.options.filters)
  {
    this.state.options.filters.map((item) => {
      filterItems.push(<option value={item.id}>{item.name}</option>)
    });
  }

}
if (this.state.showForm)
{
  this.checkInits();
  var courseList = this.state.options.courseList.map((item, index) =>
                <option value={item.id}>{item.name}</option>);

  
  
  let frame;
  if (this.state.ext == "mp4")
  {
    frame = <iframe class="embed-responsive-item" src={`/api/media/media_${this.state.id}.mp4`} type="video/mp4"></iframe>
  }
  else if (this.state.ext == "pdf")
  {
    frame = <iframe class="embed-responsive-item" src={`/api/media/media_${this.state.id}.pdf`}></iframe>
  }
  let deleteButton;

  if (this.state.id != -1)
  {
    deleteButton = <button type="submit" class="btn btn-danger mt-4" onClick={this.deleteItem.bind(this)}>Delete</button>
  }

  return(
<div>{helperFile.nav()}

    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-video mr-2" aria-hidden="true"></i>Media Form</h2>

        {error1}       
        {alert1}        
{/*<!-- user info block start -->*/}
        <div class="data-container">
            <div class="row mb-2">
                <div class="col-12">
                    <h4>Add/Edit Media</h4>
                </div>
            </div>
            <hr class="border-primary mb-4"/>
            
{/*<!-- form start -->*/}
        <div class="row">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                <p>Web Based Training Media must be assigned to a particualr course, each course contains at least one wbt media for user display. Media must be uploaded in mp4 format for best quality and play.</p>
                
                    <div class="form-group">
                        <select id="selectCourseVideo" class="form-control"  name="courseId" value={this.state.courseId}  onChange={this.hic.bind(this)}>
                            <option selected>Select course to assign this Media to...</option>
                            {courseList}
                        </select>
                    </div>
                    
                    <hr class="border-primary my-4"/>
                    
                    <h5 class="text-primary mb-3">Web Based Training (mp4 or pdf):</h5>

                    <div class="form-group">
                        <input type="text" class="form-control" id="videoTitle" placeholder="Video Title"  name="title" value={this.state.title} onChange={this.hic.bind(this)} />
                        <small id="videoTitleHelp" class="form-text text-muted">Enter the video title to display for user and in other locations</small>
                    </div>
                    <h6>Media Upload:</h6>
                    <ul class="mb-5">
                        <li>The video must be in mp4 format with specifed dimenstions.</li>
                        <li>To replace already uploaded video, choose and upload the new video and save this form.</li>
                        <li>If a video is deleted and no file exists, it will not show for the user.</li>
                    </ul>
                        <div class="form-group">
                            <input type="file" class="form-control-file" id="videoUpload"  name="video" value={this.state.video} onChange={this.onFile.bind(this)} />

                            <div class="progress">
                              <div class="progress-bar" role="progressbar" style={{width: this.state.progress}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>

                            <a href={`content?id=${this.state.id}`}>{this.state.ext}</a><br/>
    {frame}
                            
                        </div>                    

                    <button type="submit" class="btn btn-primary mt-4" onClick={this.saveItem.bind(this)}>Add/Update Media</button>&nbsp;{deleteButton}&nbsp;<button class="btn btn-danger mt-4" onClick={() => {this.setState({showForm:false})}}>Close</button>
              
            </div>
        </div>
     </div>
 </div>
      

</div>


  )
  }
  else
  {
return(
<div>
{helperFile.nav()}

    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-database mr-2" aria-hidden="true"></i>Media</h2>

        <div class="data-container">
            <div class="row mb-2">
                <div class="col-8">
                    <p>Web Based Training meedia used for our courses are listed here, videos are in mp4 format and displayed for the user in an object responsive container.</p>
                </div>
                <div class="col-4">
                    <div class="card bg-secondary">
                        <div class="card-body text-center font-weight-bolder text-white">
                        Media: {this.state.count}
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            

            <div class="container d-flex my-4 px-0 justify-content-between">
                <a class="btn btn-primary" role="button" onClick={this.addItem.bind(this)}><i class="fa fa-database mr-2" aria-hidden="true"></i>Add Media</a>
                Course: <misc.FilterBox name="courseId" parent={this} onChange={this.filterCourse.bind(this)} value={this.state.courseId} options={this.state.options.courseList}/>
                Provider: <misc.FilterBox name="providerId" onChange={this.filterProvider.bind(this)} value={this.state.providerId} options={this.state.options.providerList}/>
                <input class="form-control w-40" id="myInput" type="text" placeholder="Search.. by anything"/>
            </div>
            

            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <table class="table mb-0 table-hover">
                      <thead>
                        <tr>
                        <th scope="col" >Provider</th>  
                          <th scope="col" >Course</th>  
                          <th scope="col" >Title</th>
                          <th scope="col" >File</th>
                          <th scope="col" class="text-center" width="120">Action</th>
                        </tr>
                      </thead>
                      <tbody id="myTable">
                      {listItems}
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
      

    {pager}
 {helperFile.footer()}
 
</div>

)
  }}
}


export default ModalList1
