import React from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const helperFile = require("./admin_helper.js");
const Pager = require("../comp_misc").pager;

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/admin/guidelines";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    <td>{props.item.course}</td>
<td>{props.item.accordance}</td>
<td>{props.item.suitable}</td>
<td>undefined</td>

  <td align="center"><button type="button" class="btn btn-outline-primary" onClick={() =>
    {
    props.parent.showModal(props.item) }
  }><i class="fa fa-edit mr-2" aria-hidden="true"></i>Edit</button></td>  

</tr>

}

class ModalList1 extends React.Component {
  

  constructor(props)
  {
    super(props);
    var params = helper.parseQuery();
    var page = params.p;
    if (!page)
      page = 0;

    this.state = {
      rows: null,
      error:null,
      image: null,
      page: page,
      
    };
  }


checkInits()
{

}

fillList(filter)
{
  var rows1 = [];

  helper.getFromServer(g_link+"?dw=get&filter="+filter)
  .then(res => {

    this.setState({showForm: false, rows: res.result, options: res.options, count: res.options.count});
  })



}
 componentDidMount() {
  this.fillList()  
  }

  showModal(item)
  {
      
//    this.setState({showForm: true, image: "/api/media/logo"+item.id+".png", id: item.id, name: item.name, email: helper.checkNull(item.email), error: ""});
    this.setState({showForm: true, id: item.id, accordance: item.accordance, suitable: item.suitable, stepsIntro: item.stepsIntro, step1: item.step1, alert: '', error: ""});
}

  hideModal()
  {
    this.setState({show: false});
  }

  saveItem()
  {
//    helper.postToServer(g_link, {dw: "update", id: this.state.id, email: this.state.email, name: this.state.name}, true)
    helper.postToServer(g_link, {dw: "update", id: this.state.id, accordance: this.state.accordance, suitable: this.state.suitable, stepsIntro: this.state.stepsIntro, step1: this.state.step1, }, true)
.then(res => {
      this.fillList();
    })
    .catch(err => {
      this.setState({error: err});
    })

  }
  addItem()
  {
    var item = {id: -1};
    this.showModal(item);
/*
    helper.postToServer(g_link, {dw: "add"}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })
*/
  }

  deleteItem()
  {

    helper.postToServer(g_link, {dw: "del", id: this.state.id}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }
  
  hiCheck1(event)
  {
    const target = event.target;
    const name = target.name;
    let value;
    if (target.checked)
      value = target.checked;
    else
      value = 0;

      this.setState({
        [name]: value
      });
  }

  
  filter(event)
  {
    const target = event.target;
//    const name = target.name;
    var value = target.value;

    this.fillList(value);

  }

  hic(event) {

    const target = event.target;
    const name = target.name;

    let value;
//    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'checkbox')
    {
      value = this.state[name];
      if (!value)
        value = [];
        // assumes DOM won't feed us dupes
        var vint = parseInt(target.value);

      if (target.checked)
      {
        value.push(vint);
      }
      else
      {
        var index = value.indexOf(vint);
        if (index !== -1) value.splice(index, 1);
      }
    }
    else
    {
      value = target.value;
    }
    this.setState({
      [name]: value
    });
  
  }

  onFile() {

  // OE??!! Fix this because it it confusing and only allows 1 _t per page
    var file = document.getElementById("undefined").files[0];
  
    var formData = new FormData();
    formData.append('file', file);
    formData.append('id', this.state.id)
    formData.append("dw", "saveFile")
    
    fetch(config.back+g_link+"_up", {
      method: 'POST', credentials: "same-origin",
      body: formData
    }).then(response => response.json())
    .then(res => {
      
      this.setState({image: "/api/media/undefined"+this.state.id+".png?"+new Date().getMilliseconds(),
    alert: "File was uploaded"});
    })
    .catch(error => { 
      alert(error);
    });
   //.then(response => console.log('Success:', response));
    
  }
    

  render() {
document.title = "Guidelines";

let spinner;
let pager;
if (!this.state.rows)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>;
}
else
{
  pager = <Pager page={this.state.page} count={this.state.options.count} link={g_link}/>;
}

var error1 = <div class="alert alert-danger" role="alert">
<i class="fa fa-times mr-2" aria-hidden="true"></i>{this.state.error}
</div>;
if (!this.state.error)
error1 = <div></div>;
var alert1 = <div class="alert alert-success" role="alert">
<i class="fa fa-check mr-2" aria-hidden="true"></i>{this.state.alert}
</div>;

if (!this.state.alert)
    alert1 = <div></div>

let listItems;
var filterItems = [];

if (this.state.rows)
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

  if (this.state.options.filters)
  {
    this.state.options.filters.map((item) => {
      filterItems.push(<option value={item.id}>{item.name}</option>)
    });
  }

}
if (this.state.showForm)
{
  this.checkInits();
  
  
  

  let deleteButton;

  if (this.state.id != -1)
  {
    deleteButton = <button type="submit" class="btn btn-danger mt-4" onClick={this.deleteItem.bind(this)}>Delete</button>
  }

  return(
<div>{helperFile.nav()}

    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-graduation-cap mr-2" aria-hidden="true"></i>Guidelines Form</h2>

{/*<!-- user info block start -->*/}
        <div class="data-container">
            <div class="row mb-2">
                <div class="col-12">
                    <h4>Add/Edit Guidelines</h4>
                </div>
            </div>
            <hr class="border-primary mb-4"/>
            
{/*<!-- form start -->*/}
        <div class="row">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                <p>Guidelines are displayed in the user's homepage. Each course may have different guidelines and procedure to follow. Here we may assign guidelines in accordance with course requirements to show the steps needed to complete a successful training.</p>
                
                    <div class="form-group">
                        <select id="selectCourseGuidelines" class="form-control">
                            <option selected>Select course to assign Guidelines...</option>
                            <option>!!course_list!!</option>
                        </select>
                    </div>
                    
                    <hr class="border-primary my-4"/>
                    
                    <h5 class="text-primary mb-3">Guidelines:</h5>

                  <div class="form-group">
                        <input type="text" class="form-control"  name="accordance" value={this.state.accordance} onChange={this.hic.bind(this)}  placeholder="In Accordance with"/>
                        <small id="inAccordanceHelp" class="form-text text-muted">Enter the compliance for this course i.e. This training is in accordance with EASA/OPS/CAT</small>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control"  name="suitable" value={this.state.suitable} onChange={this.hic.bind(this)}  placeholder="Initial/Conversion/Recurrent Training"/>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control"  name="stepsIntro" value={this.state.stepsIntro} onChange={this.hic.bind(this)}  id="stepsIntro" placeholder="Steps Intro"/>
                    </div>
                    <div class="form-group">
                        <textarea class="form-control"  name="step1" value={this.state.step1} onChange={this.hic.bind(this)}  id="step-1" rows="3" placeholder="Step-1"></textarea>
                    </div>
                    <div class="form-group">
                        <textarea class="form-control" name="step-2" id="step-2" rows="3" placeholder="Step-2"></textarea>
                    </div>
                    <div class="form-group">
                        <textarea class="form-control" name="step-3" id="step-3" rows="3" placeholder="Step-3"></textarea>
                    </div>                              

                    <button type="submit" class="btn btn-primary mt-4" onClick={this.saveItem.bind(this)}>Add/Update Guideline</button>&nbsp;{deleteButton}&nbsp;<button class="btn btn-danger mt-4" onClick={() => {this.setState({showForm:false})}}>Close</button>
              
            </div>
        </div>
     </div>
 </div>
      

</div>


  )
  }
  else
  {
return(
<div>
  {spinner}
{helperFile.nav()}

    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-database mr-2" aria-hidden="true"></i>Guidelines</h2>

        <div class="data-container">
            <div class="row mb-2">
                <div class="col-8">
                    <p>Guidelines are displayed for each course, table below shows assigned guidelines to relevant courses.</p>
                </div>
                <div class="col-4">
                    <div class="card bg-secondary">
                        <div class="card-body text-center font-weight-bolder text-white">
                        Guidelines: {this.state.count}
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            

            <div class="container d-flex my-4 px-0 justify-content-between">
                <a class="btn btn-primary" role="button" onClick={this.addItem.bind(this)}><i class="fa fa-database mr-2" aria-hidden="true"></i>Add Guideline</a>
                <input class="form-control w-40" id="myInput" type="text" placeholder="Search.. by anything"/>
            </div>
            

            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <table class="table mb-0 table-hover">
                      <thead>
                        <tr>
                          <th scope="col">Course</th>  
                          <th scope="col" >In Accordance</th>
                          <th scope="col" >Suitable For</th>
                          <th scope="col" class="text-center" width="120">Action</th>
                        </tr>
                      </thead>
                      <tbody id="myTable">
                      {listItems}
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
      

    {pager}
 {helperFile.footer()}
 
</div>

)
  }}
}


export default ModalList1
