import React from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const helperFile = require("./user_helper.js");

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/user/wbt";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    
</tr>

}

class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    var id = helper.parseQuery()['id'];

    this.state = {
      id: id,
    };
  }




fillList()
{
    helper.getFromServer(g_link+"?dw=getOne&id="+this.state.id)
  .then(res => {

    this.setState({showForm: false, props: res.result.vals, rows: res.result.rows});
  })



}
 componentDidMount() {
  this.fillList()  
  }

    

  render() {
document.title = "WBT";

let listItems;
let spinner;
if (!this.state.props)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>;

}
else 
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

}
if (!this.state.props)
{
  return(<div>
  {spinner}    
  </div>)
}
return(
<div>
{helperFile.nav()}


    <div class="container my-5">
        
{/*<!-- course title (logo and name) -->*/}
        <div class="container d-flex mb-5 px-0 align-content-center">
            <div class="mr-2">
                <img src="assets/icon-rvsm.svg" width="100" height="100" alt=""/>
            </div>
            <div class="ml-4">
                <div class="course-title">RVSM</div>
                <p class="lead font-weight-bolder">Reduced Vertical Separation Minimum</p>
            </div>
        </div>
        
        
{/*<!-- course item object embed start for video viewer -->*/}
        <div class="container mx-0 px-0">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe class="embed-responsive-item" src={"/api/media/video"+this.state.id+".mp4"} type="video/mp4"></iframe>
            </div>
        </div>
    </div>


 {helperFile.footer()}
 
</div>

)
}
}


export default ModalList1
