import React from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const helperFile = require("./user_helper.js");

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/user/test4";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    
</tr>

}

class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    var id = helper.parseQuery()['id'];

    this.state = {
      id: id,
    };
  }




fillList()
{
    helper.getFromServer(g_link+"?dw=getOne&id="+this.state.id)
  .then(res => {

    this.setState({showForm: false, props: res.result.vals, rows: res.result.rows});
  })



}
 componentDidMount() {
  this.fillList()  
  }

    

  render() {
document.title = "Test";

let listItems;
let spinner;
if (!this.state.props)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>;

}
else 
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

}
if (!this.state.props)
{
  return(<div>
  {spinner}    
  </div>)
}
return(
<div>
{helperFile.nav()}


    <div class="container my-5">
        
{/*<!-- course title (logo and name) -->*/}
        <div class="container d-flex mb-3 px-0 align-content-center">
            <div class="mr-2">
                <img src="assets/icon-rvsm.svg" width="100" height="100" alt=""/>
            </div>
            <div class="ml-4">
                <div class="course-title">RVSM</div>
                <p class="lead font-weight-bolder">Reduced Vertical Separation Minimum</p>
            </div>
        </div>
        
        <div class="data-container mb-4">
            <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 border-right">
                <h4 class="text-primary">Test Result</h4>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
            <table class="table">
                    <col width="25%"/>
                    <tr>
                        <td class="font-weight-bold border-top-0">Test Result For:</td>
                        <td class="text-lg-org border-top-0">{localStorage.getItem("name")}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Result:</td>
                        <td class="text-xl-grn">{this.state.props.result}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Feedback:</td>
                        <td>{this.state.props.feedback}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold border-dark">Quiz Name:</td>
                        <td class="text-lg-blk border-dark">RVSM</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Started on:</td>
                        <td>{this.state.props.startTime}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Completed on:</td>
                        <td>{this.state.props.finishTime}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Time Taken:</td>
                        <td>{this.state.props.totalTime}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Total Questions:</td>
                        <td>{this.state.props.totalQuestions}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Answered Questions:</td>
                        <td>{this.state.props.answeredQuestions}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Missed:</td>
                        <td>{this.state.props.missed}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Correct Answers:</td>
                        <td>{this.state.props.correctAnswers}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Incorrect Answers:</td>
                        <td>{this.state.props.incorrectAnswers}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Score:</td>
                        <td>{this.state.props.score}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Grade:</td>
                        <td>{this.state.props.score}%</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Note:</td>
                        <td>If you result is "Pass" a certificate is issued, you may click the button below to view or print your certificate. For "Fail" grade no certificate is issued and we suggest that you arrange to take the test again in due course.</td>
                    </tr>
            </table>
                <div class="mt-5">
                    <a href={`view_cert?id=${this.state.props.attemptId}`}><button type="button" class="btn btn-lg btn-success mr-2"><i class="fa fa-certificate mr-2" aria-hidden="true"></i>View Certificate</button></a>
                    <a href="attempts"><button type="button" class="btn btn-lg btn-danger">View Attempts<i class="fa fa-arrow-alt-circle-right ml-2" aria-hidden="true"></i></button></a>
                </div>
                </div>
            </div>
        </div>
    </div>


 {helperFile.footer()}
 
</div>

)
}
}


export default ModalList1
