import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

const helper = require("./helper");
const config = require("./config");

function ModMenu(props)
{
  return(
  <div>
    <a href="/admin">Admin</a> <a href="/admin/goto?cid=1">Airline</a> <a href="/user">Users</a><br/>
    <br/>
  </div>)
}

function renderAdmin(Component) {
  return(
    <div>
<div class="container-fluid d-flex justify-content-end px-0">
<div class="profile-card-admin">
    <div class="profile-item"><i class="fa fa-user mr-1" aria-hidden="true"></i>Hello: {localStorage.getItem("name")}</div>
    <div class="profile-item"><i class="fa fa-address-card mr-1" aria-hidden="true"></i>Role: Admin</div>
    <div class="profile-item"><a href="/logout"><i class="fa fa-sign-out mr-1" aria-hidden="true"></i>Logout</a></div>
</div>
</div>
<Component />
</div>
  )
}

function renderAirline(Component) {
  var logo = "/api/media/"+localStorage.getItem("logo");
return <div><div class="header-container">
<img src={logo} width="215" height="50" alt="airline"/>

<div class="profile-card">
<div class="profile-item"><i class="fa fa-user mr-1" aria-hidden="true"></i>Hello: {localStorage.getItem("name")}</div>
<div class="profile-item"><i class="fa fa-address-card mr-1" aria-hidden="true"></i>Role: Airline</div>
<div class="profile-item"><a href="/logout"><i class="fa fa-sign-out mr-1" aria-hidden="true"></i>Logout</a></div>
</div>
</div>
<Component /></div>

}

function renderTrainee(Component) {
  var logo = "/api/media/"+localStorage.getItem("logo");
return <div><div class="header-container">
<img src={logo} width="215" height="50" alt="airline"/>

<div class="profile-card">
<div class="profile-item"><i class="fa fa-user mr-1" aria-hidden="true"></i>Hello: {localStorage.getItem("name")}</div>
<div class="profile-item"><i class="fa fa-address-card mr-1" aria-hidden="true"></i>Role: Trainee</div>
<div class="profile-item"><a href="/logout"><i class="fa fa-sign-out mr-1" aria-hidden="true"></i>Logout</a></div>
</div>
</div>
<Component /></div>

}

/*
function render1(Component) {
    var logo = <img src="assets/airline-logo.png" width="215" height="50" alt="airline"/>;
  return <div><ModMenu/>
        <div class="container-fluid d-flex justify-content-end px-0">
        <div class="profile-card-admin">
            <div class="profile-item"><i class="fa fa-user mr-1" aria-hidden="true"></i>Hello: {localStorage.getItem("name")}</div>
            <div class="profile-item"><i class="fa fa-address-card mr-1" aria-hidden="true"></i>Role: Admin</div>
            <div class="profile-item"><a href="/logout"><i class="fa fa-sign-out mr-1" aria-hidden="true"></i>Logout</a></div>
        </div>
      </div>

  <Component /></div>
}
*/

class Logout extends React.Component
{

  constructor(props)
  {
    super(props);
    this.state = {
      error:null,
      showError:"",
      show:false,
    };
  }

  componentDidMount()
  {
    helper.postToServer("/logout", {}, true)
    .then(res => {
//      console.log(res);
      localStorage.setItem("name", null);
      localStorage.setItem("logo", null);
      this.setState({show:true});

   
    })
    .catch(err => {
      this.setState({showError:"show", error: "Error loging out"});

    })
  }

render()
{
  if (!this.state.show)
    return(<div>Wait</div>)
    else {
    return(
        <div>
        <div className="d-flex flex-column align-items-center mt-5">
        <a href="https://www.recurrentpro.com"><img src="rpro-logo-fs.png" alt="Rucrrentpro-logo"/></a>
        <h5 class="text-dark font-weight-light text-center mt-1">Crew Recurrent Training On The Cloud</h5>
    </div>
        <div class="login-box">
            <h2 class="text-center text-warning mb-4"><a href="/login">Login</a></h2>
            
        <div className={"alert alert-danger alert-dismissible fade"+this.state.showError} role="alert">
          {this.state.error}
{/*          <button type="button" class="close" data-dismiss="alert" aria-label="Close"> 
            <span aria-hidden="true">&times;</span>
          </button>*/}
        </div>
        You have been logged out.
      </div>
        <div class="login-footer">
            <p>RecurrentPro Copyright &copy;<br/>
            For support contact: support@recurrentpro.com</p>
        </div>
        </div>    )

}    }
}



function gotoCompany()
{
  var p = helper.parseQuery(document.location.search);
  var cid = p["cid"];
  helper.postToServer("/admin/gotoCompany", {cid: cid})
  .then((res => {
    document.location = "/airline";
  }))
  .catch(err => {
    return <div>Error!</div>
  })

}


function Home()
{
  var secure = "";
  if (window.location.origin.startsWith("http:"))
  {
      secure = <div><a href='https://localhost:3010/'>Secure</a><br/><br/></div>

  }
    return (
      <div class="reg-form-container">
      <div class="reg-form-left">
      <br/>
            <a href="/login">Login</a><br/><br/>
          
      </div>
      <div class="reg-form-right">
            {secure}
        </div>
        </div>
    );

}
/*
function renderAdmin(Component) {
  return <AdminLayout><Component /></AdminLayout>
}
*/

/*
function raHome()
{
      return(render1(require("./admin/list_airlines").default) )


}
*/
/*
function rcHome()
{
  
  if (document.location.toString().endsWith("/airline"))
{
    return render1(require("./airline/list_profile").default)
 }

}
*/

/*
function ruHome()
{
  
  if (document.location.toString().endsWith("/users"))
{
    return render1(require("./users/list_dashboard").default)
 }
 else
 {
 return (
   <div></div>)
 }
}
*/

function route1() {
    return (
      <Router>
  
        <Route exact path="/" component={Home} />

        <Route exact path="/admin" render={() => renderAdmin(require("./admin/list_airlines").default)}  /> 
        <Route path="/admin/users" component={() => renderAdmin(require("./admin/list_users").default)} />
        <Route path="/admin/courses" component={() => renderAdmin(require("./admin/list_courses").default)} />
        <Route path="/admin/dashboard" component={() =>  renderAdmin(require("./admin/list_airlines").default)} />
        <Route path="/admin/groups" render={() => renderAdmin(require("./admin/list_groups").default)}  />
        <Route path="/admin/airlines" render={() => renderAdmin(require("./admin/list_airlines").default)}  />
        <Route path="/admin/requirements" component={() => renderAdmin(require("./admin/list_requirements").default)}  />
        <Route path="/admin/aircraft" component={() => renderAdmin(require("./admin/list_aircraft").default)}  />
        <Route path="/admin/certificates" component={() => renderAdmin(require("./admin/list_certificates").default)}  />
        <Route path="/admin/classes" component={() => renderAdmin(require("./admin/list_classes").default)}  />
        <Route path="/admin/quizzes" component={() => renderAdmin(require("./admin/list_quizzes").default)}  />
        <Route path="/admin/questions" component={() => renderAdmin(require("./admin/list_questions").default)}  />
        <Route path="/admin/ranks" component={() => renderAdmin(require("./admin/list_ranks").default)}  />
        <Route path="/admin/trainees" component={() => renderAdmin(require("./admin/list_trainees").default)}  />
        <Route path="/admin/subjects" component={() => renderAdmin(require("./admin/list_subjects").default)}  />
        <Route path="/admin/manuals" component={() => renderAdmin(require("./admin/list_manuals").default)}  />
        <Route path="/admin/videos" component={() => renderAdmin(require("./admin/list_videos").default)}  />
        <Route path="/admin/media" component={() => renderAdmin(require("./admin/list_media").default)}  />
        <Route path="/admin/guidelines" component={() => renderAdmin(require("./admin/list_guidelines").default)}  />
        <Route path="/admin/logins" component={() => renderAdmin(require("./admin/list_logins").default)}  />
        <Route path="/admin/invoices" component={() => renderAdmin(require("./admin/list_invoices").default)}  />
        <Route path="/admin/wikis" component={() => renderAdmin(require("./admin/form_wikis").default)}  />
        <Route path="/admin/wedit" component={() => renderAdmin(require("./admin/form_wedit").default)}  />
        <Route path="/admin/goto" component={() => gotoCompany()}  />
        <Route path="/admin/processes" component={() => renderAdmin(require("./admin/list_processes").default)}  />
        <Route path="/admin/subscriptions" component={() => renderAdmin(require("./admin/list_subscriptions").default)}  />
        <Route path="/admin/content" component={() => renderAdmin(require("./admin/content").default)}  />

        <Route exact path="/airline" render={() => renderAirline(require("./airline/dashboard").default)} />
        <Route path="/airline/profile" render={() => renderAirline(require("./airline/list_profile").default)} />
        <Route path="/airline/dashboard" render={() => renderAirline(require("./airline/dashboard").default)} />
        <Route path="/airline/crew" render={() => renderAirline(require("./airline/list_crew").default)} />
        <Route path="/airline/crew_record" render={() => renderAirline(require("./airline/record_crew").default)} />        
        <Route path="/airline/attempts" render={() => renderAirline(require("./airline/list_attempts").default)}  />
        <Route path="/airline/certificates" render={() => renderAirline(require("./airline/list_certificates").default)}  />
        <Route path="/airline/view_cert" render={() => renderAirline(require("./airline/view_cert").default)}  />
        <Route path="/airline/notifications" render={() => renderAirline(require("./airline/list_notifications").default)}  />
        <Route path="/airline/users" render={() => renderAirline(require("./airline/list_users").default)}  />
        <Route path="/airline/help" render={() => renderAirline(require("./airline/list_help").default)}  />
        <Route path="/airline/reports" render={() => renderAirline(require("./airline/list_reports").default)}  />
        <Route path="/airline/courses" render={() => renderAirline(require("./airline/list_courses").default)}  />
        <Route path="/airline/groups" render={() => renderAirline(require("./airline/list_groups").default)}  />
        <Route path="/airline/quizzes" render={() => renderAirline(require("./airline/list_quizzes").default)}  />

        <Route exact path="/user" render={() => renderTrainee(require("./user/dashboard").default)}  /> 
        <Route path="/user/dashboard" render={() => renderTrainee(require("./user/dashboard").default)}  />
        <Route path="/user/course_content" render={() => renderTrainee(require("./user/list_content").default)}  />
        <Route path="/user/courses" render={() => renderTrainee(require("./user/list_courses").default)}  />
        <Route path="/user/content" render={() => renderTrainee(require("./user/content").default)}  />
        <Route path="/user/wbt" render={() => renderTrainee(require("./user/wbt").default)}  />
        <Route path="/user/attempts" render={() => renderTrainee(require("./user/list_attempts").default)}  /> 
        <Route path="/user/certificates" render={() => renderTrainee(require("./user/list_certificates").default)}  />
        <Route path="/user/view_cert" render={() => renderTrainee(require("./user/view_cert").default)}  />
        <Route path="/user/view_quiz" render={() => renderTrainee(require("./user/view_quiz").default)}  />
        <Route path="/user/notifications" render={() => renderTrainee(require("./user/list_notifications").default)}  />
        <Route path="/user/help" render={() => renderTrainee(require("./user/list_help").default)}  /> 
        <Route path="/user/test1" render={() => renderTrainee(require("./user/page_test1").default)}  />
        <Route path="/user/test2" render={() => renderTrainee(require("./user/page_test2").default)}  />
        <Route path="/user/test3" render={() => renderTrainee(require("./user/page_test3").default)}  />
        <Route path="/user/test4" render={() => renderTrainee(require("./user/page_test4").default)}  />

        <Route path="/home" component={Home} />
        <Route path="/rpass1" component={require("./reset_pass").default} />
        <Route path="/rpass2" component={require("./reset_pass2").default} />
        <Route path="/login" component={require("./login.js").default} />
        <Route path="/logout" component={Logout} />
        <Route path="/trial" component={require("./trial").default} />
        <Route path="/signup" component={require("./signup").default} />
        <Route path="/pricing" component={require("./pricing").default} />

      </Router>
    );
  }


ReactDOM.render(route1(), document.getElementById('root'));

//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
