import React from "react";


function AirlineNav()
{
  return (
    <div class="container-fluid bg-warning">
    <div class="container px-0">
        <nav class="navbar navbar-expand-lg navbar-light bg-warning">
          <a class="navbar-brand" href="#"><img src="/rpro-brand-logo.svg" width="58" height="50" alt="RecurrentPro"/></a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item">
                <a class="nav-link" href="/airline/dashboard"><i class="fa fa-dashboard mr-1" aria-hidden="true"></i>Dashboard</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/airline/crew"><i class="fa fa-id-card mr-1" aria-hidden="true"></i>Trainee List</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/airline/attempts"><i class="fa fa-file mr-1" aria-hidden="true"></i>Attempts</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/airline/certificates"><i class="fa fa-certificate mr-1" aria-hidden="true"></i>Certificates</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/airline/notifications"><i class="fa fa-envelope-open mr-1" aria-hidden="true"></i>Notification</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/airline/users"><i class="fa fa-users mr-1" aria-hidden="true"></i>Users</a>
              </li>
              <li class="nav-item dropdown"><a class="nav-link dropdown-toggle" href="#" id="airlineViewLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fa fa-desktop mr-1" aria-hidden="true"></i>View</a>
                <div class="dropdown-menu" aria-labelledby="airlineViewLink">
{/*                  
                    <a class="dropdown-item" href="/airline/reports"><i class="fa fa-book mr-1" aria-hidden="true"></i>Reports</a>
                    <div class="dropdown-divider"></div>
*/}                    
                    <a class="dropdown-item" href="/airline/groups"><i class="fa fa-sitemap mr-1" aria-hidden="true"></i>Groups</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="/airline/courses"><i class="fa fa-paste mr-1" aria-hidden="true"></i>Courses</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="/airline/quizzes"><i class="fa fa-list mr-1" aria-hidden="true"></i>Quiz</a>
                </div>
            </li>
              <li class="nav-item">
                <a class="nav-link" href="/airline/help"><i class="fa fa-info-circle mr-1" aria-hidden="true"></i>Help</a>
              </li>
            </ul>
          </div>
        </nav>
    </div>
</div>

  )

}


export const nav = AirlineNav;

function Footer()
{
  return (
<div className="footer">
        <div className="mr-3"><a href="#"><img src="/rpro-hdr-logo.svg" width="200" height="45" alt="RecurrentPro"/></a></div>
        <div className="mt-2">All rights reserved, material Copyright &copy; – For support contact: support@recurrentpro.com</div>
    </div>)
}

export const footer = Footer;