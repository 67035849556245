const React = require("react")
const Component = React.Component
const helper = require("./helper");

class Login extends Component
{

  constructor(props)
  {
    super(props);
    this.state = {
      password:null,
      email:null,
      error:null,
      showError:""
    };
  }

  doLogin()
  {
    helper.postToServer("/login", {email: this.state.email, password: this.state.password}, true)
    .then(res => {
//      console.log(res);
//      this.setState({showError:"show", error: ""});
      localStorage.setItem("name", res.name);
      localStorage.setItem("airlineId", res.airlineId);
      localStorage.setItem("logo", res.logo);

      if (res.roles.indexOf("admin") != -1)
        document.location = "/admin";
      else if (res.roles.indexOf("company") != -1)
        document.location = "/airline";
      else if (res.roles.indexOf("user") != -1)
        document.location = "/user";
    
    })
    .catch(err => {
      this.setState({showError:"show", error: "Error loging in"});

    })
  }

  handleInputChange(event) {
    const target = event.target;
    if (target === undefined)
    {
      this.setState({
        date: event
          });
      return
    }
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });

  }

render()
{
    return(
        <div>
        <div className="d-flex flex-column align-items-center mt-5">
        <a href="https://www.recurrentpro.com"><img src="rpro-logo-fs.png" alt="Rucrrentpro-logo"/></a>
        <h5 class="text-dark font-weight-light text-center mt-1">Crew Recurrent Training On The Cloud</h5>
    </div>
        <div class="login-box">
            <h2 class="text-center text-warning mb-4">Login</h2>
            
        <div className={"alert alert-danger alert-dismissible fade"+this.state.showError} role="alert">
          {this.state.error}
{/*          <button type="button" class="close" data-dismiss="alert" aria-label="Close"> 
            <span aria-hidden="true">&times;</span>
          </button>*/}
        </div>
            <div class="form-group">
              <label for="emailAddress"><i class="fa fa-envelope text-warning" aria-hidden="true"></i> Email address</label>
              <input type="email"  onChange={this.handleInputChange.bind(this)} name="email"  class="form-control" id="emailAddress" placeholder="Enter email"/>
              <small id="emailHelp" class="form-text text-muted">Enter your assigned email address</small>
            </div>
            <div class="form-group">
              <label for="password"><i class="fa fa-lock text-warning" aria-hidden="true"></i> Password</label>
              <input type="password" onChange={this.handleInputChange.bind(this)} name="password" value={this.state.password} class="form-control" id="loginPassword" placeholder="Password"/>
            </div>
            <button class="btn btn-warning btn-lg login-btn" id="login_button" onClick={this.doLogin.bind(this)}>Login</button>
          <div class="mt-4 text-right">
              <a href="/rpass1">Forgot Password: click to reset</a>
          </div>
      </div>
        <div class="login-footer">
            <p>RecurrentPro Copyright &copy;<br/>
            For support contact: support@recurrentpro.com</p>
        </div>
        </div>    )

}    
}

export default Login;



