import React from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const helperFile = require("./user_helper.js");

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/user/test1";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    
</tr>

}

class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    var id = helper.parseQuery()['id'];

    this.state = {
      id: id,
    };
  }




fillList()
{
    helper.getFromServer(g_link+"?dw=getOne&id="+this.state.id)
  .then(res => {

    this.setState({showForm: false, props: res.result.vals, rows: res.result.rows});
  })



}
 componentDidMount() {
  this.fillList()  
  }

    

  render() {
document.title = "Test";

let listItems;
let spinner;
if (!this.state.props)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>;

}
else 
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

}
if (!this.state.props)
{
  return(<div>
  {spinner}    
  </div>)
}
return(
<div>
{helperFile.nav()}


    <div class="container my-5">
        
{/*<!-- course title (logo and name) -->*/}
        <div class="container d-flex mb-3 px-0 align-content-center">
            <div class="mr-2">
                <img src="assets/icon-rvsm.svg" width="100" height="100" alt=""/>
            </div>
            <div class="ml-4">
                <div class="course-title">RVSM</div>
                <p class="lead font-weight-bolder">Reduced Vertical Separation Minimum</p>
            </div>
        </div>
        
        <div class="data-container mb-4">
            <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 border-right">
                <h4 class="text-primary">Test Info:</h4>
                <p>It is always advisable to complete your study and refresh your expertise before taking the test.</p>
                <p>The test is a multiple choice quiz designed to help you revive your ability and supplement your theoretical knowledge.</p>
                <h6 class="font-italic">Good Luck!</h6>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
            <table class="table">
                    <col width="25%"/>
                    <tr>
                        <td class="font-weight-bold border-top-0">Quiz Name:</td>
                        <td class="border-top-0">{this.state.props.name}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Quiz ID:</td>
                        <td>{this.state.props.quizId}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Description:</td>
                        <td>{this.state.props.description}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Time Limit:</td>
                        <td>{this.state.props.timeLimit} minutes</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Attempts Allowed:</td>
                        <td>{this.state.props.attempts}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">No. of Questions:</td>
                        <td>{this.state.props.numQuestions}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Scoring:</td>
                        <td>{this.state.props.points} points per question</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Passing Grade:</td>
                        <td>{this.state.props.passing}%</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Maximum Grade:</td>
                        <td>100%</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold text-success">Special Instructions:</td>
                        <td>{this.state.props.notes}</td>
                    </tr>
            </table>
                <div class="mt-5">
                    <a href="/user/test2?start=1"><button type="button" class="btn btn-lg btn-success" >Take The Test<i class="fa fa-arrow-alt-circle-right ml-2" aria-hidden="true"></i></button></a>
                </div>
                </div>
            </div>
        </div>
    </div>


 {helperFile.footer()}
 
</div>

)
}
}


export default ModalList1
