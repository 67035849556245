import React from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const helperFile = require("./airline_helper.js");
const Pager = require("../comp_misc").pager;

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/airline/certificates";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    <td>{props.item.id}</td>
<td>{props.item.userName}</td>
<td>{props.item.courseName}</td>
<td>{props.item.date}</td>
<td>{props.item.score}</td>
<td>{props.item.grade}</td>
<td>{props.item.feedback}</td>
<td><a href={"/airline/view_cert?id="+props.item.id}><button type="button" class="btn btn-outline-primary">View</button></a></td>


</tr>

}

class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      show: false,
      rows: null,
      name:null,
      email:"",
      error:null,
      image: null,
    };
  }




fillList()
{
  var rows1 = [];

  helper.getFromServer(g_link+"?dw=get")
  .then(res => {

    this.setState({showForm: false, rows: res.result, options: res.options, count: res.options.count});
  })



}
 componentDidMount() {
  this.fillList()  
  }

    

  render() {
document.title = "Certificates";

let spinner;
let pager;
if (!this.state.rows)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>;
}
else
{
  pager = <Pager page={this.state.page} count={this.state.options.count} link={g_link}/>;
}


let listItems;
if (this.state.rows)
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

}
return(
<div>
{helperFile.nav()}


    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-certificate mr-2" aria-hidden="true"></i>Certificates</h2>

{/*<!-- user info block start -->*/}
        <div class="data-container">
            <div class="row mb-2">
                <div class="col-8">
                    <p>All certificates stored here, latest issued certificate are displayed at the top of the table. You may filter the list by Crew Name or Course Name. You may also search the list by typing when you need in the search field.</p>
                </div>
                <div class="col-4">
                    <div class="card bg-secondary">
                        <div class="card-body text-center font-weight-bolder text-white">
                        Certificates: 2
                        </div>
                    </div>
                </div>
            </div>
            <hr/>

{/*<!-- filter search in flex container start -->*/}
            <div class="container d-flex my-4 px-0 justify-content-end">
                    <input class="form-control w-50" id="myInput" type="text" placeholder="Search.. by anything"/>
            </div>
            
            <hr class="border-warning"/>

{/*<!-- filter dropdowns in flex container start -->*/}         
            <div class="container d-flex my-4 px-0 justify-content-around">
{/*<!-- filter by crew name -->*/}          
                <div class="dropdown">
                    <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="filterCrewName" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Filter: By Crew Name
                    </button>
                    <div class="dropdown-menu" aria-labelledby="filterCrewName">
                        <a class="dropdown-item" href="#">Action</a>
                    </div>
                </div>
{/*<!-- filter by course-->*/}               
                <div class="dropdown">
                    <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="filterCourse" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Filter: By Course
                    </button>
                    <div class="dropdown-menu" aria-labelledby="filterCourse">
                        <a class="dropdown-item" href="#">Action</a>
                    </div>
                </div>
                <button type="button" class="btn btn-primary">Reset Filters</button>
            </div>
            
{/*<!-- certificates table list start -->*/}
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <table class="table mb-0 table-hover">
                      <thead>
                        <tr>
                          <th scope="col" >Cert. No.</th>
                          <th scope="col" >Name</th>
                          <th scope="col" >Course</th>
                          <th scope="col" >Awarded on</th>
                          <th scope="col" >Score</th>
                          <th scope="col" >Grade</th>
                          <th scope="col" >Feedback</th>
                          <th scope="col" >View</th>
                        </tr>
                     </thead>
                     {listItems}
                    </table>
                </div>
            </div>
        </div>
    </div>


    {pager}

 {helperFile.footer()}
 
</div>

)
}
}


export default ModalList1
