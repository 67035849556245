import React from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const helperFile = require("./airline_helper.js");
const Pager = require("../comp_misc").pager;

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/airline/users";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    <td>{props.item.name}</td>
<td>{props.item.group}</td>
<td>{props.item.rank}</td>
<td>{props.item.a_cName}</td>
<td>{props.item.courses}</td>
<td>{props.item.role}</td>
<td>{props.item.activity}</td>

  <td align="center"><button type="button" class="btn btn-outline-primary" onClick={() =>
    {
    props.parent.showModal(props.item) }
  }><i class="fa fa-edit mr-2" aria-hidden="true"></i>Edit</button></td>  

</tr>

}

class ModalList1 extends React.Component {
  

  constructor(props)
  {
    super(props);
    var params = helper.parseQuery();
    var page = params.p;
    if (!page)
      page = 0;

    this.state = {
      rows: null,
      error:null,
      image: null,
      page: page,
      
    };
  }


checkInits()
{
if (!this.state.aircraftIds)
     this.state.aircraftIds = [];
if (!this.state.groupIds)
     this.state.groupIds = [];
if (!this.state.courseIds)
     this.state.courseIds = [];

}

fillList(filter)
{
  var rows1 = [];

  helper.getFromServer(g_link+"?dw=get&filter="+filter)
  .then(res => {

    this.setState({showForm: false, rows: res.result, options: res.options, count: res.options.count});
  })



}
 componentDidMount() {
  this.fillList()  
  }

  showModal(item)
  {
      
//    this.setState({showForm: true, image: "/api/media/logo"+item.id+".png", id: item.id, name: item.name, email: helper.checkNull(item.email), error: ""});
    this.setState({showForm: true, id: item.id, rankId: item.rankId, aircraftIds: item.aircraftIds, groupIds: item.groupIds, courseIds: item.courseIds, home_base: item.home_base, alert: '', error: ""});
}

  hideModal()
  {
    this.setState({show: false});
  }

  saveItem()
  {
//    helper.postToServer(g_link, {dw: "update", id: this.state.id, email: this.state.email, name: this.state.name}, true)
    helper.postToServer(g_link, {dw: "update", id: this.state.id, rankId: this.state.rankId, aircraftIds: this.state.aircraftIds, groupIds: this.state.groupIds, courseIds: this.state.courseIds, home_base: this.state.home_base, }, true)
.then(res => {
      this.fillList();
    })
    .catch(err => {
      this.setState({error: err});
    })

  }
  addItem()
  {
    var item = {id: -1};
    this.showModal(item);
/*
    helper.postToServer(g_link, {dw: "add"}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })
*/
  }

  deleteItem()
  {

    helper.postToServer(g_link, {dw: "del", id: this.state.id}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }
  
  hiCheck1(event)
  {
    const target = event.target;
    const name = target.name;
    let value;
    if (target.checked)
      value = target.checked;
    else
      value = 0;

      this.setState({
        [name]: value
      });
  }

  
  filter(event)
  {
    const target = event.target;
//    const name = target.name;
    var value = target.value;

    this.fillList(value);

  }

  hic(event) {

    const target = event.target;
    const name = target.name;

    let value;
//    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'checkbox')
    {
      value = this.state[name];
      if (!value)
        value = [];
        // assumes DOM won't feed us dupes
        var vint = parseInt(target.value);

      if (target.checked)
      {
        value.push(vint);
      }
      else
      {
        var index = value.indexOf(vint);
        if (index !== -1) value.splice(index, 1);
      }
    }
    else
    {
      value = target.value;
    }
    this.setState({
      [name]: value
    });
  
  }

  onFile() {

  // OE??!! Fix this because it it confusing and only allows 1 _t per page
    var file = document.getElementById("undefined").files[0];
  
    var formData = new FormData();
    formData.append('file', file);
    formData.append('id', this.state.id)
    formData.append("dw", "saveFile")
    
    fetch(config.back+g_link+"_up", {
      method: 'POST', credentials: "same-origin",
      body: formData
    }).then(response => response.json())
    .then(res => {
      
      this.setState({image: "/api/media/undefined"+this.state.id+".png?"+new Date().getMilliseconds(),
    alert: "File was uploaded"});
    })
    .catch(error => { 
      alert(error);
    });
   //.then(response => console.log('Success:', response));
    
  }
    

  render() {
document.title = "Users";

let spinner;
let pager;
if (!this.state.rows)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>;
}
else
{
  pager = <Pager page={this.state.page} count={this.state.options.count} link={g_link}/>;
}

var error1 = <div class="alert alert-danger" role="alert">
<i class="fa fa-times mr-2" aria-hidden="true"></i>{this.state.error}
</div>;
if (!this.state.error)
error1 = <div></div>;
var alert1 = <div class="alert alert-success" role="alert">
<i class="fa fa-check mr-2" aria-hidden="true"></i>{this.state.alert}
</div>;

if (!this.state.alert)
    alert1 = <div></div>

let listItems;
var filterItems = [];

if (this.state.rows)
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

  if (this.state.options.filters)
  {
    this.state.options.filters.map((item) => {
      filterItems.push(<option value={item.id}>{item.name}</option>)
    });
  }

}
if (this.state.showForm)
{
  this.checkInits();
  
  var aircraftItems = this.state.options.aircraftList.map((item, index) => <div><label><input type="checkbox"
                 name="aircraftIds" value={item.id} checked={this.state.aircraftIds.indexOf(item.id) != -1}
 onChange={this.hic.bind(this)}/> {item.name}</label></div>);

var groupItems = this.state.options.groupList.map((item, index) => <div><label><input type="checkbox"
                 name="groupIds" value={item.id} checked={this.state.groupIds.indexOf(item.id) != -1}
 onChange={this.hic.bind(this)}/> {item.name}</label></div>);

var courseItems = this.state.options.courseList.map((item, index) => <div><label><input type="checkbox"
                 name="courseIds" value={item.id} checked={this.state.courseIds.indexOf(item.id) != -1}
 onChange={this.hic.bind(this)}/> {item.name}</label></div>);


  var rankItems = this.state.options.rankList.map((item, index) => <div class="form-check mb-2">
            <input class="form-check-input" type="radio" name="rankId" onChange={this.hic.bind(this)}
            value={item.id} checked={this.state.rankId == item.id} /><label class="form-check-label">
              {item.name}</label></div>);



  let deleteButton;

  if (this.state.id != -1)
  {
    deleteButton = <button type="submit" class="btn btn-danger mt-4" onClick={this.deleteItem.bind(this)}>Delete</button>
  }

  return(
<div>{helperFile.nav()}

    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-user mr-2" aria-hidden="true"></i>User Profile Form</h2>

{/*<!-- Error & Feedback alerts Show/hide as needed -->*/}
{/*<!--
    <div class="alert alert-success alert-dismissible fade show" role="alert">
        here is a successful dismissable alert
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="alert alert-warning alert-dismissible fade show" role="alert">
        here is a warning dismissable alert
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="alert alert-danger alert-dismissible fade show" role="alert">
        here is a danger error dismissable alert
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
-->*/}
{error1}
        
{/*<!-- user info block start -->*/}
    <div class="data-container">
        <div class="row">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                
                    <div class="form-group">
                        <h5 class="text-primary">User Info:</h5>
                        <div class="form-row">
                            <div class="col-6">
                                <input type="text" class="form-control" id="first_name" placeholder="First Name"/>
                            </div>
                            <div class="col-6">
                                <input type="text" class="form-control" id="last_name" placeholder="Last Name"/>
                            </div>
                            <div class="col-12">
                                <small id="nameHelp" class="form-text text-danger">Name must be entered as it should appear on the certificate</small>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="email" class="form-control" id="email" aria-describedby="emailHelp" placeholder="Email Address"/>
                        <small id="userEmailHelp" class="form-text text-danger">Enter email address to be used for communication and login for this user</small>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control"  name="home_base" value={this.state.home_base} onChange={this.hic.bind(this)}  placeholder="Home Base"/>
                    </div>
                    <div class="form-group">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="userActive" value="active" checked/>
                          <label class="form-check-label" for="userActive">Indicate if this user is Active/Inactive</label>
                        </div>
                    </div>
                <hr class="border-primary"/>
                    <h5 class="text-primary">Rank &amp; Aircraft:</h5>
                    <table class="form-table">
                        <tbody>
                            <tr>
                                <td width="100"><h6 class="m-0">Rank:</h6></td>
                                <td>
                                    {rankItems}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="form-table">
                        <tbody>
                            <tr>
                                <td width="100"><h6 class="m-0">Aircraft:</h6></td>
                                <td>You may assign one or more type for this user:</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    {aircraftItems}
</td>
                            </tr>
                        </tbody>
                    </table>
                <hr class="border-primary"/>
                    <h5 class="text-primary">Enrollment:</h5>
                    <table class="form-table">
                        <tbody>
                            <tr>
                                <td width="100"><h6 class="m-0">Groups:</h6></td>
                                <td>Tick the groups that this user will be enrolled in:</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    {groupItems}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="form-table">
                        <tbody>
                            <tr>
                                <td width="100"><h6 class="m-0">Courses:</h6></td>
                                <td>Default courses for selected group are displayed, tick/untick courses as required:</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    {courseItems}
                                </td>
                            </tr>
                        </tbody>
                    </table>   
                <hr class="border-primary"/>
                    <h5 class="text-primary">Login &amp; Password:</h5>
                    <div class="form-group">
                        <input type="email" class="form-control" id="userName" aria-describedby="userNameHelp" placeholder="Email used for login"/>
                        <small id="userNameHelp" class="form-text text-muted">Your username must be your email address</small>
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" id="password" placeholder="Password"/>
                        <small id="passwordHelp" class="form-text text-danger">To change password, enter and confirm below new password, otherwise leave blank</small>
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" id="confirmPassword" placeholder="Confirm Password"/>
                    </div>
                    
                    

                    <button type="submit" class="btn btn-primary mt-4" onClick={this.saveItem.bind(this)}>Add/Update Users</button>&nbsp;{deleteButton}&nbsp;<button class="btn btn-danger mt-4" onClick={() => {this.setState({showForm:false})}}>Close</button>
              
            </div>
        </div>
     </div>
 </div>
        

</div>


  )
  }
  else
  {
return(
<div>
  {spinner}
{helperFile.nav()}

    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-database mr-2" aria-hidden="true"></i>Users</h2>

        <div class="data-container">
            <div class="row mb-2">
                <div class="col-8">
                    <p>This screen displays all your crew members sorted by name in Alphabetical order. To view each crew member specific records, click on the View button. You may filter your crew by Group, Rank and Aircraft Type.</p>
                </div>
                <div class="col-4">
                    <div class="card bg-secondary">
                        <div class="card-body text-center font-weight-bolder text-white">
                        Users: {this.state.count}
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            

            <div class="container d-flex my-4 px-0 justify-content-between">
                <a class="btn btn-primary" role="button" onClick={this.addItem.bind(this)}><i class="fa fa-database mr-2" aria-hidden="true"></i>Add Users</a>
                <input class="form-control w-40" id="myInput" type="text" placeholder="Search.. by anything"/>
            </div>
            

            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <table class="table mb-0 table-hover">
                      <thead>
                        <tr>
                          <th  scope="col">Full Name</th>
                          <th  scope="col">Group</th>
                          <th  scope="col">Rank</th>
                          <th scope="col">A/C Type</th>
                          <th scope="col">Courses</th>
                          <th scope="col">Role</th>
                          <th scope="col">Activity</th>
                          <th scope="col" class="text-center" width="120" >Action</th>
                        </tr>
                      </thead>
                      <tbody id="myTable">
                      {listItems}
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
      

    {pager}
 {helperFile.footer()}
 
</div>

)
  }}
}


export default ModalList1
