import React, {useState} from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const helperFile = require("./airline_helper.js");

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/airline/certificates";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    
</tr>

}

class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    var id = helper.parseQuery()['id'];

    this.state = {
      id: id,
    };
  }




fillList()
{
    helper.getFromServer(g_link+"?dw=getOne&id="+this.state.id)
  .then(res => {

    this.setState({showForm: false, props: res.result.vals, rows: res.result.rows});
  })



}
 componentDidMount() {
  this.fillList()  
  }

    

  render() {
document.title = "Certificate";

let listItems;
let spinner;
if (!this.state.props)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>;

}
else 
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

}
if (!this.state.props)
{
  return(<div>
  {spinner}    
  </div>)
}
return(
<div>
{helperFile.nav()}


    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-certificate mr-2" aria-hidden="true"></i>Certificate</h2>
    </div>

{/*<!-- certificate items -->*/}
    <div class="cert-container d-flex justify-content-center d-block">
        <div class="cert-body">
            <img src="/certificate-example.png" alt="certificate"/>
            <div class="cert-number">{this.state.props.certNumber}</div>
            <div class="cert-award">{localStorage.getItem("name")}</div>
            <div class="cert-quiz">{this.state.props.courseName}</div>
            <div class="cert-on">{this.state.props.issueDate}</div>
            <div class="cert-date">{this.state.props.issueDate}"</div>
        </div>
    </div>
        <div class="d-flex justify-content-center my-4">
          <a href="#"><button type="button" class="btn btn-lg btn-warning"><i class="fa fa-print mr-2" aria-hidden="true"></i>Print Certificate</button></a>
        </div>
        


 {helperFile.footer()}
 
</div>

)
}
}


export default ModalList1
