import React from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const helperFile = require("./admin_helper.js");
const Pager = require("../comp_misc").pager;

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/admin/quizzes";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    <td>{props.item.name}</td>
<td>{props.item.code}</td>
<td>{props.item.forName}</td>
<td>{props.item.numQuestions}</td>
<td>{props.item.time}</td>
<td>{props.item.attempts}</td>
<td><a href={"/admin/questions?qid="+props.item.id}><button type="button" class="btn btn-outline-primary">Preview</button></a></td>

  <td align="center"><button type="button" class="btn btn-outline-primary" onClick={() =>
    {
    props.parent.showModal(props.item) }
  }><i class="fa fa-edit mr-2" aria-hidden="true"></i>Edit</button></td>  

</tr>

}

class ModalList1 extends React.Component {
  

  constructor(props)
  {
    super(props);
    var params = helper.parseQuery();
    var page = params.p;
    if (!page)
      page = 0;

    this.state = {
      rows: null,
      error:null,
      image: null,
      page: page,
      
    };
  }


checkInits()
{

}

fillList(filter)
{
  var rows1 = [];

  helper.getFromServer(g_link+"?dw=get&filter="+filter)
  .then(res => {

    this.setState({showForm: false, rows: res.result, options: res.options, count: res.options.count});
  })



}
 componentDidMount() {
  this.fillList()  
  }

  showModal(item)
  {
      
//    this.setState({showForm: true, image: "/api/media/logo"+item.id+".png", id: item.id, name: item.name, email: helper.checkNull(item.email), error: ""});
    this.setState({showForm: true, id: item.id, name: item.name, code: item.code, description: item.description, special: item.special, numQuestions: item.numQuestions, questionsPage: item.questionsPage, time: item.time, attempts: item.attempts, alert: '', error: ""});
}

  hideModal()
  {
    this.setState({show: false});
  }

  saveItem()
  {
//    helper.postToServer(g_link, {dw: "update", id: this.state.id, email: this.state.email, name: this.state.name}, true)
    helper.postToServer(g_link, {dw: "update", id: this.state.id, name: this.state.name, code: this.state.code, description: this.state.description, special: this.state.special, numQuestions: this.state.numQuestions, questionsPage: this.state.questionsPage, time: this.state.time, attempts: this.state.attempts, }, true)
.then(res => {
      this.fillList();
    })
    .catch(err => {
      this.setState({error: err});
    })

  }
  addItem()
  {
    var item = {id: -1};
    this.showModal(item);
/*
    helper.postToServer(g_link, {dw: "add"}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })
*/
  }

  deleteItem()
  {

    helper.postToServer(g_link, {dw: "del", id: this.state.id}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }
  
  hiCheck1(event)
  {
    const target = event.target;
    const name = target.name;
    let value;
    if (target.checked)
      value = target.checked;
    else
      value = 0;

      this.setState({
        [name]: value
      });
  }

  
  filter(event)
  {
    const target = event.target;
//    const name = target.name;
    var value = target.value;

    this.fillList(value);

  }

  hic(event) {

    const target = event.target;
    const name = target.name;

    let value;
//    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'checkbox')
    {
      value = this.state[name];
      if (!value)
        value = [];
        // assumes DOM won't feed us dupes
        var vint = parseInt(target.value);

      if (target.checked)
      {
        value.push(vint);
      }
      else
      {
        var index = value.indexOf(vint);
        if (index !== -1) value.splice(index, 1);
      }
    }
    else
    {
      value = target.value;
    }
    this.setState({
      [name]: value
    });
  
  }

  onFile() {

  // OE??!! Fix this because it it confusing and only allows 1 _t per page
    var file = document.getElementById("undefined").files[0];
  
    var formData = new FormData();
    formData.append('file', file);
    formData.append('id', this.state.id)
    formData.append("dw", "saveFile")
    
    fetch(config.back+g_link+"_up", {
      method: 'POST', credentials: "same-origin",
      body: formData
    }).then(response => response.json())
    .then(res => {
      
      this.setState({image: "/api/media/undefined"+this.state.id+".png?"+new Date().getMilliseconds(),
    alert: "File was uploaded"});
    })
    .catch(error => { 
      alert(error);
    });
   //.then(response => console.log('Success:', response));
    
  }
    

  render() {
document.title = "Quiz";

let spinner;
let pager;
if (!this.state.rows)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>;
}
else
{
  pager = <Pager page={this.state.page} count={this.state.options.count} link={g_link}/>;
}

var error1 = <div class="alert alert-danger" role="alert">
<i class="fa fa-times mr-2" aria-hidden="true"></i>{this.state.error}
</div>;
if (!this.state.error)
error1 = <div></div>;
var alert1 = <div class="alert alert-success" role="alert">
<i class="fa fa-check mr-2" aria-hidden="true"></i>{this.state.alert}
</div>;

if (!this.state.alert)
    alert1 = <div></div>

let listItems;
var filterItems = [];

if (this.state.rows)
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

  if (this.state.options.filters)
  {
    this.state.options.filters.map((item) => {
      filterItems.push(<option value={item.id}>{item.name}</option>)
    });
  }

}
if (this.state.showForm)
{
  this.checkInits();
  
  
  

  let deleteButton;

  if (this.state.id != -1)
  {
    deleteButton = <button type="submit" class="btn btn-danger mt-4" onClick={this.deleteItem.bind(this)}>Delete</button>
  }

  return(
<div>{helperFile.nav()}

    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-list mr-2" aria-hidden="true"></i>Quiz Form</h2>

{/*<!-- user info block start -->*/}
        <div class="data-container">
            <div class="row mb-2">
                <div class="col-12">
                    <h4>Add/Edit Quiz</h4>
                </div>
            </div>
            <hr class="border-primary mb-4"/>
            
{/*<!-- form start -->*/}
        <div class="row">
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                
                    <h5 class="text-primary">General Info:</h5>
                    <div class="form-group">
                        <input type="text" class="form-control" id="quizName" placeholder="Quiz Name"  name="name" value={this.state.name} onChange={this.hic.bind(this)} />
                        <small id="quizNameHelp" class="form-text text-muted">Quiz name as required, for example TCAS</small>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control w-20 text-center text-uppercase" id="quizID"  name="code" value={this.state.code} onChange={this.hic.bind(this)}  placeholder="Quiz ID"/>
                        <small id="quizIDHelp" class="form-text text-muted">Enter short max 4 digits quiz ID, two starting letters and number</small>
                    </div>
                    <div class="form-group">
                        <textarea class="form-control" id="quizDescription" rows="3" placeholder="Quiz Description"  name="description" value={this.state.description} onChange={this.hic.bind(this)} ></textarea>
                        <small id="quizDescriptionHelp" class="form-text text-muted">Short description of the quiz to appear in the quiz start screen</small>
                    </div>
                    <div class="form-group">
                        <textarea class="form-control" id="specialInstructions" rows="3" placeholder="Special Instructions"  name="special" value={this.state.special} onChange={this.hic.bind(this)} ></textarea>
                        <small id="specialInstructionsHelp" class="form-text text-muted">Enter special instructions about this quiz for the user to follow, optional</small>
                    </div>

                    <div class="form-group">
                    <select class="form-control">
                        <option selected>Select course associated with this quiz</option>
                        
                    </select>
                    </div>

                    <hr class="border-primary my-4"/>
                    
                    <h5 class="text-primary">Questions:</h5>
                    <div class="form-group">
                        <input type="text" class="form-control" id="questionDisplay"  name="numQuestions" value={this.state.numQuestions} onChange={this.hic.bind(this)}  placeholder="Questions to be Displayed"/>
                        <small id="questionDisplayHelp" class="form-text text-muted">Enter the maximum number of questions to be displayed for this quiz</small>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" id="questionPerPage"  name="questionsPage" value={this.state.questionsPage} onChange={this.hic.bind(this)}  placeholder="Questions Per Page"/>
                        <small id="questionPerPageHelp" class="form-text text-muted">Enter the maximum number of questions per page to be displayed to the user</small>
                    </div>
                    <div class="form-group">
                    <select id="shaffleQuestions" class="form-control">
                        <option selected>Shaffle questions</option>
                        <option>Yes</option>
                        <option>No</option>
                    </select>
                    </div>
                    
                    <hr class="border-primary my-4"/>
                    
                    <h5 class="text-primary">Time Control:</h5>
                    <div class="form-group">
                        <input type="text" class="form-control w-20 text-center" id="timeLimit"  name="time" value={this.state.time} onChange={this.hic.bind(this)}  placeholder="Time Limit"/>
                        <small id="timeLimitHelp" class="form-text text-muted">Enter the maximum time allowed (in minutes) for the user to complete this quiz</small>
                    </div>
                    <div class="form-group">
                    <select id="attemptsAllowed" class="form-control"  name="attempts" value={this.state.attempts} onChange={this.hic.bind(this)} >
                        <option selected>Attempts Allowed</option>
                        <option>Unlimited</option>
                        <option value="1">1 Attempt</option>
                        <option value="2">2 Attempt</option>
                        <option value="3">3 Attempt</option>
                        <option value="4">4 Attempt</option>
                        <option value="5">5 Attempt</option>
                    </select>
                    </div>
                    
                    <hr class="border-primary my-4"/>
                    
                    <h5 class="text-primary">Grading &amp; Scoring:</h5>
                    <table class="table">
                        <tbody>
                            <tr>
                                <td width="160">Scoring:</td>
                                <td>0</td>
                                <td>Points Per Question</td>
                            </tr>
                            <tr>
                                <td width="160">Maximum Grade:</td>
                                <td width="160"><input type="text" class="form-control text-center" id="maximumGrade"/></td>
                                <td>(%) Percent</td>
                            </tr>
                            <tr>
                                <td width="160">Passing Grade:</td>
                                <td width="160"><input type="text" class="form-control text-center" id="passingGrade"/></td>
                                <td>(%) Percent</td>
                            </tr>
                            <tr>
                                <td width="160">Grade Boundary:</td>
                                <td width="160">100%</td>
                                <td><select id="selectGrade-1" class="form-control">
                                    <option selected>Excellent</option>
                                    <option>Very Good</option>
                                    <option>Good</option>
                                    <option>Fail</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td width="160">Grade Boundary:</td>
                                <td width="160"><input type="text" class="form-control text-center" id="gradeBoundary-2"/></td>
                                <td><select id="selectGrade-2" class="form-control">
                                    <option>Excellent</option>
                                    <option selected>Very Good</option>
                                    <option>Good</option>
                                    <option>Fail</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td width="160">Grade Boundary:</td>
                                <td width="160"><input type="text" class="form-control text-center" id="gradeBoundary-3"/></td>
                                <td><select id="selectGrade-3" class="form-control">
                                    <option>Excellent</option>
                                    <option>Very Good</option>
                                    <option selected>Good</option>
                                    <option>Fail</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td width="160">Grade Boundary:</td>
                                <td width="160"><input type="text" class="form-control text-center" id="gradeBoundary-4"/></td>
                                <td><select id="selectGrade-4" class="form-control">
                                    <option>Excellent</option>
                                    <option>Very Good</option>
                                    <option>Good</option>
                                    <option selected>Fail</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <hr class="border-primary my-4"/>
                    <p class="text-danger font-italic">For a new quiz, you must save the quiz before you can add any questions</p>

                    <button type="submit" class="btn btn-primary mt-4" onClick={this.saveItem.bind(this)}>Add/Update Quiz</button>&nbsp;{deleteButton}&nbsp;<button class="btn btn-danger mt-4" onClick={() => {this.setState({showForm:false})}}>Close</button>
              
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 border-left">
                <h5>Adding Questions</h5>
                <p>For new quiz, you must save the quiz before you can add questions. For already saved quiz, you may go ahead and click the "Add Questions" button and start adding questions for this quiz.</p>
                <table class="table">
                    <tbody>
                        <tr>
                            <td class="px-0">Current Questions:</td>
                            <td align="right">22</td>
                        </tr>                      
                    </tbody>
                </table>
                <a href={"/admin/questions?qid="+this.state.id}><button type="button" class="btn btn-success btn-block btn-lg"><i class="fa fa-check mr-2" aria-hidden="true"></i>Add Questions</button></a>
            </div>
        </div>  
     </div>
 </div>
      

</div>


  )
  }
  else
  {
return(
<div>
  {spinner}
{helperFile.nav()}

    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-database mr-2" aria-hidden="true"></i>Quiz</h2>

        <div class="data-container">
            <div class="row mb-2">
                <div class="col-8">
                    <p>Quizzes for all courses listed here. To add a new quiz, click on the "Add Quiz" button, to update a quiz, click on the "Edit" button.</p>
                </div>
                <div class="col-4">
                    <div class="card bg-secondary">
                        <div class="card-body text-center font-weight-bolder text-white">
                        Quizzes: {this.state.count}
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            

            <div class="container d-flex my-4 px-0 justify-content-between">
                <a class="btn btn-primary" role="button" onClick={this.addItem.bind(this)}><i class="fa fa-database mr-2" aria-hidden="true"></i>Add Quiz</a>
                <input class="form-control w-40" id="myInput" type="text" placeholder="Search.. by anything"/>
            </div>
            

            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <table class="table mb-0 table-hover">
                      <thead>
                        <tr>
                          <th scope="col" >Quiz Name</th>  
                          <th scope="col" >Quiz ID</th>
                          <th scope="col">For Course</th>
                          <th scope="col" >Questions</th>
                          <th scope="col" >Time</th>
                          <th scope="col" >Attempts</th>
                          <th scope="col" >Preview</th>
                          <th scope="col" class="text-center" width="120" >Action</th>
                        </tr>
                      </thead>
                      <tbody id="myTable">
                      {listItems}
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
      

    {pager}
 {helperFile.footer()}
 
</div>

)
  }}
}


export default ModalList1
