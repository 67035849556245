const React = require("react")
//import { render } from "react-dom";
//import {callUrl} from "./helper"
//const Space = require("./comp_misc").compSpace
//const config = require('./config.js').CONFIG
//const Modal = require('react-modal')
const helper = require('../helper');
const ReactMarkdown = require('react-markdown')
const TextPrompt = require("../comp_misc").textPrompt
const CompList = require("../comp_misc").compList
const helperFile = require("./admin_helper.js");

var g_link = "/admin/wikis?dw=get";

class FormWiki extends React.Component {
  constructor() {
    super();

    var q = helper.parseQuery();
    var name = q.name ? decodeURI(q.name) : "outline";
    
  this.state = {
    id: q.id,
    name: name,
    page: null,

  };
    this.handleInputChange = this.handleInputChange.bind(this)
  }


fetchPage()
{
  console.log("name!")
  console.log(this.state.name)
  helper.getFromServer('/admin/wikis?dw=get&name='+helper.getURI(this.state.name))
  .then(res => {

//  console.log(res.result);

var page = helper.preMarkdown(res.result);

this.setState({page: page})
})
.catch(err => {
  alert(err)
})

}


componentDidMount() 
{
  this.fetchPage()

}


handleInputChange(event) {
  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const name = target.name;

  this.setState({
    [name]: value
  });

}

  render() {
    document.title = "Wiki Page"

    if (this.state.page === null) {
return(
      <div>Working on it2</div>);
    } else {

      var link = "/admin/wedit?name="+helper.getURI(this.state.name);
      var link1 = "/admin/wikis?name=outline";
    
return(
<div className="container">

{helperFile.nav()}
<br/>
<a href={link}>Edit</a>  <a href={link1}>Base Page</a><br/><br/>
<br/>
<ReactMarkdown>{this.state.page}</ReactMarkdown>
{helperFile.footer()}
</div>

)
}
  }
}

// onClick={() => { callUrl('logout') }}

export default FormWiki
