import React from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const helperFile = require("./user_helper.js");

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/user/course_content";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    
</tr>

}

class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    var id = helper.parseQuery()['id'];

    this.state = {
      id: id,
    };
  }




fillList()
{
    helper.getFromServer(g_link+"?dw=getOne&id="+this.state.id)
  .then(res => {

    this.setState({showForm: false, props: res.result.vals, rows: res.result.rows});
  })



}
 componentDidMount() {
  this.fillList()  
  }

    

  render() {
document.title = "Content Home";

let listItems;
let spinner;
if (!this.state.props)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>;

}
else 
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

}
if (!this.state.props)
{
  return(<div>
  {spinner}    
  </div>)
}
return(
<div>
{helperFile.nav()}


    <div class="container my-5">
        
{/*<!-- course title (logo and name) -->*/}
        <div class="container d-flex mb-5 px-0 align-content-center">
            <div class="mr-2">
                <img src="assets/icon-rvsm.svg" width="100" height="100" alt=""/>
            </div>
            <div class="ml-4">
                <div class="course-title">{this.state.props.name}</div>
                <p class="lead font-weight-bolder">{this.state.props.description}</p>
            </div>
        </div>
{/*<!-- course items start (data container) -->*/}
        <div class="data-container">
            <div class="row">
                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                    <table class="content-table">
                        <tbody>
                            <tr>
                                <td width="140"><img src="/content-manual-icon.svg" width="90" height="90" alt="manual icon"/></td>
                                <td><a href={"/user/content?id="+this.state.props.manualId}><h3>{this.state.props.name} Text Based Manual</h3></a></td>
                            </tr>
                            <tr>
                                <td width="140"><img src="/content-wbt-icon.svg" width="90" height="90" alt="web based training icon"/></td>
                                <td><a href={"/user/content?id="+this.state.props.videoId}><h3>{this.state.props.name} Web Based Training</h3></a></td>
                            </tr>
                            <tr>
                                <td width="140"><img src="/content-test-icon.svg" width="90" height="90" alt="test icon"/></td>
                                <td><a href={"/user/test1?id="+this.state.id}><h3>{this.state.props.name} Test Quiz</h3></a></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="small font-italic mt-2">
                        <p>Please ensure to complete the training in one visit including the test, system may shutdown if you exceed the assigned time limit for the course.</p>
                    </div>
                </div>
                
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 border-left">
                    <h5>Training Guidelines</h5>
                    <p>This training is in accordance with EASA/OPS/CAT</p>
                    <p>Initial/Conversion/Recurrent Training</p>
                    <hr class="border-primary"/>
                    <p>Please follow the three steps to your left to complete the training:</p>
                    <p>Step-1: {this.state.props.name} Training Manual of OM Part D can be used as a good reference, you may view or download to your computer and study it at your convenience. The Manual is in PDF format and you may require Adobe Acrobat to open the file.</p>
                    <p>Step 2: Web Based Training: 60 minutes of video covering essential parts of the {this.state.props.name} practices, it is recommended that you view and make notes of all the video.</p>
                    <p>Step 3: {this.state.props.name} web-based test consist of 20 multiple choice questions with a passing grade of 75%. The test duration is controlled by a time limit of 60 minutes and only one attempt is allowed.</p>
                </div>
            </div>
        </div>
    </div>


 {helperFile.footer()}
 
</div>

)
}
}


export default ModalList1
