const React = require("react")
const helper = require('../helper');
const ReactMarkdown = require('react-markdown')
const helperFile = require("./user_helper.js");

var g_link = "/user/help";

class FormWiki extends React.Component {
  constructor() {
    super();

    var q = helper.parseQuery();
    var name = decodeURI(q.name);
    if (!name || name == "undefined")
      name = "outline";

  this.state = {
    id: q.id,
    name: name,
    page: null,

  };
    this.handleInputChange = this.handleInputChange.bind(this)
  }


fetchPage()
{
  helper.getFromServer(g_link+"?dw=get&name="+helper.getURI(this.state.name))
  .then(res => {

//  console.log(res.result);

var page = helper.preMarkdown(res.result, "help");

this.setState({page: page})
})
.catch(err => {
  alert(err)
})

}


componentDidMount() 
{
  this.fetchPage()

}


handleInputChange(event) {
  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const name = target.name;

  this.setState({
    [name]: value
  });

}

  render() {
    document.title = "Wiki Page"

    if (this.state.page === null) {
return(
      <div>Working on it2</div>);
    } else {

      var link1 = g_link+"?name=outline";
    
return(
<div className="container">

{helperFile.nav()}
<br/>
<a href={link1}>Base Page</a><br/><br/>
<br/>
<ReactMarkdown>{this.state.page}</ReactMarkdown>
{helperFile.footer()}
</div>

)
}
  }
}

// onClick={() => { callUrl('logout') }}

export default FormWiki
