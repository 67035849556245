import React from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const helperFile = require("./user_helper.js");

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/user/test3";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    
</tr>

}

class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    var id = helper.parseQuery()['id'];

    this.state = {
      id: id,
    };
  }




fillList()
{
    helper.getFromServer(g_link+"?dw=getOne&id="+this.state.id)
  .then(res => {

    this.setState({showForm: false, props: res.result.vals, rows: res.result.rows});
  })



}
 componentDidMount() {
  this.fillList()  
  }

    

  render() {
document.title = "Test";

let listItems;
let spinner;
if (!this.state.props)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>;

}
else 
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

}
if (!this.state.props)
{
  return(<div>
  {spinner}    
  </div>)
}
return(
<div>
{helperFile.nav()}


    <div class="container my-5">
        
{/*<!-- course title (logo and name) -->*/}
        <div class="container d-flex mb-3 px-0 align-content-center">
            <div class="mr-2">
                <img src="assets/icon-rvsm.svg" width="100" height="100" alt=""/>
            </div>
            <div class="ml-4">
                <div class="course-title">RVSM</div>
                <p class="lead font-weight-bolder">Reduced Vertical Separation Minimum</p>
            </div>
        </div>
        
        <div class="data-container mb-4">
            <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 border-right">
                <h4 class="text-primary">Finish &amp; Score:</h4>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
            <table class="table">
                    <col width="25%"/>
                    <tr>
                        <td class="font-weight-bold border-top-0">Total Questions:</td>
                        <td class="border-top-0">{this.state.props.totalQuestions}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Questions Answered:</td>
                        <td>{this.state.props.totalAnswered}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Missed:</td>
                        <td>{this.state.props.missed}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">Note:</td>
                        <td>If you have missed any questions, you may click the <strong>"Review Answers"</strong> button below to go back to the quiz, otherwise click <strong>"Finish &amp; Score"</strong> to submit your test and score.</td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold text-danger">Test Consent:</td>
                        <td>Upon submitting this test, you agree that you have performed and followed the correct ground school recurrent online training guidelines as stated by your company training procedures. The test result will be presented in the next page and a certificate shall be issued based on achieving the Passing Grade required for this course, and your currency will be renewed for a further validation period. If your score is below the required passing level, No certificate will be issued and you may arrange with your training department for further attempts to accomplish your currency.</td>
                    </tr>
            </table>
                <div class="mt-5">
                    <a href="/user/test2"><button type="button" class="btn btn-lg btn-success mr-2"><i class="fa fa-arrow-alt-circle-left mr-2" aria-hidden="true"></i>Review Answers</button></a>
                    <a href="/user/test4"><button type="button" class="btn btn-lg btn-danger">Finish &amp; Score<i class="fa fa-arrow-alt-circle-right ml-2" aria-hidden="true"></i></button></a>
                </div>
                </div>
            </div>
        </div>
    </div>


 {helperFile.footer()}
 
</div>

)
}
}


export default ModalList1
