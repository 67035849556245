import React from "react";
const helper = require("./helper");


// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/register";


class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      crewCount:25,
      payMethod:"annualPlan",
    };
  }

  save()
  {
    alert("hey!");
  }

  hic(event) {

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
  
    //console.log(name)
    this.setState({
      [name]: value
    });
  
  }

    

  render() {
document.title = "Dashboard";

return(
<div>


        <div class="reg-form-container">
            
            {/*<!-- left side start -->*/}
            <div class="reg-form-left">
                <h5 class="text-primary">Things you should know</h5>
                <hr/>
                
                <h5 class="mb-0 text-dark">Get Started</h5>
                <p>Your airline account can be started in about 5 minutes. Upon submitting the registration form, we will instantly send you a verification email to the email address you provided, for your security and ours we need to verify your email address. Once this is done your account becomes available and ready to go.
                </p>
                
                <h5 class="mb-0 text-dark">Your first month is free</h5>
                <p>You will automatically get 30 days free trial with full system functionality except issuing or printing certificates. After the free trial expires, you have the option to continue using the system as a paying member or close your account and all your data will be removed. If you decide to become a permanent member from the start, you will still get a free month extra.
                </p>
                
                <h5 class="mb-0 text-dark">Courses</h5>
                <p>We provide 13 generic online courses for pilots, ranging from: RVSM, PBN, TCAS, CWO, LVO, UPRT, TAWS, DGR, Route and Aerodrome, MNPS, ETOPS, Aviation Security, First Aid.  Each course may combine a text based manual, Web based training (WBT) represented in video or flash format, and the final test quiz for the course. Upon successful completion a certificate bearing your airline logo is issued and currency validity should be accomplished. You may also add your own type-specific courses and create an online customised courses depending on your airline requirement, custom courses specific to your airline are subject to agreed charges at the time of implementation.
                </p>
                
                <h5 class="mb-0 text-dark">Fair pricing policy</h5>
                <p>We believe in fair pricing, RecurrentPro is probably the most competitive crew training system found on the market today. We offer monthly rolling plan, or some customers prefer to take advantage of the 10% discount offered on our annual plan.
                </p>
            </div>
            
            {/*<!-- right side start // form elements here-->*/}
            <div class="reg-form-right">
                <h5 class="text-primary">Airline Training Form</h5>
                <hr/>
                
            {/*<!-- reg form start -->*/}
                    <div class="form-group">
                      <input type="text" class="form-control" id="airlineName" placeholder="Airline Name"/>
                    </div>
                    <div class="form-group">
                        <input type="email" class="form-control" id="airlineEmail" placeholder="Airline Email"/>
                        <small id="airlineEmailHelp" class="form-text text-muted">This email address will be used as your master login username to the Airline Admin Section</small>
                    </div>
                    <div class="form-group">
                        <input type="email" class="form-control" id="confirmAirlineEmail" placeholder="Confirm Airline Email"/>
                      <small id="confirmAirlineEmailHelp" class="form-text text-muted">We require a match, ensure correct entry</small>
                    </div>
                    <div class="form-group">
                      <input type="text" class="form-control" id="contactName" placeholder="Contact Name.. i.e John Smith"/>
                    </div>
                  <div class="form-group">
                      <input type="text" class="form-control" id="jobPosition" placeholder="Your Job Position"/>
                    </div> 
                    <div class="form-group">
                      <input type="text" class="form-control" id="airlineWebsite" placeholder="Airline Website"/>
                        <small id="airlineWebsiteHelp" class="form-text text-muted">You may wish to integrate a training link from within your website, we will provide you later</small>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" id="telephone" placeholder="Telephone.. include country code"/>
                      <small id="telephoneHelp" class="form-text text-muted">Optional, but just in case we need to contact you</small>
                    </div>
                    
                    <hr class="border-warning"/>
                    
    {/*<!-- training cost -->*/}
                    <h5 class="text-primary mb-0">Workout Your Training Cost:</h5>
                    <p>We believe in fair pricing, our charges are extremely competitive and totally based on the number of crew members likely to use the system. Enter the number of crew members in the field below to workout your training cost:</p>
                        <table class="table mb-1">
                            <tbody>
                                <tr class="font-weight-bold">
                                    <td width="200">Crew Members</td>
                                    <td>&nbsp;</td>
                                    <td>Monthly Plan</td>
                                    <td>Annual Plan*</td>
                                    <td>Rate Per Crew</td>
                                </tr>
                                <tr>
                                    <td><input type="text" class="form-control w-40 text-center" name="crewCount" onChange={this.hic.bind(this)} id="crewCount" value={this.state.crewCount}/></td>
                                    <td><input type="button" class="btn btn-primary" value="Show price" onClick={() => {
                                      var crewCount = document.getElementById("crewCount").value;
                                      document.getElementById("monthly").innerHTML = "£"+(crewCount*15).toLocaleString();
                                      document.getElementById("annual").innerHTML = "£"+(crewCount*15*.9*12).toLocaleString();
                                    }}/></td>
                                    <td id="monthly">£375</td>
                                    <td id="annual">£4,050</td>
                                    <td>£15</td>
                                </tr>
                            </tbody>
                        </table>
                    <p class="font-italic my-0 font-weight-bolder">* The Annual Plan figure above is discounted at 10% when paid annualy.</p>
                    <p class="font-italic my-0 font-weight-bolder">** For over 100 crew, further discount may apply, please contact.</p>

                    <hr class="border-warning"/>
                    
    {/*<!-- payment method -->*/} 
                    <h5 class="text-primary mb-0">Payment Plan:</h5>
                    <p class="mb-2">Choose your preferred payment method:</p>
                    <div class="form-check mb-2">
                        <input class="form-check-input" type="radio" name="payMethod" onChange={this.hic.bind(this)} 
                          value="monthlyPlan" checked={this.state.payMethod === 'monthlyPlan'} />
                        <label class="form-check-label" for="monthlyPlan">
                            Monthly Plan
                        </label>
                    </div>
                    <div class="form-check mb-2">
                        <input class="form-check-input" type="radio" name="payMethod" 
                          onChange={this.hic.bind(this)} value="annualPlan" checked={this.state.payMethod === 'annualPlan'}/>
                        <label class="form-check-label" for="annualPlan">
                            Annual Plan
                        </label>
                    </div>
                    
                    <hr class="border-warning"/>
                    
    {/*<!-- free trial  -->*/} 
                    <h5 class="text-primary mb-0">Start me:</h5>
                    <p class="mb-2">You may wish to start as a full member straight away, you still get one month free:</p>
                    <div class="form-check mb-2">
                        <input class="form-check-input" type="radio" name="startMe" id="fullMember" value="fullMember" checked/>
                        <label class="form-check-label" for="skipTrial">
                            Start me as a full member
                        </label>
                    </div>
                    <div class="form-check mb-2">
                        <input class="form-check-input" type="radio" name="startMe" id="freeTrial" value="freeTrial"/>
                        <label class="form-check-label" for="freeTrial">
                            Start me in Free Trial*
                        </label>
                    </div>
                    <p class="font-italic my-0 font-weight-bolder">* In free trial mode you are not able to issue or print certificates.</p>
                    
                    <hr class="border-warning"/>
                    
                    <h5 class="text-primary mb-0">Login &amp; Password:</h5>
                    <p>We will use the email address you entered above as your <strong>Username</strong> for login to your training admin section. Please enter a good password at least 8 digits, combine letters and numbers for better security.</p>
                    <div class="form-group">
                      <input type="password" class="form-control" id="password" placeholder="Password"/> 
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" id="confirmPassword" placeholder="Confirm Password"/>
                        <small id="passwordHelp" class="form-text text-muted">Please confirm your password, we require a match</small>
                    </div>
                    
                    <hr class="border-warning"/>
                    <h5 class="text-primary mb-0">Airline Logo:</h5>
                    <p>We shall need your airline logo to display on your training pages and the certificate, please forward your logo by email to: support@recurrentpro.com</p>
                    
                    <hr class="border-warning"/>
                    <div class="form-check mt-4">
                        <input type="checkbox" class="form-check-input" id="termsAgree" required/>
                        <label class="form-check-label" for="termsAgree"><a href="z-airline-tandc.html" target="_blank">I agree to RecurrentPro terms of use</a></label>
                    </div>
                    <p class="mt-4"> reCaptua goes here</p>
                  <button class="btn btn-warning btn-lg mt-4" onClick={this.save.bind(this)}>Create Airline Account</button>
          </div>
        </div>


 
</div>

)
}
}


export default ModalList1
