import React from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const helperFile = require("./user_helper.js");

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/user/dashboard";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    
</tr>

}

class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    var id = helper.parseQuery()['id'];

    this.state = {
      id: id,
    };
  }




fillList()
{
    helper.getFromServer(g_link+"?dw=getOne&id="+this.state.id)
  .then(res => {

    this.setState({showForm: false, props: res.result.vals, rows: res.result.rows});
  })



}
 componentDidMount() {
  this.fillList()  
  }

    

  render() {
document.title = "Dashboard";

let listItems;
let spinner;
if (!this.state.props)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>;

}
else 
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

}
if (!this.state.props)
{
  return(<div>
  {spinner}    
  </div>)
}
return(
<div>
{helperFile.nav()}


    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-dashboard mr-2" aria-hidden="true"></i>Dashboard</h2>

{/*<!-- user table general info start -->*/}
        <div class="data-container">
            <div class="row">
                <div class="col-12 text-center">
                    <h4 class="text-center mb-4">Welcome: {this.state.props.fullName}</h4>
                 </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-sm-12 col-12">
                    <table class="table mb-0">
                      <tbody>
                        <tr>
                          <td class="font-weight-bolder text-dark" width="160">Aircraft Type:</td>
                          <td>{this.state.props.aircraftType}</td>
                        </tr>
                        <tr>
                          <td class="font-weight-bolder text-dark" width="160">Rank:</td>
                          <td>{this.state.props.rank}</td>
                        </tr>
                        <tr>
                          <td class="font-weight-bolder text-dark" width="160">Group:</td>
                          <td>FC</td>
                        </tr>
                        <tr>
                          <td class="font-weight-bolder text-dark" width="160">Assigned Courses:</td>
                          <td>11</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
                <div class="col-xl-6 col-lg-6 col-sm-12 col-12">
                    <table class="table mb-0">
                      <tbody>
                        <tr>
                          <td class="font-weight-bolder text-dark" width="160">Contact Email:</td>
                          <td>{this.state.props.email}</td>
                        </tr>
                        <tr>
                          <td class="font-weight-bolder text-dark" width="160">Last Logged:</td>
                          <td>{this.state.props.lastLogin}</td>
                        </tr>
                        <tr>
                          <td class="font-weight-bolder text-dark" width="160">Logged Role:</td>
                          <td>Trainee</td>
                        </tr>
                        <tr>
                          <td class="font-weight-bolder text-dark" width="160">Status:</td>
                          <td>{this.state.props.status}</td>
                        </tr>
                      </tbody>
                    </table>
                </div>       
            </div>
        </div>
        
{/*<!-- course status start-->*/}
        <div class="container my-5 px-0">
            <div class="row">
                <div class="col-12 mb-2 text-center">
                    <h4>Are You Current?</h4>
                    <p>Check your currency status below, Well done if all green</p>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <div class="card border-success p-3 mb-2">
                        <img src="/status-green.svg" width="30" height="30" alt="green"/>
                        <div class="container d-flex justify-content-between mt-2">
                            <h4>Current</h4>
                            <h2>{this.state.props.current}</h2>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <div class="card border-warning p-3 mb-2">
                        <img src="/status-orange.svg" width="30" height="30" alt="orange"/>
                        <div class="container d-flex justify-content-between mt-2">
                            <h4>Approaching</h4>
                            <h2>{this.state.props.approaching}</h2>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <div class="card border-danger p-3 mb-2">
                        <img src="/status-red.svg" width="30" height="30" alt="red"/>
                        <div class="container d-flex justify-content-between mt-2">
                            <h4>Expiring</h4>
                            <h2>{this.state.props.expiring}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="container d-flex justify-content-center mb-5">
            <a href="/user/courses"><button type="button" class="btn btn-lg btn-primary">Go To Training</button></a>
        </div>

{/*<!-- performance chart -->*/}
        <div class="data-container mt-4">
            <div class="row">
                <div class="col-12">
                    <h5 class="text-center text-black-50">Performance Chart</h5>
                </div>
                <div class="col-12 mt-2 text-center">
                    <p class="text-danger">Insert chart here and remove this paragraph tag</p>
                </div>
            </div>
        </div>
</div>



 {helperFile.footer()}
 
</div>

)
}
}


export default ModalList1
