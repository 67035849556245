import React from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const helperFile = require("./airline_helper.js");
const Pager = require("../comp_misc").pager;

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/airline/attempts";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    <td>{props.item.attemptDate}</td>
<td>{props.item.crewName}</td>
<td>{props.item.courseName}</td>
<td>{props.item.quizName}</td>
<td>{props.item.correct}</td>
<td>{props.item.incorrect}</td>
<td>{props.item.missed}</td>
<td>{props.item.score}</td>
<td>{props.item.feedback}</td>
<td>{props.item.grade}</td>
<td><a href="#"><img src="/certificate-icon.svg" width="40" height="40" alt="Certificate1"/></a></td>


</tr>

}

class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      show: false,
      rows: null,
      name:null,
      email:"",
      error:null,
      image: null,
    };
  }




fillList()
{
  var rows1 = [];

  helper.getFromServer(g_link+"?dw=get")
  .then(res => {

    this.setState({showForm: false, rows: res.result, options: res.options, count: res.options.count});
  })



}
 componentDidMount() {
  this.fillList()  
  }

    

  render() {
document.title = "Attempts";

let spinner;
let pager;
if (!this.state.rows)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>;
}
else
{
  pager = <Pager page={this.state.page} count={this.state.options.count} link={g_link}/>;
}


let listItems;
if (this.state.rows)
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

}
return(
<div>
{helperFile.nav()}

    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-database mr-2" aria-hidden="true"></i>Attempts</h2>


        <div class="data-container">
            <div class="row mb-2">
                <div class="col-8">
                    <p>This screen displays all your crew members sorted by name in Alphabetical order. To view each crew member specific records, click on the View button. You may filter your crew by Group, Rank and Aircraft Type.</p>
                </div>
                <div class="col-4">
                    <div class="card bg-secondary">
                        <div class="card-body text-center font-weight-bolder text-white">
                        Attempts: {this.state.count}
                        </div>
                    </div>
                </div>
            </div>
            <hr/>

            

            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <table class="table mb-0 table-hover">
                      <thead>
                        <tr>
                          <th scope="col" >Attempt Date</th>
                          <th scope="col" >Crew Name</th>
                          <th scope="col" >Course</th>
                          <th scope="col" >Quiz</th>
                          <th scope="col" >Correct</th>
                          <th scope="col" >Incorrect</th>
                          <th scope="col" >Missed</th>
                          <th scope="col" >Score</th>
                          <th scope="col" >Feedback</th>
                          <th scope="col" >Grade</th>
                          <th scope="col" >Certificate</th>
                        </tr>
                      </thead>
                      <tbody id="myTable">
                      {listItems}
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
      

    {pager}

 {helperFile.footer()}
 
</div>

)
}
}


export default ModalList1
