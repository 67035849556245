import React from "react";
const helper = require("../helper");
const helperFile = require("./user_helper.js");

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/user/content";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    
</tr>

}

class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    var id = helper.parseQuery()['id'];

    this.state = {
      id: id,
    };
  }




fillList()
{
    helper.getFromServer(g_link+"?id="+this.state.id)
  .then(res => {

    this.setState({showForm: false, props: res.result.vals});
  })
  .catch(err => {
    this.setState({props: {error: "An error has occurred, probably because there is no media for this item."}})
  })



}
 componentDidMount() {
  this.fillList()  
  }

    

  render() {
document.title = "Media";

let listItems;
let spinner;
if (!this.state.props)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>;
    return spinner;
}
let frame;
if (this.state.props.ext == "pdf")
{
  frame = <div class="container mx-0 px-0">
  <div class="embed-responsive embed-responsive-21by9">
    <iframe class="embed-responsive-item" src={"/api/media/"+this.state.props.link} allowfullscreen></iframe>
  </div>
</div>

}
else
{
  frame = <div class="container mx-0 px-0">
  <div class="embed-responsive embed-responsive-16by9">
    <iframe class="embed-responsive-item" src={"/api/media/"+this.state.props.link} type="video/mp4"></iframe>
  </div>
</div>

}
return(
<div>
{helperFile.nav()}
{this.state.props.error}


    <div class="container my-5">
        
{/*<!-- course title (logo and name) -->*/}
        <div class="container d-flex mb-5 px-0">
            <div class="mr-2">
                <img src="assets/icon-rvsm.svg" width="100" height="100" alt=""/>
            </div>
            <div class="ml-4">
                <div class="course-title">{this.state.props.name}</div>
                <p class="lead font-weight-bolder">{this.state.props.description}</p>
            </div>
        </div>
        <div class="container d-flex justify-content-end mb-5">
            <a href="media">Back to Courses</a>
{/*            
            <div class="dropdown">
                  <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Go to another course
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="#">Action</a>
                  </div>
            </div>
*/}            
        </div>
        
        <div class="data-container mb-4">{/*<!-- this is fixed text no dynamics -->*/}
            <h5 class="text-primary">Description</h5>
            <ul>
                {this.state.props.notes}
            </ul>
        </div>
        
{/*<!-- course item object embed start for pdf viewer -->*/}
{frame}
    </div>


 {helperFile.footer()}
 
</div>

)
}
}


export default ModalList1
