import React from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const helperFile = require("./admin_helper.js");
const Pager = require("../comp_misc").pager;

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/admin/groups";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    <td>{props.item.airlineName}</td>
<td>{props.item.name}</td>
<td>{props.item.shortName}</td>
<td>{props.item.courses}</td>
<td>{props.item.memberCount}</td>
<td>{props.item.certificateName}</td>

  <td align="center"><button type="button" class="btn btn-outline-primary" onClick={() =>
    {
    props.parent.showModal(props.item) }
  }><i class="fa fa-edit mr-2" aria-hidden="true"></i>Edit</button></td>  

</tr>

}

class ModalList1 extends React.Component {
  

  constructor(props)
  {
    super(props);
    var params = helper.parseQuery();
    var page = params.p;
    if (!page)
      page = 0;

    this.state = {
      rows: null,
      error:null,
      image: null,
      page: page,
      
    };
  }


checkInits()
{

}

fillList(filter)
{
  var rows1 = [];

  helper.getFromServer(g_link+"?dw=get&filter="+filter)
  .then(res => {

    this.setState({showForm: false, rows: res.result, options: res.options, count: res.options.count});
  })



}
 componentDidMount() {
  this.fillList()  
  }

  showModal(item)
  {
      
//    this.setState({showForm: true, image: "/api/media/logo"+item.id+".png", id: item.id, name: item.name, email: helper.checkNull(item.email), error: ""});
    this.setState({showForm: true, id: item.id, airlineId: item.airlineId, certificateId: item.certificateId, name: item.name, shortName: item.shortName, alert: '', error: ""});
}

  hideModal()
  {
    this.setState({show: false});
  }

  saveItem()
  {
//    helper.postToServer(g_link, {dw: "update", id: this.state.id, email: this.state.email, name: this.state.name}, true)
    helper.postToServer(g_link, {dw: "update", id: this.state.id, airlineId: this.state.airlineId, certificateId: this.state.certificateId, name: this.state.name, shortName: this.state.shortName, }, true)
.then(res => {
      this.fillList();
    })
    .catch(err => {
      this.setState({error: err});
    })

  }
  addItem()
  {
    var item = {id: -1};
    this.showModal(item);
/*
    helper.postToServer(g_link, {dw: "add"}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })
*/
  }

  deleteItem()
  {

    helper.postToServer(g_link, {dw: "del", id: this.state.id}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }
  
  hiCheck1(event)
  {
    const target = event.target;
    const name = target.name;
    let value;
    if (target.checked)
      value = target.checked;
    else
      value = 0;

      this.setState({
        [name]: value
      });
  }

  
  filter(event)
  {
    const target = event.target;
//    const name = target.name;
    var value = target.value;

    this.fillList(value);

  }

  hic(event) {

    const target = event.target;
    const name = target.name;

    let value;
//    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'checkbox')
    {
      value = this.state[name];
      if (!value)
        value = [];
        // assumes DOM won't feed us dupes
        var vint = parseInt(target.value);

      if (target.checked)
      {
        value.push(vint);
      }
      else
      {
        var index = value.indexOf(vint);
        if (index !== -1) value.splice(index, 1);
      }
    }
    else
    {
      value = target.value;
    }
    this.setState({
      [name]: value
    });
  
  }

  onFile() {

  // OE??!! Fix this because it it confusing and only allows 1 _t per page
    var file = document.getElementById("undefined").files[0];
  
    var formData = new FormData();
    formData.append('file', file);
    formData.append('id', this.state.id)
    formData.append("dw", "saveFile")
    
    fetch(config.back+g_link+"_up", {
      method: 'POST', credentials: "same-origin",
      body: formData
    }).then(response => response.json())
    .then(res => {
      
      this.setState({image: "/api/media/undefined"+this.state.id+".png?"+new Date().getMilliseconds(),
    alert: "File was uploaded"});
    })
    .catch(error => { 
      alert(error);
    });
   //.then(response => console.log('Success:', response));
    
  }
    

  render() {
document.title = "Groups";

let spinner;
let pager;
if (!this.state.rows)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>;
}
else
{
  pager = <Pager page={this.state.page} count={this.state.options.count} link={g_link}/>;
}

var error1 = <div class="alert alert-danger" role="alert">
<i class="fa fa-times mr-2" aria-hidden="true"></i>{this.state.error}
</div>;
if (!this.state.error)
error1 = <div></div>;
var alert1 = <div class="alert alert-success" role="alert">
<i class="fa fa-check mr-2" aria-hidden="true"></i>{this.state.alert}
</div>;

if (!this.state.alert)
    alert1 = <div></div>

let listItems;
var filterItems = [];

if (this.state.rows)
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

  if (this.state.options.filters)
  {
    this.state.options.filters.map((item) => {
      filterItems.push(<option value={item.id}>{item.name}</option>)
    });
  }

}
if (this.state.showForm)
{
  this.checkInits();
  var airlineList = this.state.options.airlineList.map((item, index) =>
                <option value={item.id}>{item.name}</option>);
var certificateList = this.state.options.certificateList.map((item, index) =>
                <option value={item.id}>{item.name}</option>);

  
  

  let deleteButton;

  if (this.state.id != -1)
  {
    deleteButton = <button type="submit" class="btn btn-danger mt-4" onClick={this.deleteItem.bind(this)}>Delete</button>
  }

  return(
<div>{helperFile.nav()}

    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-sitemap mr-2" aria-hidden="true"></i>Groups Form</h2>

{/*<!-- Error & Feedback alerts Show/hide as needed -->*/}
{/*<!--
    <div class="alert alert-success alert-dismissible fade show" role="alert">
        Airline details has been saved/updated successfully
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="alert alert-warning alert-dismissible fade show" role="alert">
        here is a warning dismissable alert
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="alert alert-danger alert-dismissible fade show" role="alert">
        here is a danger error dismissable alert
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
-->*/}
{error1}

{/*<!-- user info block start -->*/}
        <div class="data-container">
            <div class="row mb-2">
                <div class="col-12">
                    <h4>Add/Edit Group</h4>
                </div>
            </div>
            <hr class="border-primary mb-4"/>
            
{/*<!-- form start -->*/}
        <div class="row">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                <p>A Group contains a set of users known as Group Members, each assigned user must belong to a group. Groups are assigned to airlines and each airline may have different groups depending on their training requirements. Certificates are also assigned to specific group(s) of the same airline, this will provide more flexible certificate management with different signatures. A group name is not unique and can be used by various airlines.</p>
                
                    <div class="form-group">
                        <select id="selectAirline"  name="airlineId" value={this.state.airlineId}  onChange={this.hic.bind(this)} class="form-control">
                            <option selected>Assign this group to airline</option>
                            {airlineList}
                        </select>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control w-40" id="groupName" name="shortName" value={this.state.shortName} onChange={this.hic.bind(this)}  name="name" value={this.state.name} onChange={this.hic.bind(this)}  placeholder="Group Name"/>
                        <small id="groupNameHelp" class="form-text text-muted">Enter a group name, for example Flight Crew, Cabin Crew, A320</small>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control w-40 text-uppercase"  name="shortName" value={this.state.shortName} onChange={this.hic.bind(this)}  id="groupShortName" placeholder="Group Short Name"/>
                        <small id="groupShortNameHelp" class="form-text text-muted">Group Short name – for example Flight Crew dispalyed as FC</small>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" id="groupDescription" placeholder="Group Description"/>
                        <small id="groupDescriptionHelp" class="form-text text-muted">Optional notes or description for this group</small>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" id="groupManager" placeholder="Group Manager"/>
                        <small id="groupManagerHelp" class="form-text text-muted">Optional, enter a group manager name if required</small>
                    </div>
                    
                    <hr class="border-primary mb-4"/>
                    
                    <div class="form-group">
                        <h5 class="text-primary">Assign Certificate:</h5>
                        <select id="selectCertificate"  name="certificateId" value={this.state.certificateId}  onChange={this.hic.bind(this)} class="form-control">
                            <option selected>Choose Certificate for this Group</option>
                            {certificateList}
                        </select>
                    </div>

                    <button type="submit" class="btn btn-primary mt-4" onClick={this.saveItem.bind(this)}>Add/Update Group</button>&nbsp;{deleteButton}&nbsp;<button class="btn btn-danger mt-4" onClick={() => {this.setState({showForm:false})}}>Close</button>
              
            </div>
        </div>
     </div>
 </div>
      

</div>


  )
  }
  else
  {
return(
<div>
  {spinner}
{helperFile.nav()}

    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-database mr-2" aria-hidden="true"></i>Groups</h2>

        <div class="data-container">
            <div class="row mb-2">
                <div class="col-8">
                    <p>Note - Groups are not well defined.  We need to decide what they do.  Groups displayed for all airlines, filter groups by search or dropdown.</p>
                </div>
                <div class="col-4">
                    <div class="card bg-secondary">
                        <div class="card-body text-center font-weight-bolder text-white">
                        Groups: {this.state.count}
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            

            <div class="container d-flex my-4 px-0 justify-content-between">
                <a class="btn btn-primary" role="button" onClick={this.addItem.bind(this)}><i class="fa fa-database mr-2" aria-hidden="true"></i>Add Group</a>
                <input class="form-control w-40" id="myInput" type="text" placeholder="Search.. by anything"/>
            </div>
            

            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <table class="table mb-0 table-hover">
                      <thead>
                        <tr>
                          <th scope="col" >Airline???</th>  
                          <th scope="col" >Group Name</th>
                          <th scope="col" >Short Name</th>
                          <th scope="col">Courses</th>
                          <th scope="col" >Members???</th>
                          <th scope="col" >Certificate???</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody id="myTable">
                      {listItems}
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
      

    {pager}
 {helperFile.footer()}
 
</div>

)
  }}
}


export default ModalList1
