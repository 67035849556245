import React from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const airlineHelper = require("./airline_helper");

// https://reactjs.org/docs/lists-and-keys.html react lists

var g_link = "/airline/subjects"
var g_title = "Subject Form"

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr><td>{props.item.name}</td><td><input type="button" value="Edit" onClick={() =>
    {
    props.parent.showModal(props.item) }
  }/></td></tr>;
}

class ListRows extends React.Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      show: false,
      rows: null,
      name:null,
      error:null,
    };
  }

fillList()
{
  var rows1 = [];
  
  helper.getFromServer(g_link+"?dw=get")
  .then(res => {
    alert("fill!");

    console.log(res);

    this.setState({show: false, rows: res.result});
  })



}
 componentDidMount() {
  this.fillList()  
  }

  showModal(item)
  {
      
    this.setState({show: true, id: item.id, name: item.name, error: ""});
  }

  hideModal()
  {
    this.setState({show: false});
  }

  saveItem()
  {
    helper.postToServer(g_link, {dw: "update", id: this.state.id, name: this.state.name}, true)
    .then(res => {
      this.fillList();
    })
    .catch(err => {
      this.setState({error: err});
    })

  }
  addItem()
  {

    helper.postToServer(g_link, {dw: "add"}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }

  deleteItem()
  {

    helper.postToServer(g_link, {dw: "del", id: this.state.id}, true)
    .then(res => {
      this.fillList()
    })
    .catch(err => {
      alert("AU Said : "+err)
    })

  }

  
  handleInputChange(event) {

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
  
    //console.log(name)
    this.setState({
      [name]: value
    });
  
  }
  

  render() {
document.title = g_title;
let listItems;
if (this.state.rows)
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

}

return(
<div>
{airlineHelper.nav()}
<br/>
<div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <table class="table mb-0 table-hover">
                      <thead>
                        <tr>
                          <th scope="col">Property</th>
                          <th scope="col">Value</th>
                        </tr>
                    </thead>
                    <tbody>
                    {listItems}
                      </tbody>
                    </table>
                </div>
            </div>

<Modal show={this.state.show} handleClose={this.hideModal.bind(this)}>
  {this.state.error}<br/>
  Name: <input type="text" name="name" value={this.state.name} onChange={this.handleInputChange.bind(this)} /><br/><br/>
  <input type="button" class="btn btn-primary" value="Save" onClick={this.saveItem.bind(this)}/>&nbsp;
  <input type="button" class="btn btn-primary" value="Delete" onClick={this.deleteItem.bind(this)}/>&nbsp;
  <br/>

</Modal>

 <input type="button" class="btn btn-primary" value="Add" onClick={this.addItem.bind(this)}/>
 <br/>

 {airlineHelper.footer()}
</div>

)
  }
}


export default ListRows
