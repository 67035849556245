import React from "react";
const helper = require("./helper");


// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/register";


class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      crewCount:25,
      payMethod:"annualPlan",
    };
  }

  save()
  {
    alert("hey!");
  }

  hic(event) {

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
  
    //console.log(name)
    this.setState({
      [name]: value
    });
  
  }

    

  render() {
document.title = "Dashboard";

return(
<div>



        <div class="reg-form-container">
            {/*<!-- left side start -->*/}
            <div class="reg-form-left">
            <a href="/">Home</a><br/><br/>
            <a href="/login">Login</a><br/><br/>
            <a href="/pricing">Pricing</a><br/><br/>
            <a href="/signup">Sign Up</a><br/><br/>
            <a href="/about">About</a><br/><br/>
                
            </div>
            
            {/*<!-- right side start // form elements here-->*/}
            <div class="reg-form-right">
                <h5 class="text-primary">Sign up for our service</h5>
                <hr/>
                
            {/*<!-- reg form start -->*/}
                    <div class="form-group">
                      <input type="text" class="form-control" id="airlineName" placeholder="Ciriculum Name"/>
                    </div>
                    <div class="form-group">
                        <input type="email" class="form-control" id="airlineEmail" placeholder="Login Email"/>
                        <small id="airlineEmailHelp" class="form-text text-muted">This email address will be used as your master login username to the Admin Section</small>
                    </div>
                    
                    <hr class="border-warning"/>
                    
                    <h5 class="text-primary mb-0">Login &amp; Password:</h5>
                    <p>We will use the email address you entered above as your <strong>Username</strong> for login to your training admin section. Please enter a good password at least 8 digits, combine letters and numbers for better security.</p>
                    <div class="form-group">
                      <input type="password" class="form-control" id="password" placeholder="Password"/> 
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" id="confirmPassword" placeholder="Confirm Password"/>
                        <small id="passwordHelp" class="form-text text-muted">Please confirm your password, we require a match</small>
                    </div>
                    
                    <hr class="border-warning"/>
                    <div class="form-check mt-4">
                        <input type="checkbox" class="form-check-input" id="termsAgree" required/>
                        <label class="form-check-label" for="termsAgree"><a href="z-airline-tandc.html" target="_blank">I agree to RecurrentPro terms of use</a></label>
                    </div>
                    <p class="mt-4"> reCaptua goes here</p>
                  <button class="btn btn-warning btn-lg mt-4" onClick={this.save.bind(this)}>Create Account</button>
          </div>
        </div>


 
</div>

)
}
}


export default ModalList1
