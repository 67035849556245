import React from "react";
const config = require("../config");
const helper = require("../helper");
const Modal = require("../comp_misc").modal;
const helperFile = require("./airline_helper.js");
const Pager = require("../comp_misc").pager;

// https://reactjs.org/docs/lists-and-keys.html react lists


var g_link = "/airline/crew";

function ListItem(props) {
  // Correct! There is no need to specify the key here:
  return <tr>
    <td>{props.item.name}</td>
<td>{props.item.rank}</td>
<td>{props.item.acType}</td>
<td>{props.item.group}</td>
<td>{props.item.courses}</td>
<td>{props.item.attempts}</td>
<td><a href={"/airline/crew_record?id="+props.item.id}><button type="button" class="btn btn-outline-primary">View</button></a></td>


</tr>

}

class ModalList1 extends React.Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      show: false,
      rows: null,
      name:null,
      email:"",
      error:null,
      image: null,
    };
  }




fillList()
{
  var rows1 = [];

  helper.getFromServer(g_link+"?dw=get")
  .then(res => {

    this.setState({showForm: false, rows: res.result, options: res.options, count: res.options.count});
  })



}
 componentDidMount() {
  this.fillList()  
  }

    

  render() {
document.title = "Crew";

let spinner;
let pager;
if (!this.state.rows)
{
  spinner = <div class="d-flex justify-content-center">
  <div class="spinner-border spinner text-primary" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>;
}
else
{
  pager = <Pager page={this.state.page} count={this.state.options.count} link={g_link}/>;
}


let listItems;
if (this.state.rows)
{


  listItems = this.state.rows.map((item, index) =>

    <ListItem key={index} item={item} parent={this} />
  );

}
return(
<div>
{helperFile.nav()}


    <div class="container my-4">
        <h2 class="text-primary mb-4"><i class="fa fa-id-card mr-2" aria-hidden="true"></i>Crew List</h2>

{/*<!-- info block start -->*/}
        <div class="data-container">
            <div class="row mb-2">
                <div class="col-8">
                    <p>This screen displays all your crew members sorted by name in Alphabetical order. To view each crew member specific records, click on the View button. You may filter your crew by Group, Rank and Aircraft Type.</p>
                </div>
                <div class="col-4">
                    <div class="card bg-secondary">
                        <div class="card-body text-center font-weight-bolder text-white">
                        Crew: {this.state.count}
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            
{/*<!-- search & add button in flex container start -->*/}
            <div class="container d-flex my-4 px-0 justify-content-end">
                <input class="form-control w-40" id="myInput" type="text" placeholder="Search Crew Name.."/>
            </div>
            <hr class="border-warning"/>
            
{/*<!-- filter dropdowns in flex container start with reset button -->*/}         
{/*<!-- filter start -->*/}
            <div class="container d-flex my-4 px-0 justify-content-between">
{/*<!-- Groups filter -->*/}              
                <div class="dropdown">
                  <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="filterGroups" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Filter: All Groups
                  </button>
                  <div class="dropdown-menu" aria-labelledby="filterGroups">
                    <a class="dropdown-item" href="#">Action</a>
                  </div>
                </div>
{/*<!-- Rank filter -->*/}
                <div class="dropdown">
                <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="filterRanks" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Filter: Rank
                  </button>
                  <div class="dropdown-menu" aria-labelledby="filterRanks">
                    <a class="dropdown-item" href="#">Action</a>
                  </div>
                </div>
{/*<!-- Aircraft filter -->*/}
                <div class="dropdown">
                <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="filterAircraft" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Filter: Aircraft Type
                  </button>
                  <div class="dropdown-menu" aria-labelledby="filterAircraft">
                    <a class="dropdown-item" href="#">Action</a>
                  </div>
                </div>
                
 {/*<!-- reset button -->*/}
               <button type="button" class="btn btn-primary">Reset Filters</button> 
            </div>
{/*<!-- filter end -->*/}

{/*<!-- table list start -->*/}
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <table class="table mb-0 table-hover">
                      <thead>
                        <tr>
                          <th scope="col" >Full Name</th>
                          <th scope="col" >Rank</th>
                          <th scope="col" >A/C Type</th>
                          <th scope="col" >Group</th>
                          <th scope="col" >Courses</th>
                          <th scope="col" >Attempts</th>
                          <th scope="col" class="text-center" width="120" >Action</th>
                        </tr>
                      </thead>
                      {listItems}
                    </table>
                </div>
            </div>
        </div>
    </div>



    {pager}

 {helperFile.footer()}
 
</div>

)
}
}


export default ModalList1
